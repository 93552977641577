import { useTranslation } from "react-i18next";

export const Error = (): any => {
  const { t } = useTranslation();
  return {
    "auth/email-already-in-use": t("EmailAlreadyInUse"),
    "auth/invalid-credential": t("InvalidCredentials"),
    "auth/requires-recent-login": t("RequiresRecentLogin"),
    "auth/password-does-not-meet-requirements": t(
      "PasswordDoesNotMeetRequirements"
    ),
  };
};
