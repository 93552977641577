import {
  Fade,
  Box,
  Heading,
  Text,
  Avatar,
  Container,
  Flex,
  Spacer,
  Image,
  ScaleFade,
} from "@chakra-ui/react";
import { _mainColor } from "../classes/Utils";
import Card from "../theme/components/Card/Card";
import CardBody from "../theme/components/Card/CardBody";
import { useTranslation } from "react-i18next";

export const ShopPresentation = ({ shop }: any) => {
  const { t } = useTranslation();
  return (
    <Fade in={shop}>
      <Card minHeight="290.5px" p="1.2rem" mb="20px" w="100%">
        <CardBody w="100%">
          <Flex flexDirection={{ sm: "column", lg: "row" }} w="100%">
            <Flex
              flexDirection="column"
              h="100%"
              lineHeight="1.6"
              width={{ lg: "45%" }}
            >
              <Text fontSize="sm" color="gray.400" fontWeight="bold">
                {t("AnApppointShop")}
              </Text>
              <Text
                fontSize="lg"
                color={_mainColor()}
                fontWeight="bold"
                pb=".5rem"
              >
                {shop.name}
              </Text>
              <Text fontSize="sm" color="gray.400" fontWeight="normal">
                {shop.description || ""}
              </Text>
              <Spacer />
            </Flex>
            <Spacer />
            {shop.logoUrl !== "" && (
              <Flex
                //   bg={_mainColor()}
                align="center"
                justify="center"
                padding={"20px 50px"}
                width={{ lg: "30%" }}
                minHeight={{ sm: "250px" }}
              >
                {/* <Avatar size="2xl" name={shop.name} src={shop.logoUrl} /> */}
                {/* <ScaleFade in={true} initialScale={0.7}> */}
                <Image src={shop.logoUrl} alt={shop.name} />
                {/* </ScaleFade> */}
              </Flex>
            )}
          </Flex>
        </CardBody>
      </Card>
    </Fade>
  );
};
