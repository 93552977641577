import {
  Box,
  Button,
  Heading,
  Icon,
  Stack,
  useColorModeValue,
  Text,
  Center,
  CardFooter,
  Image,
  ScaleFade,
  Fade,
  VStack,
  Flex,
} from "@chakra-ui/react";
import "./../style/home.css";
import MainPanel from "../theme/components/Layout/MainPanel";
import PanelContent from "../theme/components/Layout/PanelContent";
import PanelContainer from "../theme/components/Layout/PanelContainer";
import { _mainColor } from "../classes/Utils";
import Card from "../theme/components/Card/Card";
import CardHeader from "../theme/components/Card/CardHeader";
import CardBody from "../theme/components/Card/CardBody";
import { FaRegCalendarCheck, FaRegUser } from "react-icons/fa";
import { CiMobile2 } from "react-icons/ci";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAuthModal } from "../context/AuthModalContext";
import SidebarHelpImage from "./../assets/img/BackgroundCard1.png";
import IconBox from "../theme/components/Icons/IconBox";
import { QuestionIcon } from "@chakra-ui/icons";

function Home() {
  const { isModalOpen, modalType, closeModal, openModal } = useAuthModal();
  const { t } = useTranslation();
  return (
    <Center>
      <MainPanel
        w={{
          base: "100%",
          xl: "70%",
        }}
        // bg={"gray.50"}
      >
        <PanelContent>
          <PanelContainer>
            <Fade in={true}>
              <Card padding={"40px"}>
                <CardHeader></CardHeader>
                <CardBody>
                  <Box width={"100%"}>
                    <VStack w="100%">
                      <Stack
                        as={Box}
                        w="100%"
                        textAlign={"center"}
                        spacing={{ base: 3, md: 5 }}
                        py={{ base: 10, md: 36 }}
                        margin={"0 auto"}
                      >
                        <Image
                          src={"/logo.svg"}
                          alt="logo"
                          w="10%"
                          margin={"0 auto"}
                        />
                        <Heading
                          fontWeight={600}
                          fontSize={{ base: "4xl", sm: "3xl", md: "6xl" }}
                          lineHeight={"110%"}
                        >
                          {t("YourOnlineShop")},{" "}
                          <Text as={"span"} color={_mainColor()}>
                            {t("Simplified")}.
                          </Text>
                        </Heading>
                        <Text color={"gray.500"}>
                          {t("EffortlesslyManage")}
                        </Text>
                        <Stack
                          direction={"row"}
                          spacing={3}
                          align={"center"}
                          alignSelf={"center"}
                          position={"relative"}
                        >
                          <Link to={`/dashboard`}>
                            <Button
                              variant={"main"}
                              rounded={"full"}
                              w={"auto"}
                              fontSize={{ base: "sm", sm: "sm", md: "md" }}
                              px={6}
                              _hover={{
                                bg: "pink.500",
                              }}
                              onClick={() => {
                                openModal("signup");
                              }}
                            >
                              {t("StartYourShopToday")}
                            </Button>
                          </Link>
                        </Stack>
                      </Stack>
                      {/* <Box w="100%" textAlign="center" mt="40px" display={"block"}> */}
                      <Stack
                        direction={["column", "row"]}
                        spacing={3}
                        align={"center"}
                        w={"100%"}
                      >
                        <Image
                          src="/images/preview.jpg"
                          borderRadius={"30px"}
                          w={["100%", "50%"]}
                        />
                        <Image
                          src="/images/preview_appointments.jpg"
                          borderRadius={"30px"}
                          w={["100%", "50%"]}
                        />
                      </Stack>
                    </VStack>
                  </Box>
                  {/* </Box> */}
                </CardBody>
              </Card>
              {/* <Card padding={"40px 40px"} mt="20px"> */}
              <Box mt="40px">
                <CardHeader textAlign={"center"}>
                  <Heading
                    fontSize={{ base: "xl", sm: "2xl", md: "4xl" }}
                    margin="0 auto"
                  >
                    {t("Features")}
                  </Heading>
                </CardHeader>
                <CardBody>
                  <Stack
                    direction={{
                      base: "column",
                      sm: "column",
                      md: "row",
                      xl: "row",
                    }}
                    spacing={8}
                    w={"100%"}
                    mt="40px"
                  >
                    <Card
                      border={"1px solid"}
                      borderColor={"gray.200"}
                      padding={"40px"}
                    >
                      <CardHeader>
                        <Box
                          bg={"green.300"}
                          padding="20px"
                          color={"green.50"}
                          borderRadius={"20px"}
                          fontSize={"2xl"}
                        >
                          <FaRegCalendarCheck />
                        </Box>
                      </CardHeader>
                      <CardBody mt="20px">
                        <Stack>
                          <Heading fontSize={"2xl"}>
                            {t("AppointmentScheduling")}
                          </Heading>
                          <Text mt="20px">
                            {t("AppointmentSchedulingSpecification")}
                          </Text>
                        </Stack>
                      </CardBody>
                    </Card>
                    <Card
                      border={"1px solid"}
                      borderColor={"transparent"}
                      padding={"40px"}
                      boxShadow={"0px 10px 120px 0 rgba(100,100,100,0.1)"}
                    >
                      <CardHeader>
                        <Box
                          bg={"orange.300"}
                          padding="20px"
                          color={"orange.50"}
                          borderRadius={"20px"}
                          fontSize={"2xl"}
                        >
                          <FaRegUser />
                        </Box>
                      </CardHeader>
                      <CardBody mt="20px">
                        <Stack>
                          <Heading fontSize={"2xl"}>
                            {t("CustomerManagement")}
                          </Heading>
                          <Text mt="20px">
                            {t("CustomerManagementSpecification")}
                          </Text>
                        </Stack>
                      </CardBody>
                    </Card>
                    <Card
                      border={"1px solid"}
                      borderColor={"gray.200"}
                      padding={"40px"}
                    >
                      <CardHeader>
                        <Box
                          bg={"blue.300"}
                          padding="20px"
                          color={"blue.50"}
                          borderRadius={"20px"}
                          fontSize={"2xl"}
                        >
                          <CiMobile2 />
                        </Box>
                      </CardHeader>
                      <CardBody mt="20px">
                        <Stack>
                          <Heading fontSize={"2xl"}>
                            {t("MobileOptimization")}
                          </Heading>
                          <Text mt="20px">
                            {t("MobileOptimizationSpecification")}
                          </Text>
                        </Stack>
                      </CardBody>
                    </Card>
                  </Stack>
                </CardBody>
                <Box w="100%" textAlign="center" mt="40px">
                  <Text color={"gray.500"}>{t("AndMuchMoreFeatures")}</Text>
                </Box>
              </Box>
              {/* </Card> */}
              <Stack
                as={Box}
                textAlign={"center"}
                spacing={{ base: 8, md: 14 }}
                py={{ base: 20, md: 36 }}
                px={{ base: 6, md: 16 }}
              >
                {/* <Heading
                fontWeight={600}
                fontSize={{ base: "3xl", sm: "3xl", md: "3xl" }}
                lineHeight={"110%"}
              >
                {t("Quote")}
              </Heading> */}
                <Text color={"gray.500"}>{t("EasilyManageAppointments")}</Text>
                <Stack
                  direction={"row"}
                  spacing={3}
                  align={"center"}
                  alignSelf={"center"}
                  position={"relative"}
                >
                  <Link to={`/dashboard`}>
                    <Button
                      variant={"main"}
                      rounded={"full"}
                      px={6}
                      _hover={{
                        bg: "pink.500",
                      }}
                      onClick={() => {
                        openModal("signup");
                      }}
                    >
                      {t("StartYourShop")}
                    </Button>
                  </Link>
                </Stack>
              </Stack>

              <Box
                marginTop={{ base: "1", sm: "5" }}
                marginBottom={"40px"}
                display="flex"
                flexDirection={{ base: "column", sm: "column", md: "row" }}
                justifyContent="space-between"
              >
                <Box
                  display="flex"
                  flex="1"
                  marginRight="3"
                  position="relative"
                  alignItems="center"
                >
                  <Box
                    width={{ base: "100%", sm: "85%" }}
                    zIndex="2"
                    marginLeft={{ base: "0", sm: "5%" }}
                    marginTop="5%"
                  >
                    <Box
                      textDecoration="none"
                      _hover={{ textDecoration: "none" }}
                    >
                      <Image
                        src={"/illustrations/quote2.svg"}
                        alt="img"
                        objectFit="contain"
                      />
                    </Box>
                  </Box>
                  <Box
                    zIndex="1"
                    width="100%"
                    position="absolute"
                    height="100%"
                  >
                    <Box
                      bgGradient={useColorModeValue(
                        `radial(${_mainColor()} 1px, transparent 1px)`,
                        `radial(${_mainColor()} 1px, transparent 1px)`
                      )}
                      backgroundSize="20px 20px"
                      opacity="0.4"
                      height="100%"
                    />
                  </Box>
                </Box>

                <Box
                  display="flex"
                  flex="1"
                  flexDirection="column"
                  justifyContent="center"
                  marginTop={{ base: "3", sm: "0" }}
                >
                  <Heading marginTop="1">
                    <Text
                      textDecoration="none"
                      _hover={{ textDecoration: "none" }}
                    >
                      {t("Quote2")}
                    </Text>
                  </Heading>
                  <Text
                    as="p"
                    marginTop="2"
                    color={useColorModeValue("gray.700", "gray.200")}
                    fontSize="lg"
                  >
                    {t("Quote2Specification")}
                  </Text>
                </Box>
              </Box>
              <Box marginTop={"70px"} marginBottom={"40px"}>
                <Flex
                  borderRadius="15px"
                  flexDirection="column"
                  bgImage={SidebarHelpImage}
                  bgRepeat={"no-repeat"}
                  bgSize={"cover"}
                  bgPos={"center"}
                  justifyContent="flex-start"
                  alignItems="center"
                  boxSize="border-box"
                  p="16px"
                  h="170px"
                  w="100%"
                >
                  <IconBox width="35px" h="35px" bg="white" mb="auto">
                    <QuestionIcon color={_mainColor()} h="18px" w="18px" />
                  </IconBox>
                  <Text fontSize="sm" color="white" fontWeight="bold">
                    {t("NeedHelp")}
                  </Text>
                  <Text fontSize="xs" color="white" mb="10px">
                    {t("ContactUs")}
                  </Text>
                  <Link to="mailto:support@apppoint.gr">
                    <Button
                      fontSize="10px"
                      fontWeight="bold"
                      w="100%"
                      bg="white"
                      //   _hover="none"
                      _active={{
                        bg: "white",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                      color="black"
                    >
                      support@apppoint.gr
                    </Button>
                  </Link>
                </Flex>
              </Box>
            </Fade>
          </PanelContainer>
        </PanelContent>
      </MainPanel>
    </Center>
  );
}

export default Home;
