import {
  collection,
  collectionGroup,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useQuery } from "react-query";
import { auth, firestore } from "../firebase";
import { IAppointment } from "../interfaces/interfaces";
import { useAuthState } from "react-firebase-hooks/auth";

export const useMyAppointments = () => {
  const [user] = useAuthState(auth);
  return useQuery({
    queryKey: ["appointments", user?.uid],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const snapshot = await getDocs(
        query(
          collectionGroup(firestore, "appointments"),
          where("user", "==", user?.uid),
          orderBy("date", "asc")
        )
      );
      const data: IAppointment[] = [];
      for (const doc of snapshot.docs) {
        let appointment = doc.data() as IAppointment;
        appointment.id = doc.id;
        data.push(appointment);
      }
      return data as IAppointment[];
    },
  });
};

export const useShopAppointments = async (shopId: string) => {
  return useQuery({
    queryKey: ["appointments", shopId],
    queryFn: async () => {
      const snapshot = await getDocs(
        query(
          collection(firestore, "shops", shopId, "appointments"),
          orderBy("date", "asc")
        )
      );
      const data: IAppointment[] = [];
      for (const doc of snapshot.docs) {
        let appointment = doc.data() as IAppointment;
        appointment.id = doc.id;
        data.push(appointment);
      }
      return data as IAppointment[];
    },
  });
};
