import { AbsoluteCenter, Skeleton } from "@chakra-ui/react";
import "./../style/loading.css";

export const LoadingSkeleton = () => {
  return <Skeleton height={"20px"} marginY={"20px"} />;
};

export const LoadingAnimation = () => {
  return (
    <AbsoluteCenter>
      <div className="boxes">
        <div className="box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </AbsoluteCenter>
  );
};
