import React, { act, useEffect, useMemo, useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  Divider,
  Fade,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Heading,
  Input,
  List,
  ListIcon,
  ListItem,
  NumberInput,
  NumberInputField,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  ScaleFade,
  Select,
  SlideFade,
  Stack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  border,
  useSteps,
  Text,
  useToast,
  AbsoluteCenter,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Alert,
  AlertIcon,
  Avatar,
  Image,
  VStack,
  Textarea,
} from "@chakra-ui/react";
import { steps } from "framer-motion";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  FaCalendar,
  FaCheckCircle,
  FaRegHandPointLeft,
  FaRegHandPointRight,
} from "react-icons/fa";
import {
  addDoc,
  collection,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth, firestore, storage } from "../firebase";

import { useAuthState } from "react-firebase-hooks/auth";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import { getCheckoutUrl } from "../stripe/Stripe";
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";
import { RiContactsBook2Fill } from "react-icons/ri";
import { IoFootstepsSharp } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { _mainColor } from "../classes/Utils";

export const CreateShop = () => {
  const [user]: any = useAuthState(auth);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const email_regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   const phone_regex = /^\d{12}$/gs;
  const toast = useToast();
  const steps = [
    {
      title: `${t("Step")} 1`,
      description: t("ContactInformation"),
      icon: <RiContactsBook2Fill />,
    },
    {
      title: `${t("Step")} 2`,
      description: t("CalendarInformation"),
      icon: <FaCalendar />,
    },
    {
      title: `${t("Step")} 3`,
      description: t("FinalSteps"),
      icon: <IoFootstepsSharp />,
    },
  ];

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const availableDays = [
    t("Monday"),
    t("Tuesday"),
    t("Wednesday"),
    t("Thursday"),
    t("Friday"),
    t("Saturday"),
    t("Sunday"),
  ];

  const [logo, setLogo] = useState<any>(null);
  const [urlExists, setUrlExists] = useState(false);
  const [done, setDone] = useState(false);
  const [step1data, setStep1Data] = useState<any>({});
  const [step2data, setStep2Data] = useState<any>({});
  const [fetchedUrls, setFetchedUrls] = useState<any>([]);

  const [loading, setLoading] = useState(false);

  const createShop = async () => {
    setLoading(true);
    await addDoc(collection(firestore, "shops"), {
      name: step1data.name,
      email: step1data.email,
      url: step1data.url,
      description: step1data.description || "",
      phone: step1data.phone,
      days: JSON.stringify(step2data.days),
      timeslots: JSON.stringify(step2data.timeslots),
      duration: step2data.duration,
      bookLimit: step2data.bookLimit,
      slots: step2data.slots,
      owner: user?.uid,
      active: true,
      createdAt: new Date(),
      logoUrl: "",
    })
      .then((docRef: any) => {
        setDone(true);
        toast({
          title: t("Success"),
          status: "success",
          duration: 3000,
          description: t("SuccessfullyCreatedShop"),
        });
        uploadLogo(docRef);
        setLoading(false);
      })
      .catch((error) => {
        toast({
          title: t("Oops"),
          description: t("SomethingWentWrong"),
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const uploadLogo = (docRef: any) => {
    if (logo === null) {
      return;
    }
    const imageRef = storageRef(storage, `shops/${docRef.id}/logo`);

    uploadBytes(imageRef, logo)
      .then((snapshot) => {
        getDownloadURL(imageRef).then((url) => {
          updateDoc(docRef, {
            logoUrl: url,
          });
        });
      })
      .catch((error) => {});
  };

  const checkUrl = async (shopUrl: any) => {
    if (!shopUrl || urlExists) return;
    if (fetchedUrls.includes(shopUrl)) {
      setUrlExists(true);
      return;
    }
    const q = query(
      collection(firestore, "shops"),
      where("url", "==", shopUrl)
    );
    const snapshot = await getDocs(q);
    if (snapshot.size > 0) {
      setFetchedUrls([
        ...fetchedUrls,
        snapshot.docs.map((doc) => doc.data().url),
      ]);
      toast({
        title: t("Oops"),
        description: t("UrlAlreadyTaken"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setUrlExists(true);
    } else {
      setUrlExists(false);
    }
  };

  useEffect(() => {
    const get = async () => {};
    get();
  }, []);

  const getOrientation = () => {
    if (window.innerWidth < 600) {
      return "vertical";
    } else {
      return "horizontal";
    }
  };

  if (loading) {
    return (
      <AbsoluteCenter axis="both">
        <Box textAlign="center" py={10} px={6}>
          <Heading as="h2" size="xl" mt={6} mb={2}>
            {t("CreatingYourShop")}...
          </Heading>
          <Text color={"gray.500"}>{t("WaitWhileWeCreateYourShop")}</Text>
        </Box>
      </AbsoluteCenter>
    );
  }
  return (
    <>
      {done ? (
        // <ScaleFade initialScale={0.3} transition={{ enter: { duration: 1 } }}>
        <AbsoluteCenter marginTop={"40px"}>
          <Box w={"100%"}>
            <Center>
              <Image src={"/illustrations/party.svg"} alt="heart" w="60%" />
            </Center>
          </Box>
          <Box textAlign="center" py={10} px={6}>
            {/* <CheckCircleIcon boxSize={"50px"} color={"green.500"} /> */}
            <Heading as="h2" size="xl" mt={6} mb={2}>
              {t("YourShopHasBeenCreated")}
            </Heading>
            <Text color={"gray.500"}>
              {t("YourShopHasBeenCreatedSpecification")}
            </Text>
            <Link to={`/dashboard`}>
              <Button mt={6} variant="main">
                {t("GoToDashboard")}
              </Button>
            </Link>
          </Box>
        </AbsoluteCenter>
      ) : (
        // </ScaleFade>
        <>
          <Stepper
            size="lg"
            orientation={getOrientation()}
            index={activeStep}
            colorScheme="blue"
            mb="40px"
          >
            {steps.map((step, index) => (
              <>
                <Step
                  key={index}
                  //   onClick={() => setActiveStep(index)}
                  //   style={{ cursor: "pointer" }}
                >
                  <SlideFade
                    //   initialScale={0.8}
                    offsetX={"-20px"}
                    offsetY={"0px"}
                    in={index === activeStep}
                    transition={{
                      exit: { duration: 0.5 },
                      enter: { duration: 0.5 },
                    }}
                  >
                    <StepIndicator>
                      <StepStatus
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                      />
                    </StepIndicator>
                  </SlideFade>

                  <Box flexShrink="10">
                    <StepIcon>{step.icon}</StepIcon>
                    <StepTitle>{step.title}</StepTitle>
                    <StepDescription>{step.description}</StepDescription>
                  </Box>

                  <StepSeparator />
                </Step>
              </>
            ))}
          </Stepper>
          {activeStep === 0 && (
            <>
              <ScaleFade initialScale={0.8} in={activeStep === 0}>
                <Formik
                  initialValues={{
                    name: step1data.name || "",
                    url: step1data.url || "",
                    email: user?.email,
                    phone: step1data.phone || "",
                  }}
                  validate={(values) => {
                    const errors: any = {};
                    if (!values.name) {
                      errors.name = t("FieldIsRequired");
                    }
                    if (!values.url) {
                      errors.url = t("FieldIsRequired");
                    } else if (urlExists) {
                      errors.url = t("UrlAlreadyTaken");
                    }
                    if (!values.email) {
                      errors.email = t("FieldIsRequired");
                    }
                    if (!values.phone) {
                      errors.phone = t("FieldIsRequired");
                    } else if (values.phone.length !== 13) {
                      errors.phone = t("InvalidPhoneNumber");
                    }
                    return errors;
                  }}
                  onSubmit={(values) => {
                    setStep1Data(values);
                    setActiveStep(activeStep + 1);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    setFieldValue,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }: /* and other goodies */
                  any) => (
                    <form onSubmit={handleSubmit}>
                      <FormControl>
                        <FormLabel>{t("GiveAName")}</FormLabel>
                        <Input
                          type="text"
                          value={values.name}
                          name="name"
                          onChange={handleChange}
                          isInvalid={errors.name && touched.name && errors.name}
                          errorBorderColor="crimson"
                        />
                        <FormHelperText>
                          {t("GiveANameSpecification")}
                        </FormHelperText>
                      </FormControl>
                      {/* Add url field here */}
                      <FormControl marginTop={"20px"}>
                        <FormLabel>{t("Url")}</FormLabel>
                        <Stack spacing={4}>
                          {/* If you add the size prop to `InputGroup`, it'll pass it to all its children. */}
                          <InputGroup size="sm">
                            <InputLeftAddon padding={"25px 20px"}>
                              {window.location.origin}/shop/
                            </InputLeftAddon>
                            <Input
                              type="text"
                              value={values.url}
                              onChange={(e) => {
                                const v = e.target.value
                                  .replace(
                                    /[`~!@#$%^&*()_|+\=?;:'",.<>\s+\{\}\[\]\\\/]/gi,
                                    ""
                                  )
                                  .toLowerCase();
                                setUrlExists(false);
                                setFieldValue("url", v);
                              }}
                              name="url"
                              isInvalid={
                                (errors.url && touched.url && errors.url) ||
                                urlExists
                              }
                              errorBorderColor="crimson"
                              onBlur={(e) => {
                                checkUrl(e.target.value);
                              }}
                            />
                          </InputGroup>
                        </Stack>
                        <Text color="red.500" size={"sm"}>
                          {errors.url && touched.url && errors.url}{" "}
                        </Text>
                        <FormHelperText>{t("UrlSpecification")}</FormHelperText>
                      </FormControl>

                      <FormControl marginTop={"20px"}>
                        <FormLabel>{t("GiveADescription")}</FormLabel>
                        <Textarea
                          variant={"outline"}
                          value={values.description}
                          name="description"
                          onChange={handleChange}
                        />
                        <FormHelperText>
                          {t("GiveADescriptionSpecification")}
                        </FormHelperText>
                      </FormControl>

                      <FormControl marginTop={"20px"}>
                        <FormLabel>{t("Logo")}</FormLabel>
                        <HStack spacing={10}>
                          {logo && (
                            <Avatar size="xl" src={URL.createObjectURL(logo)} />
                          )}
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            <Input
                              id="logo"
                              border={"0"}
                              padding={"0"}
                              type="file"
                              variant={"outline"}
                              display={"none"}
                              accept="image/*"
                              onChange={(e: any) => {
                                if (e.target.files.length === 0) {
                                  return;
                                }
                                if (e.target.files[0].size / 1024 ** 2 > 10) {
                                  toast({
                                    title: t("Oops"),
                                    description: t("FileSizeLimit"),
                                    status: "error",
                                    duration: 3000,
                                    isClosable: true,
                                  });
                                  e.target.value = "";
                                  return;
                                }
                                setLogo(e.target.files[0]);
                              }}
                            />
                            <Button
                              as="label"
                              htmlFor="logo"
                              variant={"main"}
                              size="sm"
                              cursor="pointer"
                              _hover={{ bg: "blue.600" }}
                              _focus={{ boxShadow: "outline" }}
                            >
                              {logo ? t("ChangeFile") : t("UploadFile")}
                            </Button>
                          </Box>
                        </HStack>
                        <FormHelperText>
                          {t("LogoSpecification")}
                        </FormHelperText>
                      </FormControl>

                      <FormControl marginTop={"20px"}>
                        <FormLabel>{t("Email")}</FormLabel>
                        <Input
                          type="email"
                          value={values.email}
                          disabled={true}
                        />
                        <FormHelperText>
                          <Alert status="info" variant="left-accent">
                            <AlertIcon />
                            {t("EmailNotice")}
                          </Alert>
                        </FormHelperText>
                      </FormControl>
                      <FormControl marginTop={"20px"}>
                        <FormLabel>{t("PhoneNumber")}</FormLabel>
                        <PhoneInput
                          defaultCountry="GR"
                          value={values.phone}
                          autoComplete="off"
                          name="phone"
                          style={{
                            border: `${
                              errors.phone && touched.phone && errors.phone
                                ? "1px solid crimson"
                                : "1px solid #e2e8f0"
                            }`,
                            boxShadow: `${
                              errors.phone && touched.phone && errors.phone
                                ? "0 0 0 1px crimson"
                                : ""
                            }`,
                            backgroundColor: "white",
                            color: "black",
                            padding: "15px 20px",
                            borderRadius: "3px",
                          }}
                          className={`chakra-input css-1yqhqcq focus-none`}
                          onChange={(e: any) => {
                            handleChange("phone")(e ? e : "");
                          }}
                          disabled={isSubmitting}
                        />
                        <Text color="red.500" size={"sm"}>
                          {errors.phone && touched.phone && errors.phone}
                        </Text>
                        <FormHelperText>
                          {t("PhoneNumberSpecification")}
                        </FormHelperText>
                      </FormControl>
                      <Grid templateColumns="repeat(1, 1fr)" mt="40px">
                        <GridItem colSpan={2}></GridItem>
                        <GridItem colStart={4} colEnd={6}>
                          <Button
                            isLoading={isSubmitting}
                            variant={"main"}
                            type="submit"
                            rightIcon={<FaRegHandPointRight />}
                          >
                            {t("Next")}
                          </Button>
                        </GridItem>
                      </Grid>
                    </form>
                  )}
                </Formik>
              </ScaleFade>
            </>
          )}
          {activeStep === 1 && (
            <>
              <ScaleFade
                initialScale={0.8}
                in={activeStep === 1}
                //   transition={{ enter: { duration: 0.5 } }}
              >
                <Formik
                  initialValues={{
                    days: step2data.days || availableDays.map(() => false),
                    timeslots: step2data.timeslots || Array(7).fill([0, 24]),
                    duration: step2data.duration || 60,
                    slots: step2data.slots || 1,
                    bookLimit: step2data.bookLimit || 30,
                  }}
                  validate={(values) => {
                    const errors: any = {};
                    if (!values.duration) {
                      errors.duration = t("FieldIsRequired");
                    } else if (values.duration < 10 || values.duration > 60) {
                      errors.duration = t("ValueOutOfRange");
                    }
                    if (!values.slots) {
                      errors.slots = t("FieldIsRequired");
                    } else if (values.slots < 0 || values.slots > 10) {
                      errors.slots = t("ValueOutOfRange");
                    }
                    if (!values.bookLimit) {
                      errors.bookLimit = t("FieldIsRequired");
                    } else if (values.bookLimit < 0 || values.bookLimit > 120) {
                      errors.bookLimit = t("ValueOutOfRange");
                    }
                    return errors;
                  }}
                  onSubmit={(values) => {
                    setStep2Data(values);
                    setActiveStep(activeStep + 1);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    setFieldValue,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }: /* and other goodies */
                  any) => (
                    <form onSubmit={handleSubmit}>
                      <FormControl>
                        <FormLabel>{t("DayAvailability")}</FormLabel>
                        <>
                          <Checkbox
                            isChecked={values.days.every(Boolean)}
                            onChange={(e) => {
                              setFieldValue(
                                "days",
                                Array(availableDays.length).fill(
                                  e.target.checked
                                )
                              );
                            }}
                            disabled={isSubmitting}
                          >
                            {t("AllDays")}
                          </Checkbox>
                          <Accordion defaultIndex={[]} allowMultiple>
                            {/* <Stack pl={6} mt={1} spacing={1}> */}
                            {availableDays.map((day, index) => (
                              <AccordionItem key={index}>
                                <AccordionButton>
                                  <Checkbox
                                    // name={`days[${index}]`}
                                    key={`day_${index}`}
                                    isChecked={values.days[index]}
                                    onChange={(e) => {
                                      const newItems: boolean[] = [
                                        ...values.days,
                                      ];
                                      newItems[index] = e.target.checked;
                                      setFieldValue("days", newItems);
                                      //   handleChange("days")(newItems);
                                      //   setDays(newItems);
                                    }}
                                    disabled={isSubmitting}
                                  >
                                    {day}
                                  </Checkbox>
                                  <Box as="span" flex="1" textAlign="right">
                                    <AccordionIcon />
                                  </Box>
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                  <Center>
                                    <Stack direction="row" spacing={4}>
                                      <Heading size={"md"}>
                                        {values.timeslots[index][0]}:00 -{" "}
                                        {values.timeslots[index][1]}
                                        :00
                                      </Heading>
                                      <Button
                                        size="xs"
                                        alignSelf={"right"}
                                        variant={"main"}
                                        onClick={() => {
                                          let timeslot =
                                            values.timeslots[index];
                                          let timeslotsCopy = [
                                            ...values.timeslots,
                                          ];
                                          timeslotsCopy = values.timeslots.map(
                                            (item: any) => {
                                              return timeslot;
                                            }
                                          );
                                          setFieldValue(
                                            "timeslots",
                                            timeslotsCopy
                                          );
                                          toast({
                                            title: t("Success"),
                                            description: t("TimeslotsApplied"),
                                            status: "success",
                                            duration: 3000,
                                            isClosable: true,
                                          });
                                        }}
                                        disabled={isSubmitting}
                                      >
                                        {t("ApplyToAllDays")}
                                      </Button>
                                    </Stack>
                                  </Center>
                                  <RangeSlider
                                    aria-label={["min", "max"]}
                                    key={`timeslot_${index}`}
                                    defaultValue={[0, 24]}
                                    name={`timeslots[${index}]`}
                                    value={values.timeslots[index]}
                                    max={24}
                                    min={0}
                                    onChange={(v) => {
                                      const newItems: any = [
                                        ...values.timeslots,
                                      ];
                                      newItems[index] = v;
                                      //   setTimeslots(newItems);
                                      setFieldValue("timeslots", newItems);
                                    }}
                                    isDisabled={isSubmitting}
                                  >
                                    <RangeSliderTrack>
                                      <RangeSliderFilledTrack />
                                    </RangeSliderTrack>
                                    <RangeSliderThumb index={0} />
                                    <RangeSliderThumb index={1} />
                                  </RangeSlider>
                                </AccordionPanel>
                              </AccordionItem>
                            ))}
                            {/* </Stack> */}
                          </Accordion>
                        </>
                        <FormHelperText>
                          {t("DayAvailabilitySpecification")}
                        </FormHelperText>
                      </FormControl>
                      <FormControl marginTop={"20px"}>
                        <FormLabel>{t("Duration")}</FormLabel>
                        <NumberInput
                          defaultValue={values.duration}
                          value={values.duration}
                          step={0}
                          isInvalid={
                            errors.duration &&
                            touched.duration &&
                            errors.duration
                          }
                          name="duration"
                          errorBorderColor="crimson"
                          onChange={(e) => {
                            setFieldValue("duration", e);
                          }}
                          isDisabled={isSubmitting}
                        >
                          <NumberInputField />
                        </NumberInput>
                        <Text color="red.500" size={"sm"}>
                          {errors.duration &&
                            touched.duration &&
                            errors.duration}{" "}
                        </Text>
                        <FormHelperText>
                          {t("DurationSpecification")}
                        </FormHelperText>
                      </FormControl>
                      <FormControl marginTop={"20px"}>
                        <FormLabel>{t("AvailableSlotPerBooking")}</FormLabel>
                        <NumberInput
                          defaultValue={values.slots}
                          value={values.slots}
                          step={0}
                          isInvalid={
                            errors.slots && touched.slots && errors.slots
                          }
                          errorBorderColor="crimson"
                          name="slots"
                          onChange={(e) => {
                            setFieldValue("slots", e);
                          }}
                          isDisabled={isSubmitting}
                        >
                          <NumberInputField />
                        </NumberInput>
                        <Text color="red.500" size={"sm"}>
                          {errors.slots && touched.slots && errors.slots}{" "}
                        </Text>
                        <FormHelperText>
                          {t("AvailableSlotPerBookingSpecification")}
                        </FormHelperText>
                      </FormControl>
                      <FormControl marginTop={"20px"}>
                        <FormLabel>
                          {t("BookLimitation")} ({t("InDays")})
                        </FormLabel>
                        <NumberInput
                          defaultValue={values.bookLimit}
                          value={values.bookLimit}
                          step={0}
                          isInvalid={
                            errors.bookLimit &&
                            touched.bookLimit &&
                            errors.bookLimit
                          }
                          errorBorderColor="crimson"
                          name="bookLimit"
                          onChange={(e) => {
                            setFieldValue("bookLimit", e);
                          }}
                          isDisabled={isSubmitting}
                        >
                          <NumberInputField />
                        </NumberInput>
                        <Text color="red.500" size={"sm"}>
                          {errors.bookLimit &&
                            touched.bookLimit &&
                            errors.bookLimit}{" "}
                        </Text>
                        <FormHelperText>
                          {t("BookLimitationSpecification")}
                        </FormHelperText>
                      </FormControl>

                      <Grid
                        templateColumns="repeat(2, 1fr)"
                        margin={"40px auto"}
                      >
                        <GridItem colSpan={2}>
                          <Button
                            colorScheme="blue"
                            variant={"outline"}
                            leftIcon={<FaRegHandPointLeft />}
                            isLoading={isSubmitting}
                            onClick={() => {
                              setStep2Data(values);
                              setActiveStep(activeStep - 1);
                            }}
                          >
                            {t("Back")}
                          </Button>
                        </GridItem>
                        <GridItem colStart={4} colEnd={6}>
                          <Button
                            variant={"main"}
                            rightIcon={<FaRegHandPointRight />}
                            type="submit"
                            isLoading={isSubmitting}
                          >
                            {t("Next")}
                          </Button>
                        </GridItem>
                      </Grid>
                    </form>
                  )}
                </Formik>
              </ScaleFade>
            </>
          )}
          {activeStep === 2 && (
            <>
              {/* <ScaleFade
                initialScale={0.8}
                in={activeStep === 2}
                transition={{ enter: { duration: 0.5 } }}
              > */}
              <Center>
                <Image
                  src={"/illustrations/complete.svg"}
                  alt="heart"
                  w="20%"
                />
              </Center>
              <Box py={6} px={5}>
                <VStack spacing={4} w={"100%"}>
                  <Heading size={"lg"}>
                    <Text color={_mainColor()}>{t("EverythingReady")}</Text>
                  </Heading>
                  {/* <Text textAlign={"center"}>
                      {t("YouHaveDaysFreeSpecification")}
                    </Text> */}
                </VStack>
                <Divider my={"40px"} />
                <Center>
                  <Button colorScheme={"green"} onClick={createShop}>
                    {t("CreateShop")}
                  </Button>
                </Center>
              </Box>

              <Grid templateColumns="repeat(2, 1fr)" margin={"40px auto"}>
                <GridItem colSpan={2}>
                  <Button
                    colorScheme="blue"
                    variant={"outline"}
                    leftIcon={<FaRegHandPointLeft />}
                    onClick={() => setActiveStep(activeStep - 1)}
                  >
                    {t("Back")}
                  </Button>
                </GridItem>
              </Grid>
              {/* </ScaleFade> */}
            </>
          )}
        </>
      )}
    </>
  );
};
