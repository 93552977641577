import { useAuthState } from "react-firebase-hooks/auth";
import { auth, firestore, storage } from "../firebase";
import {
  Alert,
  AlertIcon,
  Button,
  Container,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ScaleFade,
  Spinner,
  useDisclosure,
  useToast,
  Highlight,
  Code,
  VStack,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  updateProfile,
  User,
  deleteUser as deleteUserAuth,
} from "firebase/auth";
import { useNavigate, useParams } from "react-router-dom";
import { PiWarningOctagonFill } from "react-icons/pi";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  deleteObject,
  listAll,
  ref,
  ref as storageRef,
} from "firebase/storage";
import { getPremiumStatus } from "../stripe/PremiumStatus";
import { Trans, useTranslation } from "react-i18next";
import { Error } from "../classes/Error";
import { changeStatus } from "../classes/Appointment";
import { useMyAppointments } from "../hooks/useAppointment";
import { useMyShops } from "../hooks/useShop";
// import { useAppointment } from "../hooks/useAppointment";

export const Profile = () => {
  const [user]: any = useAuthState(auth);
  const [displayName, setDisplayName] = useState("");
  const { url } = useParams();
  const [submitted, setSubmitted] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus]: any = useState(null);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(true);
  const { data } = useMyAppointments();
  const { data: shops, isLoading } = useMyShops("profile");
  //   const { appointments, getAppointments, changeAppointmentStatus, isLoading } =
  //     useAppointment(url || "");

  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const errors = Error();

  useEffect(() => {
    setCanDelete(false);
    const get = async () => {
      if (user) {
        setDisplayName(user.displayName || "");
      }
    };
    get().catch((error) => {
      console.error(error);
    });
  }, [user]);

  useEffect(() => {
    const get = async () => {
      const as = await getHasActiveSubscription();
      setHasActiveSubscription(as);
      //   setHasActiveSubscription(await getHasActiveSubscription());
    };
    get().catch((error) => {
      console.error(error);
    });
  }, [shops, isLoading]);

  const updateUser = async () => {
    if (!displayName) {
      return;
    }
    setSubmitted(true);
    await updateProfile(auth.currentUser as User, {
      displayName: displayName,
    });
    setSubmitted(false);
    toast({
      title: t("Success"),
      description: t("YourChangesHaveBeenSaved"),
      status: "success",
      duration: 9000,
      isClosable: true,
    });
    navigate(window.location.pathname);
  };

  const getHasActiveSubscription = async () => {
    if (!shops) {
      return false;
    }

    let has = false;

    shops?.forEach(async (shop: any) => {
      if (shop.subscription) {
        if (
          (shop.subscription.status === "active" ||
            shop.subscription.status === "trialing") &&
          shop.subscription.cancel_at == null
        ) {
          has = true;
        }
      }
    });

    return has;
  };

  const deleteUser = async () => {
    try {
      setSubmitted(true);
      const q = query(
        collection(firestore, "shops"),
        where("owner", "==", user.uid)
      );
      const snapshots = await getDocs(q);
      snapshots.forEach(async (doc: any) => {
        const qa = query(
          collection(firestore, "shops", doc.ref.id, "appointments"),
          where("user", "==", user.uid),
          where("status", "in", ["pending", "accepted"])
        );
        const snapshots_a = await getDocs(qa);
        snapshots_a.forEach((d: any) => {
          changeStatus(
            {
              ...d.data(),
              id: d.id,
            },
            "declined"
          );
        });
        const storageRef = ref(storage, `shops/${doc.ref.id}`);
        if (storageRef) {
          await listAll(storageRef)
            .then((res) => {
              res.items.forEach((itemRef) => {
                deleteObject(itemRef);
              });
            })
            .catch((error) => {
              console.error(error);
            });
        }
        deleteDoc(doc.ref);
      });

      data?.map(async (appointment: any) => {
        if (
          appointment.status === "pending" ||
          appointment.status === "accepted"
        ) {
          await changeStatus(appointment, "cancelled");
        }
      });

      const customer_doc = await doc(firestore, "customers", user.uid);
      await deleteDoc(customer_doc);

      await deleteUserAuth(auth.currentUser as User).then(() => {
        auth.signOut();
        toast({
          title: t("AccountDeleted"),
          description: t("AccountDeletedDescription"),
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      });
      setSubmitted(false);
      navigate("/");
    } catch (error: any) {
      const errorCode: any = error?.code;
      toast({
        title: t("Error"),
        description: errors[errorCode] || t("AnErrorOccurred"),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  if (!user) {
    return <div>Not logged in</div>;
  }
  return (
    <>
      <ScaleFade initialScale={0.9} in={true} style={{ width: "100%" }}>
        <Alert status="success" variant="left-accent">
          <AlertIcon />
          {t("YourEmailIsVerified")}
        </Alert>
      </ScaleFade>
      <Divider height={"20px"} />
      {/* <Alert status="info" variant="left-accent">
          <AlertIcon />
        </Alert> */}

      <FormControl isRequired my={"20px"}>
        <FormLabel>{t("DisplayName")}</FormLabel>
        <Input
          type="text"
          value={displayName || ""}
          onChange={(e) => setDisplayName(e.target.value)}
          isInvalid={submitted && !displayName}
          errorBorderColor="crimson"
          disabled={submitted}
        />
        <FormHelperText>{t("DisplayNameSpecification")}</FormHelperText>
      </FormControl>
      <FormControl isRequired>
        <FormLabel>{t("Email")}</FormLabel>
        <Input type="text" value={user.email || ""} disabled={true} />
        <FormHelperText>{t("EmailSpecification")}</FormHelperText>
      </FormControl>
      <Divider height={"20px"} />
      <Button
        onClick={onOpen}
        type="button"
        colorScheme="red"
        marginTop={"20px"}
        float={"right"}
        variant={"ghost"}
        disabled={submitted}
        opacity={submitted ? 0.3 : 1}
      >
        {t("DeleteAccount")}
      </Button>
      <Button
        onClick={updateUser}
        type="submit"
        marginY={"60px"}
        variant={"main"}
        w="100%"
        disabled={submitted}
        opacity={submitted ? 0.3 : 1}
      >
        {submitted ? <Spinner /> : t("SaveChanges")}
      </Button>
      {/* <h1>Profile</h1>
      <p>Email: {user.email}</p>
      <p>UID: {user.uid}</p> */}
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent maxW={["90%", "md"]}>
          <ModalHeader>{t("DeleteAccount")}</ModalHeader>
          <ModalCloseButton
            onClick={() => {
              onClose();
              setCanDelete(false);
            }}
          />
          {hasActiveSubscription ? (
            <>
              <ModalBody>
                <Alert
                  status="error"
                  variant="left-accent"
                  marginBottom={"20px"}
                >
                  <AlertIcon />
                  <VStack>
                    <Text>{t("YouHaveAnActiveSubscription")}</Text>
                    <Text>{t("CancelSubscriptionFromDashboard")}</Text>
                  </VStack>
                </Alert>
              </ModalBody>
              <ModalFooter></ModalFooter>
            </>
          ) : (
            <>
              <ModalBody>
                <Alert
                  status="warning"
                  variant="left-accent"
                  marginBottom={"20px"}
                >
                  <AlertIcon />
                  {t("DeleteAccountAlert")}
                </Alert>
                {/* <Container> */}
                <Trans>
                  {t("Type")}{" "}
                  <Code colorScheme="red" fontSize={"12px"}>
                    {user.email}
                  </Code>{" "}
                  {t("ToConfirm")}.
                </Trans>
                <Input
                  size="sm"
                  marginTop={"10px"}
                  placeholder={t("DeleteAccountInputPlaceholder")}
                  onChange={(e) => {
                    setCanDelete(e.target.value === user.email);
                  }}
                />
                {/* </Container> */}
              </ModalBody>
              <ModalFooter>
                <Button
                  isLoading={!canDelete}
                  onClick={() => {
                    deleteUser();
                  }}
                  colorScheme="red"
                  disabled={!canDelete}
                  w={"100%"}
                  size={"sm"}
                  leftIcon={<PiWarningOctagonFill />}
                >
                  {t("PermanentlyDeleteAccountAndData")}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
