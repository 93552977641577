import { useToast } from "@chakra-ui/react";
import { format } from "date-fns";
import { FaCheckDouble } from "react-icons/fa";
import { MdPending } from "react-icons/md";
import { GiCancel } from "react-icons/gi";
import { PiMailbox } from "react-icons/pi";
import { el, enUS } from "date-fns/locale";
import i18n from "../i18n";
import { IShop } from "../interfaces/interfaces";

export const getDateAndTime = (date: Date, hour: number, minute: number) => {
  let d = new Date(date);
  d.setHours(hour);
  d.setMinutes(minute);
  d.setSeconds(0);
  return d;
};

export const _mainColor = () => {
  return "#3a56ff";
};

export const getActiveColor = (active: boolean) => {
  if (active) {
    return "green";
  }
  return "red";
};

export const languageCode = () => {
  switch (i18n.language) {
    case "el":
      return el;
    case "en":
      return enUS;
    default:
      return el;
  }
};

export const languageCodeString = () => {
  switch (i18n.language) {
    case "el":
      return "el";
    case "en":
      return "en";
    default:
      return "el";
  }
};

// export const renderSubscriptionStatus = (subscription: string) => {

// }

export const getAppointmentStatusColor = (status: string) => {
  switch (status) {
    case "pending":
      return "gray";
    case "accepted":
      return "green";
    case "declined":
      return "red";
    case "cancelled":
      return "red";
    default:
      return "gray";
  }
};

export const getAppointmentStatusIcon = (status: string) => {
  switch (status) {
    case "pending":
      return <MdPending />;
    case "accepted":
      return <FaCheckDouble />;
    case "declined":
      return <GiCancel />;
    case "cancelled":
      return <GiCancel />;
    default:
      return <MdPending />;
  }
};

export const formatFirebaseDate = (
  date: any,
  forceEnglishFormat: boolean = false
) => {
  if (!date) {
    return "";
  }
  return format(date.toDate(), "eee dd/MM/yy kk:mm", {
    locale: forceEnglishFormat ? enUS : languageCode(),
  });
};

export const formatDate = (
  date: any,
  forceEnglishFormat: boolean = false
): any => {
  if (!date) {
    return "";
  }
  return format(date, "eee dd/MM/yy kk:mm", {
    locale: forceEnglishFormat ? enUS : languageCode(),
  });
};

export const isShopAvailable = (shop: IShop) => {
  if (!shop) {
    return false;
  }
  if (!shop.active) {
    return false;
  }
  if (shop.subscription) {
    if (shop.subscription.status === "active") {
      return true;
    }
    if (shop.subscription.status === "trialing") {
      return true;
    }
  }
};
