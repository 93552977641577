import {
  query,
  collection,
  where,
  getDocs,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { url } from "inspector";
import { useEffect, useState } from "react";
import { auth, firestore, storage } from "../firebase";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Button,
  Center,
  Checkbox,
  Code,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  ScaleFade,
  Spinner,
  Stack,
  Switch,
  useDisclosure,
  useToast,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { useAuthState } from "react-firebase-hooks/auth";
import { LoadingAnimation } from "../repeatable-components/RepeatableComponents";
import { Link, redirect, useNavigate, useParams } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
  deleteObject,
  ref,
  listAll,
} from "firebase/storage";
import { deleteUser } from "firebase/auth";
import { PiWarningOctagonFill } from "react-icons/pi";
import { getShopSubscription } from "../stripe/Stripe";
import { LuExternalLink } from "react-icons/lu";
import { Trans, useTranslation } from "react-i18next";
import { Formik } from "formik";
import { changeStatus } from "../classes/Appointment";

export const ShopEdit = () => {
  const { t } = useTranslation();
  const email_regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const toast = useToast();
  const { url } = useParams();
  const navigate = useNavigate();
  const [shop, setShop] = useState<any>(null);
  const [shopRef, setShopRef] = useState<any>(null);
  const [user]: any = useAuthState(auth);
  const [loading, setIsLoading] = useState(true);
  const availableDays = [
    t("Monday"),
    t("Tuesday"),
    t("Wednesday"),
    t("Thursday"),
    t("Friday"),
    t("Saturday"),
    t("Sunday"),
  ];
  const [logo, setLogo] = useState<any>(null);
  const [logoChanged, setLogoChanged] = useState(false);
  const [urlExists, setUrlExists] = useState(false);
  const [activeAppointments, setActiveAppointments] = useState([]);
  const [fetchedUrls, setFetchedUrls] = useState<any>([]);

  const [canDelete, setCanDelete] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const checkUrl = async (shopUrl: any) => {
    if (!shopUrl || urlExists) return;
    if (shopUrl == url) {
      setUrlExists(false);
      return;
    }
    if (fetchedUrls.includes(shopUrl)) {
      setUrlExists(true);
      return;
    }
    const q = query(
      collection(firestore, "shops"),
      where("url", "==", shopUrl)
    );
    const snapshot = await getDocs(q);
    if (snapshot.size > 0) {
      setFetchedUrls([
        ...fetchedUrls,
        snapshot.docs.map((doc) => doc.data().url),
      ]);
      toast({
        title: t("Oops"),
        description: t("UrlAlreadyTaken"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setUrlExists(true);
    } else {
      setUrlExists(false);
    }
  };

  useEffect(() => {
    const get = async () => {
      const q = query(
        collection(firestore, "shops"),
        where("url", "==", url),
        where("owner", "==", user?.uid),
        where("active", "in", [true, false])
      );
      const snapshot: any = await getDocs(q);
      if (snapshot.empty) {
        toast({
          title: t("ShopNotFound"),
          description: t("ShopDoesNotExist"),
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        navigate("/dashboard");
        return;
      }
      let shop = snapshot.docs[0].data();
      shop.id = snapshot.docs[0].id;
      shop.subscription = await getShopSubscription(shop);
      setShop(shop);

      const qa = query(
        collection(firestore, "shops", shop.id, "appointments"),
        where("status", "not-in", ["cancelled", "declined"]),
        where("date", ">=", new Date())
      );
      const snapshop_appointments: any = await getDocs(qa);
      let appointments = snapshop_appointments.docs.map((doc: any) => {
        doc.data();
      });
      setActiveAppointments(appointments);
      setShopRef(snapshot.docs[0].ref);
      setIsLoading(false);
    };

    get();
  }, []);

  const uploadLogo = (docRef: any) => {
    if (!logoChanged || !logo) {
      return;
    }
    const imageRef = storageRef(storage, `shops/${docRef.id}/logo`);
    deleteObject(imageRef)
      .then(() => {
        uploadBytes(imageRef, logo)
          .then((snapshot) => {
            getDownloadURL(imageRef).then((url) => {
              updateDoc(docRef, {
                logoUrl: url,
              });
            });
          })
          .catch((error) => {
            toast({
              title: t("Oops"),
              description: t("ErrorUploadingLogo"),
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          });
      })
      .catch((error) => {
        uploadBytes(imageRef, logo)
          .then((snapshot) => {
            getDownloadURL(imageRef).then((url) => {
              updateDoc(docRef, {
                logoUrl: url,
              });
            });
          })
          .catch((error) => {
            toast({
              title: t("Oops"),
              description: t("ErrorUploadingLogo"),
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          });
      });
  };

  const deleteShop = async () => {
    const qa = query(
      collection(firestore, "shops", shop.id, "appointments"),
      where("date", ">=", new Date()),
      where("status", "in", ["pending", "accepted"])
    );
    const snapshots_a = await getDocs(qa);
    for (const i in snapshots_a.docs) {
      const doc = snapshots_a.docs[i];

      changeStatus(
        {
          ...(doc.data() as any),
          id: doc.id,
        },
        "declined"
      );
    }
    const storageRef = ref(storage, `shops/${shop.id}`);
    if (storageRef) {
      await listAll(storageRef)
        .then((res) => {
          res.items.forEach((itemRef) => {
            deleteObject(itemRef);
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
    deleteDoc(shopRef);
    toast({
      title: t("ShopDeleted"),
      description: t("ShopDeletedSpecification"),
      status: "success",
      duration: 9000,
      isClosable: true,
    });

    navigate("/dashboard");
  };

  return (
    <>
      {loading ? (
        <LoadingAnimation />
      ) : (
        <>
          <ScaleFade initialScale={0.9} in={true} style={{ width: "100%" }}>
            <Formik
              initialValues={{
                name: shop.name,
                url: shop.url,
                description: shop.description,
                email: shop.email,
                phone: shop.phone,
                duration: shop.duration,
                slots: shop.slots,
                bookLimit: shop.bookLimit,
                timeslots: JSON.parse(shop.timeslots),
                days: JSON.parse(shop.days),
                active: shop.active,
              }}
              validate={(values) => {
                const errors: any = {};
                if (!values.name) {
                  errors.name = t("FieldIsRequired");
                }
                if (!values.url) {
                  errors.url = t("FieldIsRequired");
                } else if (urlExists) {
                  errors.url = t("UrlAlreadyTaken");
                }
                if (!values.email) {
                  errors.email = t("FieldIsRequired");
                }
                if (!values.phone) {
                  errors.phone = t("FieldIsRequired");
                } else if (values.phone.length !== 13) {
                  errors.phone = t("InvalidPhoneNumber");
                }
                if (!values.duration) {
                  errors.duration = t("FieldIsRequired");
                } else if (values.duration < 10 || values.duration > 60) {
                  errors.duration = t("ValueOutOfRange");
                }
                if (!values.slots) {
                  errors.slots = t("FieldIsRequired");
                } else if (values.slots < 0 || values.slots > 10) {
                  errors.slots = t("ValueOutOfRange");
                }
                if (!values.bookLimit) {
                  errors.bookLimit = t("FieldIsRequired");
                } else if (values.bookLimit < 0 || values.bookLimit > 120) {
                  errors.bookLimit = t("ValueOutOfRange");
                }

                return errors;
              }}
              onSubmit={async (values, { setSubmitting }) => {
                await updateDoc(shopRef, {
                  name: values.name,
                  url: values.url,
                  description: values.description || "",
                  email: values.email,
                  phone: values.phone,
                  duration: values.duration,
                  slots: values.slots,
                  bookLimit: values.bookLimit,
                  timeslots: JSON.stringify(values.timeslots),
                  days: JSON.stringify(values.days),
                  active: values.active,
                }).then((res) => {
                  toast({
                    title: t("Success"),
                    description: t("YourChangesHaveBeenSaved"),
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  });
                  uploadLogo(shopRef);

                  setSubmitting(false);
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                setFieldValue,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }: /* and other goodies */
              any) => (
                <form onSubmit={handleSubmit}>
                  <>
                    <FormControl display="flex" alignItems="center" my="30px">
                      <FormLabel htmlFor="active" mb="0">
                        {t("Active")}
                      </FormLabel>
                      <Switch
                        id="active"
                        size="lg"
                        colorScheme="teal"
                        isChecked={values.active}
                        name={"active"}
                        onChange={handleChange}
                        disabled={isSubmitting}
                      />
                    </FormControl>
                    {!values.active && (
                      <Alert status="warning" variant="left-accent" my="10px">
                        <AlertIcon />
                        {t("YourShopIsInactive")}
                      </Alert>
                    )}
                    <FormControl>
                      <FormLabel>{t("Name")}</FormLabel>
                      <Input
                        type="text"
                        variant={"outline"}
                        value={values.name}
                        name="name"
                        onChange={handleChange}
                        isInvalid={errors.name && touched.name && errors.name}
                        errorBorderColor="crimson"
                        disabled={isSubmitting}
                      />
                      <FormHelperText>
                        {t("GiveANameSpecification")}
                      </FormHelperText>
                    </FormControl>
                    <FormControl marginTop={"20px"}>
                      <FormLabel>{t("Url")}</FormLabel>
                      <Stack spacing={4}>
                        {/* If you add the size prop to `InputGroup`, it'll pass it to all its children. */}
                        <InputGroup size="sm">
                          <InputLeftAddon padding={"25px 20px"}>
                            {window.location.origin}/shop/
                          </InputLeftAddon>
                          <Input
                            type="text"
                            onChange={(e) => {
                              const v = e.target.value
                                .replace(
                                  /[`~!@#$%^&*()_|+\=?;:'",.<>\s+\{\}\[\]\\\/]/gi,
                                  ""
                                )
                                .toLowerCase();
                              setUrlExists(false);
                              setFieldValue("url", v);
                            }}
                            value={values.url}
                            name="url"
                            isInvalid={
                              (errors.url && touched.url && errors.url) ||
                              urlExists
                            }
                            errorBorderColor="crimson"
                            onBlur={(e) => {
                              checkUrl(e.target.value);
                            }}
                            disabled={isSubmitting}
                          />
                        </InputGroup>
                      </Stack>
                      <Text color="red.500" size={"sm"}>
                        {errors.url && touched.url && errors.url}{" "}
                      </Text>
                      <FormHelperText>{t("UrlSpecification")}</FormHelperText>
                    </FormControl>
                    <FormControl marginTop={"20px"}>
                      <FormLabel>{t("GiveADescription")}</FormLabel>
                      <Textarea
                        variant={"outline"}
                        value={values.description}
                        name="description"
                        onChange={handleChange}
                      />
                      <FormHelperText>
                        {t("GiveADescriptionSpecification")}
                      </FormHelperText>
                    </FormControl>
                    <FormControl marginTop={"20px"}>
                      <FormLabel>{t("Logo")}</FormLabel>
                      <HStack spacing={10}>
                        {(logo || shop.logoUrl) && (
                          <Avatar
                            size="xl"
                            src={
                              logoChanged
                                ? URL.createObjectURL(logo)
                                : shop.logoUrl
                            }
                          />
                        )}
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                        >
                          <Input
                            id="logo"
                            border={"0"}
                            padding={"0"}
                            type="file"
                            variant={"outline"}
                            display={"none"}
                            accept="image/*"
                            onChange={(e: any) => {
                              if (e.target.files.length === 0) {
                                return;
                              }
                              // File size limit to 10MB
                              if (e.target.files[0].size / 1024 ** 2 > 10) {
                                toast({
                                  title: t("Oops"),
                                  description: t("FileSizeLimit"),
                                  status: "error",
                                  duration: 3000,
                                  isClosable: true,
                                });
                                e.target.value = "";
                                return;
                              }
                              setLogoChanged(true);
                              setLogo(e.target.files[0]);
                            }}
                          />
                          <Button
                            as="label"
                            htmlFor="logo"
                            variant={"main"}
                            size="sm"
                            cursor="pointer"
                            _hover={{ bg: "blue.600" }}
                            _focus={{ boxShadow: "outline" }}
                          >
                            {logo ? t("ChangeFile") : t("UploadFile")}
                          </Button>
                        </Box>
                      </HStack>
                      <FormHelperText>{t("LogoSpecification")}</FormHelperText>
                    </FormControl>
                    <FormControl marginTop={"20px"}>
                      <FormLabel>{t("Email")}</FormLabel>
                      <Input
                        type="email"
                        name="email"
                        value={values.email}
                        //   onChange={(e) => setEmail(e.target.value)}
                        disabled={true}
                        //   isInvalid={submitted && (!email || !email_regex.test(email))}
                        //   errorBorderColor="crimson"
                      />

                      <FormHelperText>
                        {shop.email != values.email && (
                          <Alert status="error" variant="left-accent">
                            <AlertIcon />
                            Email is different from the one you registered with.
                            If you press save, the email will be updated.
                          </Alert>
                        )}
                        <Alert status="info" variant="left-accent">
                          <AlertIcon />
                          {t("EmailNotice")}
                        </Alert>
                      </FormHelperText>
                    </FormControl>

                    <FormControl marginTop={"20px"}>
                      <FormLabel>{t("PhoneNumber")}</FormLabel>
                      <PhoneInput
                        defaultCountry="GR"
                        value={values.phone}
                        autoComplete="off"
                        name="phone"
                        style={{
                          border: `${
                            errors.phone && touched.phone && errors.phone
                              ? "1px solid crimson"
                              : "1px solid #e2e8f0"
                          }`,
                          boxShadow: `${
                            errors.phone && touched.phone && errors.phone
                              ? "0 0 0 1px crimson"
                              : ""
                          }`,
                          backgroundColor: "white",
                          color: "black",
                          padding: "15px 20px",
                          borderRadius: "3px",
                        }}
                        className={`chakra-input css-1yqhqcq focus-none`}
                        onChange={(e: any) => {
                          handleChange("phone")(e ? e : "");
                        }}
                        disabled={isSubmitting}
                      />
                      <Text color="red.500" size={"sm"}>
                        {errors.phone && touched.phone && errors.phone}
                      </Text>
                      <FormHelperText>
                        {t("PhoneNumberSpecification")}
                      </FormHelperText>
                    </FormControl>
                    {activeAppointments.length > 0 ? (
                      <>
                        <Alert
                          status="error"
                          variant="left-accent"
                          size={"lg"}
                          my="10px"
                        >
                          <AlertIcon />
                          {t("YouHaveActiveFutureAppointments")}
                        </Alert>
                        {/* <Link to={`/dashboard/shop/${shop.url}/appointments`}>
                      <Button colorScheme="blue" w="100%">
                        Go to Appointments
                      </Button>
                    </Link> */}
                      </>
                    ) : (
                      <>
                        <FormControl marginTop={"20px"}>
                          <FormLabel>{t("DayAvailability")}</FormLabel>
                          <>
                            <Checkbox
                              isChecked={values.days.every(Boolean)}
                              // isIndeterminate={isIndeterminate}
                              // name="days"
                              onChange={(e) => {
                                setFieldValue(
                                  "days",
                                  Array(availableDays.length).fill(
                                    e.target.checked
                                  )
                                );
                              }}
                              disabled={isSubmitting}
                            >
                              {t("AllDays")}
                            </Checkbox>
                            <Accordion defaultIndex={[]} allowMultiple>
                              {/* <Stack pl={6} mt={1} spacing={1}> */}
                              {availableDays.map((day, index) => (
                                <AccordionItem key={index}>
                                  <AccordionButton>
                                    <Checkbox
                                      // name={`days[${index}]`}
                                      key={`day_${index}`}
                                      isChecked={values.days[index]}
                                      onChange={(e) => {
                                        const newItems: boolean[] = [
                                          ...values.days,
                                        ];
                                        newItems[index] = e.target.checked;
                                        setFieldValue("days", newItems);
                                        //   handleChange("days")(newItems);
                                        //   setDays(newItems);
                                      }}
                                      disabled={isSubmitting}
                                    >
                                      {day}
                                    </Checkbox>
                                    <Box as="span" flex="1" textAlign="right">
                                      <AccordionIcon />
                                    </Box>
                                  </AccordionButton>
                                  <AccordionPanel pb={4}>
                                    <Center>
                                      <Stack direction="row" spacing={4}>
                                        <Heading size={"md"}>
                                          {values.timeslots[index][0]}:00 -{" "}
                                          {values.timeslots[index][1]}
                                          :00
                                        </Heading>
                                        <Button
                                          size="xs"
                                          alignSelf={"right"}
                                          variant={"main"}
                                          onClick={() => {
                                            let timeslot =
                                              values.timeslots[index];
                                            let timeslotsCopy = [
                                              ...values.timeslots,
                                            ];
                                            timeslotsCopy =
                                              values.timeslots.map(
                                                (item: any) => {
                                                  return timeslot;
                                                }
                                              );
                                            setFieldValue(
                                              "timeslots",
                                              timeslotsCopy
                                            );
                                            toast({
                                              title: t("Success"),
                                              description:
                                                t("TimeslotsApplied"),
                                              status: "success",
                                              duration: 3000,
                                              isClosable: true,
                                            });
                                          }}
                                          disabled={isSubmitting}
                                        >
                                          {t("ApplyToAllDays")}
                                        </Button>
                                      </Stack>
                                    </Center>
                                    <RangeSlider
                                      aria-label={["min", "max"]}
                                      key={`timeslot_${index}`}
                                      defaultValue={[0, 24]}
                                      name={`timeslots[${index}]`}
                                      value={values.timeslots[index]}
                                      max={24}
                                      min={0}
                                      onChange={(v) => {
                                        const newItems: any = [
                                          ...values.timeslots,
                                        ];
                                        newItems[index] = v;
                                        //   setTimeslots(newItems);
                                        setFieldValue("timeslots", newItems);
                                      }}
                                      isDisabled={isSubmitting}
                                    >
                                      <RangeSliderTrack>
                                        <RangeSliderFilledTrack />
                                      </RangeSliderTrack>
                                      <RangeSliderThumb index={0} />
                                      <RangeSliderThumb index={1} />
                                    </RangeSlider>
                                  </AccordionPanel>
                                </AccordionItem>
                              ))}
                              {/* </Stack> */}
                            </Accordion>
                          </>
                          <FormHelperText>
                            {t("DayAvailabilitySpecification")}
                          </FormHelperText>
                        </FormControl>
                        <FormControl marginTop={"20px"}>
                          <FormLabel>{t("Duration")}</FormLabel>
                          <NumberInput
                            defaultValue={values.duration}
                            value={values.duration}
                            step={0}
                            isInvalid={
                              errors.duration &&
                              touched.duration &&
                              errors.duration
                            }
                            name="duration"
                            errorBorderColor="crimson"
                            onChange={(e) => {
                              setFieldValue("duration", e);
                            }}
                            isDisabled={isSubmitting}
                          >
                            <NumberInputField />
                          </NumberInput>
                          <Text color="red.500" size={"sm"}>
                            {errors.duration &&
                              touched.duration &&
                              errors.duration}{" "}
                          </Text>
                          <FormHelperText>
                            {t("DurationSpecification")}
                          </FormHelperText>
                        </FormControl>
                        <FormControl marginTop={"20px"}>
                          <FormLabel>{t("AvailableSlotPerBooking")}</FormLabel>
                          <NumberInput
                            defaultValue={values.slots}
                            value={values.slots}
                            step={0}
                            isInvalid={
                              errors.slots && touched.slots && errors.slots
                            }
                            errorBorderColor="crimson"
                            name="slots"
                            onChange={(e) => {
                              setFieldValue("slots", e);
                            }}
                            isDisabled={isSubmitting}
                          >
                            <NumberInputField />
                          </NumberInput>
                          <Text color="red.500" size={"sm"}>
                            {errors.slots && touched.slots && errors.slots}{" "}
                          </Text>
                          <FormHelperText>
                            {t("AvailableSlotPerBookingSpecification")}
                          </FormHelperText>
                        </FormControl>
                        <FormControl marginTop={"20px"}>
                          <FormLabel>
                            {t("BookLimitation")} ({t("InDays")})
                          </FormLabel>
                          <NumberInput
                            defaultValue={values.bookLimit}
                            value={values.bookLimit}
                            step={0}
                            isInvalid={
                              errors.bookLimit &&
                              touched.bookLimit &&
                              errors.bookLimit
                            }
                            errorBorderColor="crimson"
                            name="bookLimit"
                            onChange={(e) => {
                              setFieldValue("bookLimit", e);
                            }}
                            isDisabled={isSubmitting}
                          >
                            <NumberInputField />
                          </NumberInput>
                          <Text color="red.500" size={"sm"}>
                            {errors.bookLimit &&
                              touched.bookLimit &&
                              errors.bookLimit}{" "}
                          </Text>
                          <FormHelperText>
                            {t("BookLimitationSpecification")}
                          </FormHelperText>
                        </FormControl>
                      </>
                    )}
                    <Button
                      isLoading={isSubmitting}
                      onClick={onOpen}
                      type="button"
                      colorScheme="red"
                      marginTop={"20px"}
                      float={"right"}
                      variant={"ghost"}
                    >
                      {t("DeleteShop")}
                    </Button>
                    <Button
                      isLoading={isSubmitting}
                      type="submit"
                      variant={"main"}
                      marginY={"20px"}
                      w="100%"
                    >
                      {t("SaveChanges")}
                    </Button>
                  </>
                </form>
              )}
            </Formik>
          </ScaleFade>
        </>
      )}
      {shop && (
        <Modal onClose={onClose} isOpen={isOpen} isCentered>
          <ModalOverlay />
          <ModalContent maxW={["90%", "md"]}>
            <ModalHeader>
              {t("Delete")} {shop.name}
            </ModalHeader>
            <ModalCloseButton
              onClick={() => {
                onClose();
                setCanDelete(false);
              }}
            />

            {shop.subscription &&
            (shop.subscription.status === "active" ||
              shop.subscription.status === "trialing") &&
            shop.subscription.cancel_at == null ? (
              <>
                <ModalBody>
                  <Alert
                    status="error"
                    variant="left-accent"
                    marginBottom={"20px"}
                  >
                    <AlertIcon />
                    <VStack>
                      <Text>{t("YouHaveAnActiveSubscription")}</Text>
                      <Text>{t("CancelSubscriptionFromDashboard")}</Text>
                      {/* <Button
                        colorScheme="red"
                        size="sm"
                        marginTop={"10px"}
                        onClick={async () => {
                        //   await fetch(
                        //     `${process.env.REACT_APP_FUNCTIONS_URI}/cancel`,
                        //     {
                        //       method: "POST",
                        //       headers: {
                        //         "Content-Type": "application/json",
                        //         Authorization: `${await auth.currentUser?.getIdToken()}`,
                        //       },
                        //       body: JSON.stringify({
                        //         priceId: shop.subscription.priceId,
                        //         shopId: shop.id,
                        //         shopOwner: shop.owner,
                        //         subscriptionId: shop.subscription.id,
                        //       }),
                        //     }
                        //   )
                        //     .then(() => {
                        //       toast({
                        //         title: t("SubscriptionCancelled"),
                        //         description: t(
                        //           "SubscriptionCancelledDescription"
                        //         ),
                        //         status: "success",
                        //         duration: 9000,
                        //         isClosable: true,
                        //       });

                        //       onClose();
                        //     })
                        //     .catch((error) => {
                        //       toast({
                        //         title: t("Oops"),
                        //         description: t("SomethingWentWrong"),
                        //         status: "error",
                        //         duration: 9000,
                        //         isClosable: true,
                        //       });
                        //     });
                        }}
                      >
                        {t("CancelSubscription")}
                      </Button> */}
                    </VStack>
                    {/* <Link to={shop.subscription.stripeLink}>
                      <Button
                        colorScheme="red"
                        size="sm"
                        leftIcon={<LuExternalLink />}
                      >
                        {t("CancelSubscription")}
                      </Button>
                    </Link> */}
                    {/* <a href={shop.subscription.stripeLink}>Stripe Page</a> */}
                  </Alert>
                </ModalBody>
                <ModalFooter>
                  <Button
                    onClick={() => {
                      onClose();
                      setCanDelete(false);
                    }}
                    variant={"ghost"}
                    size="sm"
                  >
                    {t("Cancel")}
                  </Button>
                </ModalFooter>
              </>
            ) : (
              <>
                <ModalBody>
                  <Alert
                    status="warning"
                    variant="left-accent"
                    marginBottom={"20px"}
                  >
                    <AlertIcon />
                    {t("ShopDeletionWarning")}
                  </Alert>
                  <Trans>
                    {t("Type")}{" "}
                    <Code colorScheme="red" fontSize={"12px"}>
                      {shop.url}
                    </Code>{" "}
                    {t("ToConfirm")}.
                  </Trans>
                  <Input
                    size="sm"
                    marginTop={"10px"}
                    placeholder={t("DeleteShopInputPlaceholder")}
                    onChange={(e) => {
                      setCanDelete(e.target.value === shop.url);
                    }}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button
                    isLoading={!canDelete}
                    onClick={() => {
                      deleteShop();
                    }}
                    colorScheme="red"
                    disabled={!canDelete}
                    w={"100%"}
                    size={"sm"}
                    leftIcon={<PiWarningOctagonFill />}
                  >
                    {t("PermanentlyDeleteShopAndData")}
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
      )}
    </>
  );
};
