import {
  Alert,
  AlertDescription,
  AlertIcon,
  Container,
  Heading,
  HStack,
  ScaleFade,
} from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";

export const GlobalInformation = () => {
  const { t } = useTranslation();
  return (
    <ScaleFade initialScale={0.9} in={true} style={{ width: "100%" }}>
      <Container maxW="container.2xl" my="10px">
        <Alert
          style={{
            // background: "rgb(0,80,110)",
            borderRadius: "4px",
            color: "white",
            background:
              "linear-gradient(45deg, rgba(58,86,255,1) 0%, rgba(31,45,128,1) 100%)",
          }}
        >
          <AlertDescription style={{ display: "block" }}>
            <Trans>
              {/* {t("GlobalMessage", {
                email: (
                  <a href="mailto:support@apppoint.gr">support@apppoint.gr</a>
                ),
              })} */}
              {t("GlobalMessage")}
              <a href="mailto:support@apppoint.gr">support@apppoint.gr</a>.
            </Trans>
          </AlertDescription>
        </Alert>
      </Container>
    </ScaleFade>
  );
};
