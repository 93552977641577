import React, { useState } from "react";
import TopMenu from "./TopMenu";
import Footer from "./Footer";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Shop } from "./Shop";
import { AuthModalProvider, useAuthModal } from "../context/AuthModalContext";
import { AuthModal } from "./AuthModal";
import { Alert, AlertIcon, Container, Heading } from "@chakra-ui/react";
import { CreateShop } from "./CreateShop";
import { Appointments } from "./Appointments";
import { TopBreadcrumb } from "./TopBreadcrumb";
import { LoadingAnimation } from "../repeatable-components/RepeatableComponents";
import { matchPath, Route, Routes, useLocation } from "react-router-dom";
import { NotFound } from "../pages/404";
import { ShopEdit } from "./ShopEdit";
import { Profile } from "./Profile";
import { UpdateSubscription } from "./UpdateSubscription";
import { SignInReminder } from "../pages/SignInReminder";
import { MyAppointments } from "./MyAppointments";
import { GlobalInformation } from "./GlobalInformation";
import { useTranslation } from "react-i18next";
import MainPanel from "../theme/components/Layout/MainPanel";
import Sidebar from "../theme/components/Sidebar";
import PanelContent from "../theme/components/Layout/PanelContent";
import PanelContainer from "../theme/components/Layout/PanelContainer";
import Home from "../pages/Home";
import {
  PrivacyPolicEl,
  PrivacyPolicEn,
  TermsOfServiceEl,
  TermsOfServiceEn,
} from "../pages/Terms";
import { AppDashboard } from "./AppDashboard";

export const App = (props: any) => {
  const [user, loading] = useAuthState(auth);
  const { isModalOpen, modalType, closeModal } = useAuthModal();
  const { t } = useTranslation();
  const location = useLocation();
  const isShopRoute = matchPath("/shop/:url", location.pathname);
  const isHomeRoute = matchPath("/", location.pathname);
  const isTermsOfServiceRoute = matchPath(
    "/terms-of-service/:lang",
    location.pathname
  );
  const isPrivacyPolicyRoute = matchPath(
    "/privacy-policy/:lang",
    location.pathname
  );
  const { ...rest } = props;
  // states and functions
  const [sidebarVariant, setSidebarVariant] = useState("transparent");

  return (
    <>
      <AuthModal isOpen={isModalOpen} onClose={closeModal} type={modalType} />

      {(isTermsOfServiceRoute || isPrivacyPolicyRoute) && (
        <MainPanel
          w={{
            base: "100%",
          }}
        >
          <PanelContent>
            <PanelContainer>
              <Routes>
                <Route
                  path="/terms-of-service/en"
                  element={<TermsOfServiceEn />}
                />
                <Route
                  path="/terms-of-service/el"
                  element={<TermsOfServiceEl />}
                />
                <Route path="/privacy-policy/en" element={<PrivacyPolicEn />} />
                <Route path="/privacy-policy/el" element={<PrivacyPolicEl />} />
              </Routes>
            </PanelContainer>
          </PanelContent>
        </MainPanel>
      )}
      <Routes>
        <Route
          path="/"
          element={
            <>
              <TopMenu />
              <Home />
              <Footer />
            </>
          }
        />
      </Routes>

      {!isHomeRoute && !isTermsOfServiceRoute && !isPrivacyPolicyRoute && (
        <>
          {loading && (
            <MainPanel
              w={{
                base: "100%",
              }}
            >
              <PanelContent>
                <PanelContainer>
                  <LoadingAnimation />
                </PanelContainer>
              </PanelContent>
            </MainPanel>
          )}
          <div>
            <div
              style={
                {
                  // marginBottom: "100px",
                }
              }
            >
              <TopMenu />
              <GlobalInformation />

              <Routes>
                {!user && (
                  <Route
                    path="*"
                    element={
                      <MainPanel
                        w={{
                          base: "100%",
                        }}
                      >
                        <PanelContent>
                          <PanelContainer>
                            <SignInReminder />
                          </PanelContainer>
                        </PanelContent>
                      </MainPanel>
                    }
                  />
                )}
                <Route
                  path="shop/:url"
                  element={
                    <MainPanel
                      w={{
                        base: "100%",
                      }}
                    >
                      <PanelContent>
                        <PanelContainer>
                          <Shop />
                        </PanelContainer>
                      </PanelContent>
                    </MainPanel>
                  }
                />
              </Routes>

              {/* 
  Routes below are matched exclusively -
  the first matched route gets rendered
*/}
              {!isShopRoute &&
                !isTermsOfServiceRoute &&
                !isPrivacyPolicyRoute && (
                  <>
                    {user && (
                      <>
                        {!user.emailVerified ? (
                          <>
                            <Container maxW="container.md" my="10px">
                              <Alert status="warning" variant="left-accent">
                                <AlertIcon />
                                {t("YourEmailIsUnverified")}
                              </Alert>
                            </Container>
                          </>
                        ) : (
                          <>
                            <Sidebar
                              logoText={"Apppoint.gr"}
                              display="none"
                              sidebarVariant={sidebarVariant}
                              {...rest}
                            />
                            <MainPanel
                              w={{
                                base: "100%",
                                xl: "calc(100% - 275px)",
                              }}
                            >
                              <PanelContent>
                                <PanelContainer>
                                  <Routes>
                                    <Route path="/dashboard">
                                      <Route index element={<AppDashboard />} />

                                      <Route
                                        path="shop/:url/appointments"
                                        element={<Appointments />}
                                      />
                                      <Route
                                        path="shop/:url/settings"
                                        element={<ShopEdit />}
                                      />
                                      <Route
                                        path="shop/:url/update-subscription"
                                        element={<UpdateSubscription />}
                                      />
                                      <Route
                                        path="create-shop"
                                        element={<CreateShop />}
                                      />
                                    </Route>
                                    <Route
                                      path="user/appointments"
                                      element={<MyAppointments />}
                                    />
                                    <Route
                                      path="user/profile"
                                      element={<Profile />}
                                    />
                                  </Routes>
                                </PanelContainer>
                              </PanelContent>
                            </MainPanel>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              <Footer />
            </div>

            {/* <Footer /> */}

            {/* <Footer /> */}
          </div>
        </>
      )}
    </>
  );
};
