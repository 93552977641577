/*eslint-disable*/
// chakra imports
import {
  Box,
  Button,
  Flex,
  HStack,
  Link,
  Stack,
  Text,
  useColorModeValue,
  Select,
} from "@chakra-ui/react";
import IconBox from "./../Icons/IconBox";
import { CreativeTimLogo } from "./../Icons/Icons";
import { Separator } from "./../Separator/Separator";
import { SidebarHelp } from "./../Sidebar/SidebarHelp";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { MdSpaceDashboard } from "react-icons/md";
import { _mainColor } from "../../../classes/Utils";
import { useTranslation } from "react-i18next";
import { AiOutlineProfile } from "react-icons/ai";
import { LANGUAGES } from "./../../../i18n";
import { LuGlobe2 } from "react-icons/lu";

// this function creates the links and collapses that appear in the sidebar (left menu)

const SidebarContent = ({ logoText, onClose }) => {
  const activeBg = useColorModeValue("white", "gray.700");
  const inactiveBg = useColorModeValue("white", "gray.700");
  const activeColor = useColorModeValue("gray.700", "white");
  const inactiveColor = useColorModeValue("gray.400", "gray.400");
  const { i18n, t } = useTranslation();
  // to check for active links and opened collapses
  let location = useLocation();
  // this is for the rest of the collapses
  const [state, setState] = React.useState({});

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };
  const createLinks = (routes) => {
    // Chakra Color Mode

    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      return (
        <NavLink to={prop.path} key={prop.name}>
          <Button
            boxSize="initial"
            justifyContent="flex-start"
            alignItems="center"
            onClick={() => {
              if (onClose) {
                onClose();
              }
            }}
            bg={activeRoute(prop.path) === "active" ? activeBg : "transparent"}
            mb={{
              xl: "12px",
            }}
            mx={{
              xl: "auto",
            }}
            py="12px"
            ps={{
              sm: "10px",
              xl: "16px",
            }}
            borderRadius="15px"
            _hover="none"
            w="100%"
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}
          >
            <Flex>
              <HStack spacing="20px">
                <IconBox
                  bg={
                    activeRoute(prop.path) === "active"
                      ? _mainColor()
                      : "transparent"
                  }
                  h="30px"
                  w="30px"
                  color={
                    activeRoute(prop.path) === "active" ? "white" : "gray.400"
                  }
                >
                  {prop.icon}
                </IconBox>
                <Text
                  color={
                    activeRoute(prop.path) === "active"
                      ? activeColor
                      : inactiveColor
                  }
                  my="auto"
                  fontSize="sm"
                >
                  {prop.name}
                </Text>
              </HStack>
            </Flex>
          </Button>
        </NavLink>
      );
    });
  };

  return (
    <>
      <Stack direction="column" mb="40px">
        <Box>
          {createLinks([
            {
              path: "/dashboard",
              name: t("Dashboard"),
              icon: <MdSpaceDashboard />,
            },
            {
              path: "/user/appointments",
              name: t("MyAppointments"),
              icon: <AiOutlineProfile />,
            },
          ])}
        </Box>
      </Stack>
      <SidebarHelp />
      <HStack spacing={4}>
        <Box mt="40px">
          <LuGlobe2 />
        </Box>
        <Select
          mt={"40px"}
          className="language-select"
          cursor={"pointer"}
          bg="white"
          defaultValue={localStorage.getItem("lang") || "el"}
          onChange={(e) => {
            i18n.changeLanguage(e.target.value);
            localStorage.setItem("lang", e.target.value);
          }}
        >
          {LANGUAGES.map(({ code, label }) => (
            <option key={code} value={code}>
              {label}
            </option>
          ))}
        </Select>
      </HStack>
    </>
  );
};

export default SidebarContent;
