import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Container,
  Heading,
  HStack,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  Text,
  AvatarBadge,
  VStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Button,
  useToast,
  FormControl,
  FormHelperText,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useDisclosure,
  Divider,
  ScaleFade,
  Flex,
  Icon,
  Stack,
  Box,
} from "@chakra-ui/react";
import Card from "./../theme/components/Card/Card.js";
import CardBody from "./../theme/components/Card/CardBody.js";
import CardHeader from "./../theme/components/Card/CardHeader.js";
import { SiHomebridge } from "react-icons/si";
import {
  _mainColor,
  formatFirebaseDate,
  getActiveColor,
  getAppointmentStatusColor,
  getAppointmentStatusIcon,
} from "../classes/Utils";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { FaCopy, FaPlusCircle, FaQrcode } from "react-icons/fa";
import { MdOutlineCopyAll } from "react-icons/md";
import { CiCirclePlus } from "react-icons/ci";
import { format } from "path";
import { auth, firestore } from "../firebase";
import { doc, Timestamp, updateDoc } from "firebase/firestore";
import { PiSealWarningFill } from "react-icons/pi";
import { RiCheckDoubleLine } from "react-icons/ri";
import { LuExternalLink } from "react-icons/lu";
import { BsClockHistory } from "react-icons/bs";
import QRCode from "react-qr-code";
import { SendEmail } from "../classes/Email";
import {
  ConfirmAppointmentTemplate,
  DeclineAppointmentTemplate,
  CancelAppointmentTemplate,
} from "../email-templates/EmailTemplates";
import { useTranslation } from "react-i18next";
import { useAuthState } from "react-firebase-hooks/auth";
import { LoadingAnimation } from "../repeatable-components/RepeatableComponents";
import { useMyShops } from "../hooks/useShop";

export const AppDashboard = () => {
  const [selectedShop, setSelectedShop] = useState<any>(null);
  const [search, setSearch] = useState("");
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, isLoading, refetch } = useMyShops("dashboard");
  const navigate = useNavigate();
  const {
    isOpen: iIsOpen,
    onOpen: iOnOpen,
    onClose: iOnClose,
  } = useDisclosure();
  const {
    isOpen: confirmationIsOpen,
    onOpen: confirmationOnOpen,
    onClose: confirmationOnClose,
  } = useDisclosure();
  const { t } = useTranslation();

  const downloadQRCode = () => {
    const svg: any = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx: any = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = selectedShop
        ? `${selectedShop.name} QR Code`
        : "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };
  const renderSubscriptionStatus = (shop: any) => {
    if (shop.subscription == null) {
      return (
        <VStack spacing={1}>
          <Text
            alignSelf={"start"}
            color="red"
            fontSize={"sm"}
            fontWeight="bold"
          >
            <HStack>
              <PiSealWarningFill /> <Text>{t("NoSubscription")}</Text>
            </HStack>
          </Text>
          <Link
            to={`/dashboard/shop/${shop.url}/update-subscription`}
            style={{
              alignSelf: "start",
            }}
          >
            <Button
              alignSelf={"start"}
              colorScheme="green"
              variant={"solid"}
              size="xs"
              leftIcon={<CiCirclePlus />}
              title={t("AddSubscription")}
            >
              {t("AddSubscription")}
            </Button>
          </Link>
        </VStack>
      );
    }
    if (
      shop.subscription.status === "active" ||
      shop.subscription.status === "trialing"
    ) {
      return (
        <VStack alignItems={"baseline"} spacing={1}>
          <HStack>
            <Text
              alignSelf={"start"}
              color="green"
              fontSize={"sm"}
              fontWeight="bold"
            >
              <RiCheckDoubleLine />
            </Text>
            <Text color="green" fontSize={"sm"} fontWeight="bold">
              {t("ActiveSubscription")} (
              {shop.subscription.cancel_at_period_end
                ? t("Cancelled")
                : t(shop.subscription.status)}
              )
            </Text>
          </HStack>
          <Text alignSelf={"start"} fontSize={"sm"}>
            {shop.subscription.canceled_at ? (
              <>
                {t("validUntil", {
                  date: formatFirebaseDate(
                    shop.subscription.current_period_end
                  ),
                })}
              </>
            ) : (
              <>
                {t("NextPayment", {
                  date: formatFirebaseDate(
                    shop.subscription.current_period_end
                  ),
                })}
              </>
            )}
          </Text>
        </VStack>
      );
    } else {
      return (
        <VStack alignItems={"baseline"} spacing={1}>
          <Text
            alignSelf={"start"}
            color="red"
            fontSize={"sm"}
            fontWeight="bold"
          >
            <HStack>
              <PiSealWarningFill /> <Text>{t("NoSubscription")}</Text>
            </HStack>
          </Text>

          <Link
            to={`/dashboard/shop/${shop.url}/update-subscription`}
            style={{
              alignSelf: "start",
            }}
          >
            <Button
              alignSelf={"start"}
              colorScheme="green"
              variant={"solid"}
              size="xs"
              leftIcon={<CiCirclePlus />}
              title={t("AddSubscription")}
            >
              {shop.subscription.trialUsed == false ? (
                <>{t("StartDayTrial", { days: 14 })}</>
              ) : (
                <>{t("AddSubscription")}</>
              )}
            </Button>
          </Link>
        </VStack>
      );
    }
  };

  const copyUrl = (url: string) => {
    try {
      navigator.clipboard.writeText(`${window.location.origin}/shop/${url}`);
      toast({
        title: t("UrlCopiedToClipboard"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: t("FailedToCopyUrlToClipboard"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  if (isLoading) {
    return <LoadingAnimation />;
    //   return "Loading...";
  }
  return (
    <>
      {selectedShop && (
        <Modal onClose={onClose} isOpen={isOpen} isCentered>
          <ModalOverlay />
          <ModalContent borderRadius={"10px"} maxW={["90%", "md", "md"]}>
            <>
              <ModalHeader>
                {t("QRCodeFor", { shopName: selectedShop.name || "" })}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <ScaleFade initialScale={0.9} in={isOpen}>
                  <QRCode
                    id="QRCode"
                    size={256}
                    style={{
                      height: "auto",
                      maxWidth: "100%",
                      width: "100%",
                    }}
                    value={`${window.location.origin}/shop/${selectedShop.url}`}
                    viewBox={`0 0 256 256`}
                  />
                </ScaleFade>
              </ModalBody>
              <ModalFooter>
                <HStack>
                  <Button variant={"main"} onClick={() => downloadQRCode()}>
                    {t("DownloadAsImage")}
                  </Button>
                  <Button
                    colorScheme="gray"
                    variant={"outline"}
                    size={"sm"}
                    onClick={onClose}
                  >
                    {t("Close")}
                  </Button>
                </HStack>
              </ModalFooter>
            </>
          </ModalContent>
        </Modal>
      )}

      {selectedShop && (
        <Modal
          onClose={confirmationOnClose}
          isOpen={confirmationIsOpen}
          isCentered
        >
          <ModalOverlay />
          <ModalContent borderRadius={"10px"} maxW={["90%", "md", "md"]}>
            <>
              <ModalHeader>
                {t("CancelSubscriptionFor", {
                  shopName: selectedShop.name || "",
                })}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <ScaleFade initialScale={0.9} in={confirmationIsOpen}>
                  <VStack spacing={4}>
                    <Text>
                      {t("YourSubscriptionWillBeCancelledAt", {
                        date: formatFirebaseDate(
                          selectedShop.subscription.current_period_end
                        ),
                      })}
                    </Text>
                    <Text size={"sm"} color={"red"}>
                      {t("CancelSubscriptionSpecification", {
                        date: formatFirebaseDate(
                          selectedShop.subscription.current_period_end
                        ),
                      })}
                    </Text>
                  </VStack>
                </ScaleFade>
              </ModalBody>
              <ModalFooter>
                <HStack>
                  <Button
                    size={"sm"}
                    colorScheme={"red"}
                    onClick={async () => {
                      confirmationOnClose();
                      await fetch(
                        `${process.env.REACT_APP_FUNCTIONS_URI}/cancel`,
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `${await auth.currentUser?.getIdToken()}`,
                          },
                          body: JSON.stringify({
                            priceId: selectedShop.subscription.priceId,
                            shopId: selectedShop.id,
                            auth:
                              process.env.NODE_ENV === "development"
                                ? auth.currentUser?.uid
                                : null,
                            shopOwner: selectedShop.owner,
                            subscriptionId: selectedShop.subscription.id,
                          }),
                        }
                      )
                        .then((data) => {
                          //   toast({
                          //     title: t("SubscriptionCancelled"),
                          //     status: "success",
                          //     duration: 6000,
                          //     isClosable: true,
                          //   });
                          iOnClose();
                          setTimeout(() => {
                            refetch();
                            toast({
                              title: t("SubscriptionCancelled"),
                              description: t(
                                "SubscriptionCancelledDescription"
                              ),
                              status: "success",
                              duration: 6000,
                              isClosable: true,
                            });
                          }, 2000);
                        })
                        .catch((error) => {
                          toast({
                            title: t("Oops"),
                            description: t("SomethingWentWrong"),
                            status: "error",
                            duration: 6000,
                            isClosable: true,
                          });
                        });
                    }}
                  >
                    {t("CancelSubscription")}
                  </Button>
                  <Button
                    colorScheme="gray"
                    variant={"outline"}
                    size={"sm"}
                    onClick={confirmationOnClose}
                  >
                    {t("Close")}
                  </Button>
                </HStack>
              </ModalFooter>
            </>
          </ModalContent>
        </Modal>
      )}

      {selectedShop && (
        <Modal onClose={iOnClose} size={"sm"} isOpen={iIsOpen} isCentered>
          <ModalOverlay />
          <ModalContent borderRadius={"10px"} maxW={["90%", "md", "md"]}>
            <>
              <ModalHeader>{selectedShop.name || ""}</ModalHeader>
              <ModalCloseButton />
              <ModalBody padding={"20px"}>
                <ScaleFade initialScale={0.9} in={iIsOpen}>
                  <VStack>
                    <Link to={`/dashboard/shop/${selectedShop.url}/settings`}>
                      <Button w="100%" variant={"ghost"} color={_mainColor()}>
                        {t("Edit")}
                      </Button>
                    </Link>
                    <Link
                      to={`/dashboard/shop/${selectedShop.url}/appointments`}
                    >
                      <Button w="100%" variant={"ghost"} color={_mainColor()}>
                        {t("ViewAppointments")}
                      </Button>
                    </Link>
                    <Link to={`/shop/${selectedShop.url}`} target="_blank">
                      <Button w="100%" variant={"ghost"} color={_mainColor()}>
                        <HStack>
                          <LuExternalLink /> <Text>{t("ViewCalendar")}</Text>
                        </HStack>
                      </Button>
                    </Link>
                    <Button
                      w="100%"
                      variant={"ghost"}
                      color={_mainColor()}
                      onClick={() => {
                        onOpen();
                      }}
                    >
                      {t("GetQrCode")}
                    </Button>
                    {selectedShop.subscription &&
                      !selectedShop.subscription.cancel_at_period_end &&
                      (selectedShop.subscription.status == "active" ||
                        selectedShop.subscription.status == "trialing") && (
                        <>
                          <Button
                            w="100%"
                            variant={"ghost"}
                            color={"red"}
                            onClick={confirmationOnOpen}
                          >
                            <HStack>
                              <Text colorScheme="red">
                                {t("CancelSubscription")}
                              </Text>
                            </HStack>
                          </Button>
                        </>
                      )}
                  </VStack>
                </ScaleFade>
              </ModalBody>
            </>
          </ModalContent>
        </Modal>
      )}
      <Stack direction={["column", "row"]} spacing={4} mb={4}>
        <Heading as="h1" size="xl" width={"90%"} fontWeight={100} mb={4}>
          {t("Dashboard")}
        </Heading>
        <Input
          variant={"outline"}
          placeholder={t("SearchShopByName")}
          onChange={(e: any) => setSearch(e.target.value)}
        />
      </Stack>
      <Link to="/dashboard/create-shop">
        <Button variant={"main"} size={"sm"} leftIcon={<FaPlusCircle />} mb={3}>
          {t("AddShop")}
        </Button>
      </Link>

      <Card p="16px" overflowX={{ sm: "scroll", xl: "hidden" }}>
        {/* <CardHeader p="12px 0px 28px 0px">
          <Flex direction="column">
            <Text fontSize="lg" fontWeight="bold" pb=".5rem">
              TEST
            </Text>
          </Flex>
        </CardHeader> */}
        <CardBody>
          <Table variant="simple">
            <Thead>
              <Tr my=".8rem" ps="0px">
                <Th color="gray.400">{t("Name")}</Th>
                <Th color="gray.400">{t("SubscriptionStatus")}</Th>
                <Th color="gray.400" isNumeric></Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.length === 0 ? (
                <Tr>
                  <Td colSpan={3} textAlign={"center"}>
                    <Text>{t("NoShopsFound")}</Text>
                  </Td>
                </Tr>
              ) : (
                data &&
                data.map((item: any) => (
                  <Tr key={item.id}>
                    <Td>
                      <HStack spacing={4}>
                        <Avatar size="xs" src={item.logoUrl}>
                          <AvatarBadge
                            title={item.active ? "Active" : "Inactive"}
                            boxSize="1.25em"
                            bg={getActiveColor(item.active)}
                          />
                        </Avatar>
                        <VStack spacing={0}>
                          <Text
                            alignSelf={"start"}
                            fontSize={"md"}
                            fontWeight="bold"
                            minWidth="100%"
                          >
                            {item.name}
                          </Text>
                          <HStack spacing={2}>
                            <Link to={`/shop/${item.url}`}>
                              <Text
                                color={_mainColor()}
                                fontSize={"sm"}
                                fontWeight={"500"}
                              >{`/${item.url}`}</Text>
                            </Link>
                            <Button
                              variant="ghost"
                              colorScheme="blue"
                              size="md"
                              margin={0}
                              p={0}
                              h={"auto"}
                              w={"auto"}
                              minW={"auto"}
                              _hover={{
                                bg: "transparent",
                              }}
                              title="Copy URL"
                              onClick={() => {
                                copyUrl(item.url);
                              }}
                            >
                              <MdOutlineCopyAll />
                            </Button>
                          </HStack>
                        </VStack>
                      </HStack>
                    </Td>
                    <Td>{renderSubscriptionStatus(item)}</Td>
                    <Td isNumeric>
                      <Box display={["inline-block", "inline-block"]}>
                        <Button
                          px={4}
                          py={2}
                          transition="all 0.2s"
                          fontWeight={"600"}
                          color={_mainColor()}
                          variant={"ghost"}
                          onClick={() => {
                            setSelectedShop(item);
                            iOnOpen();
                          }}
                        >
                          {t("More")} <ChevronDownIcon />
                        </Button>
                      </Box>
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </>
    // </Container>
  );
};
