export function TermsOfServiceEn() {
  return (
    <div>
      <h1>Terms of Service</h1>
      <h2>1. Introduction</h2>
      Welcome to Apppoint.gr (“we,” “our,” or “us”). By using our website,
      Apppoint.gr, you agree to the following Terms of Service ("Terms"). Our
      platform allows users to create and manage shops and share booking links
      with their customers. Customers can book appointments and share personal
      details like name, phone number, and email. These Terms govern your use of
      the service. By registering an account, you agree to be bound by these
      Terms. If you do not agree, you must stop using the service immediately.
      <h2>2. Account Creation and Responsibilities</h2>
      To use our services, you must create an account by providing accurate and
      up-to-date information, including your name, email address, and other
      required details. You are responsible for maintaining the confidentiality
      of your account and password. You agree to notify us immediately of any
      unauthorized use of your account. You must be at least 18 years old to
      create an account and use our services.
      <h2>3. User Conduct and Prohibited Activities</h2>
      You agree to use the service only for lawful purposes and in accordance
      with these Terms. You agree not to: Post, upload, or share any content
      that is illegal, harmful, or violates the rights of others. Engage in any
      fraudulent, deceptive, or harmful activities. Interfere with the operation
      of the website or the services provided. Attempt to gain unauthorized
      access to other user accounts or our systems. We reserve the right to
      suspend or terminate your account if you violate any of these Terms.
      <h2>4. User Content and Intellectual Property</h2>
      By creating a shop and uploading content (such as shop descriptions,
      images, etc.), you grant us a non-exclusive, royalty-free license to use,
      display, and distribute that content on our platform. You retain ownership
      of all your content. You agree not to infringe the intellectual property
      rights of others when uploading content. You acknowledge that we own all
      intellectual property rights to the website's design, layout, branding,
      and software.
      <h2>5. Customer Data and Bookings</h2>
      When a customer books an appointment with a shop, their personal
      information (such as name, email, and phone number) will be shared with
      the shop owner. As a shop owner, you are responsible for safeguarding and
      processing customer data in compliance with GDPR. We act as a data
      processor for facilitating the bookings, but shop owners are considered
      the data controllers for the customer information they receive.
      <h2>6. Limitation of Liability</h2>
      We provide the platform to facilitate bookings, but we are not responsible
      for the quality, availability, or fulfillment of services offered by
      shops. We do not guarantee that our website will always be available,
      error-free, or secure, and we are not responsible for any data loss,
      revenue loss, or damages resulting from the use of our service.
      <h2>7. Payment and Fees</h2>
      If you opt for premium services or paid subscriptions, you agree to pay
      the applicable fees. We use third-party payment providers, and their terms
      and conditions apply to any payments made on the platform. Refunds, if
      applicable, will be processed in accordance with their refund policy.
      <h2>8. Termination</h2>
      You may terminate your account at any time by contacting us. We may
      terminate or suspend your account if you breach these Terms or if we need
      to discontinue the service. Upon termination, your access to the website
      and all related data will be revoked.
      <h2>9. Governing Law</h2>
      These Terms are governed by and construed in accordance with the laws of
      Greece. Any disputes arising from or related to these Terms shall be
      subject to the exclusive jurisdiction of the courts of Greece.
      <h2>10. Changes to the Terms</h2>
      We may update these Terms from time to time. Any changes will be posted on
      this page. By continuing to use the service after any changes are posted,
      you agree to be bound by the revised Terms.
    </div>
  );
}

export function PrivacyPolicEn() {
  return (
    <div>
      <h2>1. Introduction</h2>
      Apppoint.gr (“we,” “our,” or “us”) is committed to protecting the privacy
      of your personal information. This Privacy Policy explains how we collect,
      use, store, and share your personal data in compliance with the General
      Data Protection Regulation (GDPR). By using our website, Apppoint.gr, you
      agree to this Privacy Policy. If you do not agree, you should stop using
      the service immediately.
      <h2>2. Data Controller Information</h2>
      Apppoint.gr support@apppoint.gr We are the data controller for the
      personal data collected through our website.
      <h2>3. Types of Personal Data Collected</h2>
      We collect the following types of personal data: User Data: When you
      create an account, we collect your name, email address, and password. Shop
      Information: If you create a shop, we collect details related to your
      shop, including business name and services offered. Customer Data: When
      customers book appointments through the platform, we collect their name,
      email, phone number, and, if necessary, address. Payment Information: If
      you use premium services, we may collect payment details, though these are
      processed by third-party payment providers.
      <h2>4. Purpose of Data Collection</h2>
      We collect your personal data for the following purposes: To provide and
      manage the services on our website. To communicate with you regarding your
      account, bookings, or other inquiries. To process payments for premium
      services (if applicable). To comply with legal obligations and resolve
      disputes.
      <h2>5. Legal Basis for Processing</h2>
      We process personal data under the following legal bases: Consent: You
      provide explicit consent when you share your personal data during account
      registration and booking. Contractual Obligation: Processing is necessary
      to fulfill the contract, such as facilitating bookings. Legitimate
      Interest: We may process data for fraud prevention or improving the
      platform.
      <h2>6. How Data is Shared</h2>
      We do not sell or rent your personal data. However, we may share data in
      the following situations: With Shop Owners: When a customer books an
      appointment, their data is shared with the relevant shop owner. With Third
      Parties: We may share data with trusted service providers (e.g., payment
      processors) who assist in operating our platform. Legal Compliance: We may
      disclose your data to authorities if required by law.
      <h2>7. Data Retention</h2>
      We retain your personal data for as long as necessary to provide our
      services or comply with legal obligations. After that period, data will be
      deleted or anonymized.
      <h2>8. Your Rights Under GDPR</h2>
      As a user or customer, you have the following rights regarding your
      personal data: Right to Access: You can request a copy of the personal
      data we hold about you. Right to Rectification: You can request
      corrections to your personal data if it is inaccurate. Right to Deletion:
      You can request that we delete your personal data, subject to certain
      legal obligations. Right to Data Portability: You can request your
      personal data in a commonly used format to transfer to another service.
      Right to Object: You may object to certain types of data processing,
      including marketing. Right to Withdraw Consent: You can withdraw your
      consent to data processing at any time. To exercise any of these rights,
      please contact us at support@apppoint.gr.
      <h2>9. Data Security</h2>
      We implement security measures to protect your personal data, including
      encryption and access control. However, no method of transmission over the
      internet is completely secure, and we cannot guarantee absolute security.
      <h2>10. Cookies and Tracking Technologies</h2>
      We use cookies to improve the functionality of our website and enhance
      user experience. You can manage your cookie preferences through your
      browser settings. For more information, please refer to our Cookie Policy.
      <h2>11. Data Transfers Outside the EU</h2>
      If we transfer personal data outside the European Economic Area (EEA), we
      will ensure appropriate safeguards are in place, such as Standard
      Contractual Clauses, to protect your data in compliance with GDPR.
      <h2>12. Changes to the Privacy Policy</h2>
      We may update this Privacy Policy from time to time. Any changes will be
      posted on this page, and you will be notified of significant changes. Your
      continued use of the website after any changes indicates your acceptance
      of the updated policy.
      <h2>13. Contact Information</h2>
      If you have any questions or concerns about this Privacy Policy or how we
      handle your personal data, please contact us at support@apppoint.gr.
    </div>
  );
}

export function TermsOfServiceEl() {
  return (
    <div>
      <h1>Όροι Χρήσης</h1>
      <h2>1. Εισαγωγή</h2>
      Καλώς ήρθατε στο Apppoint.gr (“εμείς,” “δικό μας,” ή “μας”).
      Χρησιμοποιώντας την ιστοσελίδα μας, Apppoint.gr, συμφωνείτε με τους
      ακόλουθους Όρους Χρήσης ("Όροι"). Η πλατφόρμα μας επιτρέπει στους χρήστες
      να δημιουργούν και να διαχειρίζονται καταστήματα και να μοιράζονται
      συνδέσμους για κρατήσεις με τους πελάτες τους. Οι πελάτες μπορούν να
      κλείνουν ραντεβού και να παρέχουν προσωπικά στοιχεία, όπως όνομα, αριθμό
      τηλεφώνου και email. Αυτοί οι Όροι διέπουν τη χρήση της υπηρεσίας μας.
      Δημιουργώντας έναν λογαριασμό, συμφωνείτε να δεσμεύεστε από αυτούς τους
      Όρους. Εάν δεν συμφωνείτε, θα πρέπει να διακόψετε αμέσως τη χρήση της
      υπηρεσίας.
      <h2>2. Δημιουργία Λογαριασμού και Ευθύνες</h2>
      Για να χρησιμοποιήσετε τις υπηρεσίες μας, θα πρέπει να δημιουργήσετε έναν
      λογαριασμό παρέχοντας ακριβείς και ενημερωμένες πληροφορίες, όπως όνομα,
      διεύθυνση email και κωδικό πρόσβασης. Είστε υπεύθυνοι για τη διατήρηση της
      εμπιστευτικότητας του λογαριασμού σας και του κωδικού σας. Συμφωνείτε να
      μας ενημερώσετε άμεσα για οποιαδήποτε μη εξουσιοδοτημένη χρήση του
      λογαριασμού σας. Πρέπει να είστε τουλάχιστον 18 ετών για να δημιουργήσετε
      λογαριασμό και να χρησιμοποιήσετε τις υπηρεσίες μας.
      <h2>3. Συμπεριφορά Χρήστη και Απαγορευμένες Δραστηριότητες</h2>
      Συμφωνείτε να χρησιμοποιείτε την υπηρεσία μόνο για νόμιμους σκοπούς και
      σύμφωνα με αυτούς τους Όρους. Συμφωνείτε να μην: Δημοσιεύετε, ανεβάζετε ή
      μοιράζεστε περιεχόμενο που είναι παράνομο, βλαβερό ή παραβιάζει τα
      δικαιώματα άλλων. Εμπλέκεστε σε απατηλές, παραπλανητικές ή βλαβερές
      δραστηριότητες. Παρεμβαίνετε στη λειτουργία της ιστοσελίδας ή των
      παρεχόμενων υπηρεσιών. Προσπαθείτε να αποκτήσετε μη εξουσιοδοτημένη
      πρόσβαση σε άλλους λογαριασμούς χρηστών ή στα συστήματά μας. Διατηρούμε το
      δικαίωμα να αναστείλουμε ή να τερματίσουμε τον λογαριασμό σας εάν
      παραβιάσετε οποιονδήποτε από αυτούς τους Όρους.
      <h2>4. Περιεχόμενο Χρηστών και Πνευματική Ιδιοκτησία</h2>
      Δημιουργώντας ένα κατάστημα και ανεβάζοντας περιεχόμενο (όπως περιγραφές
      καταστημάτων, εικόνες, κλπ.), μας παραχωρείτε μια μη αποκλειστική, δωρεάν
      άδεια χρήσης, προβολής και διανομής αυτού του περιεχομένου στην πλατφόρμα
      μας. Διατηρείτε την κυριότητα όλου του περιεχομένου σας. Συμφωνείτε να μην
      παραβιάζετε τα δικαιώματα πνευματικής ιδιοκτησίας άλλων όταν ανεβάζετε
      περιεχόμενο. Αναγνωρίζετε ότι εμείς κατέχουμε όλα τα δικαιώματα
      πνευματικής ιδιοκτησίας στον σχεδιασμό, τη διάταξη, το branding και το
      λογισμικό της ιστοσελίδας.
      <h2>5. Δεδομένα Πελατών και Κρατήσεις</h2>
      Όταν ένας πελάτης κλείνει ραντεβού με ένα κατάστημα, τα προσωπικά του
      στοιχεία (όπως όνομα, email και αριθμός τηλεφώνου) κοινοποιούνται στον
      ιδιοκτήτη του καταστήματος. Ως ιδιοκτήτης καταστήματος, είστε υπεύθυνοι
      για την προστασία και επεξεργασία των δεδομένων των πελατών σύμφωνα με τον
      GDPR. Εμείς λειτουργούμε ως επεξεργαστής δεδομένων για τη διευκόλυνση των
      κρατήσεων, αλλά οι ιδιοκτήτες των καταστημάτων θεωρούνται οι υπεύθυνοι
      επεξεργασίας δεδομένων για τις πληροφορίες των πελατών που λαμβάνουν.
      <h2>6. Περιορισμός Ευθύνης</h2>
      Παρέχουμε την πλατφόρμα για τη διευκόλυνση των κρατήσεων, αλλά δεν φέρουμε
      ευθύνη για την ποιότητα, τη διαθεσιμότητα ή την εκπλήρωση των υπηρεσιών
      που προσφέρουν τα καταστήματα. Δεν εγγυόμαστε ότι η ιστοσελίδα μας θα
      είναι πάντα διαθέσιμη, χωρίς σφάλματα ή ασφαλής, και δεν φέρουμε ευθύνη
      για οποιαδήποτε απώλεια δεδομένων, απώλεια εσόδων ή ζημιές που προκύπτουν
      από τη χρήση της υπηρεσίας μας.
      <h2>7. Πληρωμές και Χρεώσεις</h2>
      Εάν επιλέξετε υπηρεσίες premium ή συνδρομές, συμφωνείτε να καταβάλετε τις
      αντίστοιχες χρεώσεις. Χρησιμοποιούμε τρίτους παρόχους πληρωμών, και οι
      όροι και οι προϋποθέσεις τους ισχύουν για οποιεσδήποτε πληρωμές γίνονται
      στην πλατφόρμα. Οι επιστροφές χρημάτων, εάν είναι εφαρμόσιμες, θα
      διεκπεραιώνονται σύμφωνα με την πολιτική επιστροφών τους.
      <h2>8. Τερματισμός</h2>
      Μπορείτε να τερματίσετε τον λογαριασμό σας ανά πάσα στιγμή επικοινωνώντας
      μαζί μας. Μπορούμε να τερματίσουμε ή να αναστείλουμε τον λογαριασμό σας
      εάν παραβιάσετε αυτούς τους Όρους ή εάν χρειαστεί να διακόψουμε την
      υπηρεσία. Μετά τον τερματισμό, η πρόσβασή σας στην ιστοσελίδα και όλα τα
      σχετικά δεδομένα θα ανακληθούν.
      <h2>9. Ισχύον Δίκαιο</h2>
      Αυτοί οι Όροι διέπονται από και ερμηνεύονται σύμφωνα με την ελληνική
      νομοθεσία. Οποιεσδήποτε διαφορές που προκύπτουν από ή σχετίζονται με
      αυτούς τους Όρους θα υπόκεινται στην αποκλειστική δικαιοδοσία των
      δικαστηρίων της Ελλάδας.
      <h2>10. Αλλαγές στους Όρους</h2>
      Ενδέχεται να ενημερώνουμε αυτούς τους Όρους κατά καιρούς. Οποιεσδήποτε
      αλλαγές θα αναρτώνται σε αυτήν τη σελίδα. Με τη συνέχιση της χρήσης της
      υπηρεσίας μετά από οποιεσδήποτε αλλαγές, συμφωνείτε να δεσμεύεστε από τους
      τροποποιημένους Όρους.
    </div>
  );
}

export function PrivacyPolicEl() {
  return (
    <div>
      <h1>Πολιτική Απορρήτου</h1>
      <h2>1. Εισαγωγή</h2>
      Το Apppoint.gr (“εμείς,” “δικό μας,” ή “μας”) δεσμεύεται να προστατεύει τα
      προσωπικά σας δεδομένα. Αυτή η Πολιτική Απορρήτου εξηγεί πώς συλλέγουμε,
      χρησιμοποιούμε, αποθηκεύουμε και κοινοποιούμε τα προσωπικά σας δεδομένα,
      σύμφωνα με τον Γενικό Κανονισμό Προστασίας Δεδομένων (GDPR).
      Χρησιμοποιώντας την ιστοσελίδα μας, Apppoint.gr, συμφωνείτε με αυτήν την
      Πολιτική Απορρήτου. Εάν δεν συμφωνείτε, θα πρέπει να σταματήσετε άμεσα τη
      χρήση της υπηρεσίας.
      <h2>2. Πληροφορίες Υπεύθυνου Επεξεργασίας Δεδομένων</h2>
      Apppoint.gr support@apppoint.gr Είμαστε ο υπεύθυνος επεξεργασίας δεδομένων
      για τα προσωπικά δεδομένα που συλλέγονται μέσω της ιστοσελίδας μας.
      <h2>3. Τύποι Προσωπικών Δεδομένων που Συλλέγονται (συνέχεια)</h2>
      Δεδομένα Χρήστη: Όταν δημιουργείτε λογαριασμό, συλλέγουμε το όνομα, τη
      διεύθυνση email και τον κωδικό πρόσβασής σας. Πληροφορίες Καταστήματος:
      Εάν δημιουργήσετε ένα κατάστημα, συλλέγουμε πληροφορίες που σχετίζονται με
      το κατάστημά σας, όπως το όνομα της επιχείρησης και τις υπηρεσίες που
      προσφέρετε. Δεδομένα Πελατών: Όταν οι πελάτες κλείνουν ραντεβού μέσω της
      πλατφόρμας, συλλέγουμε το όνομα, το email, τον αριθμό τηλεφώνου και, αν
      χρειαστεί, τη διεύθυνση τους. Πληροφορίες Πληρωμών: Εάν χρησιμοποιήσετε
      υπηρεσίες premium, μπορεί να συλλέξουμε δεδομένα πληρωμών, τα οποία
      διεκπεραιώνονται από τρίτους παρόχους πληρωμών.
      <h2>4. Σκοπός Συλλογής Δεδομένων</h2>
      Συλλέγουμε τα προσωπικά σας δεδομένα για τους ακόλουθους σκοπούς: Για να
      παρέχουμε και να διαχειριζόμαστε τις υπηρεσίες της ιστοσελίδας μας. Για να
      επικοινωνούμε μαζί σας σχετικά με τον λογαριασμό σας, τις κρατήσεις ή
      άλλες ερωτήσεις. Για να επεξεργαζόμαστε πληρωμές για υπηρεσίες premium
      (εάν ισχύει). Για να συμμορφωθούμε με νομικές υποχρεώσεις και να επιλύουμε
      διαφορές.
      <h2>5. Νομική Βάση για την Επεξεργασία</h2>
      Επεξεργαζόμαστε τα προσωπικά δεδομένα σας με βάση τις εξής νομικές βάσεις:
      Συγκατάθεση: Παρέχετε τη ρητή συγκατάθεσή σας όταν μοιράζεστε τα προσωπικά
      σας δεδομένα κατά την εγγραφή ή την κράτηση. Συμβατική Υποχρέωση: Η
      επεξεργασία είναι απαραίτητη για την εκτέλεση της σύμβασης, όπως η
      διευκόλυνση των κρατήσεων. Έννομο Συμφέρον: Μπορεί να επεξεργαζόμαστε
      δεδομένα για την πρόληψη απάτης ή για τη βελτίωση της πλατφόρμας.
      <h2>6. Πώς Κοινοποιούνται τα Δεδομένα</h2>
      Δεν πωλούμε ούτε ενοικιάζουμε τα προσωπικά σας δεδομένα. Ωστόσο, ενδέχεται
      να κοινοποιήσουμε δεδομένα στις εξής περιπτώσεις: Με Ιδιοκτήτες
      Καταστημάτων: Όταν ένας πελάτης κλείνει ραντεβού, τα δεδομένα του
      κοινοποιούνται στον αντίστοιχο ιδιοκτήτη καταστήματος. Με Τρίτους: Μπορεί
      να κοινοποιήσουμε δεδομένα σε αξιόπιστους παρόχους υπηρεσιών (π.χ.
      διαχειριστές πληρωμών) που μας βοηθούν στη λειτουργία της πλατφόρμας.
      Νομική Συμμόρφωση: Μπορεί να κοινοποιήσουμε τα δεδομένα σας σε αρχές, εάν
      απαιτείται από το νόμο.
      <h2>7. Διατήρηση Δεδομένων</h2>
      Διατηρούμε τα προσωπικά σας δεδομένα για όσο διάστημα είναι απαραίτητο για
      την παροχή των υπηρεσιών μας ή για τη συμμόρφωση με νομικές υποχρεώσεις.
      Μετά από αυτή την περίοδο, τα δεδομένα διαγράφονται ή ανωνυμοποιούνται.
      <h2>8. Τα Δικαιώματά σας σύμφωνα με τον GDPR</h2>
      Ως χρήστης ή πελάτης, έχετε τα εξής δικαιώματα σχετικά με τα προσωπικά σας
      δεδομένα: Δικαίωμα Πρόσβασης: Μπορείτε να ζητήσετε ένα αντίγραφο των
      προσωπικών δεδομένων που διατηρούμε για εσάς. Δικαίωμα Διόρθωσης: Μπορείτε
      να ζητήσετε τη διόρθωση των προσωπικών σας δεδομένων αν είναι ανακριβή.
      Δικαίωμα Διαγραφής: Μπορείτε να ζητήσετε τη διαγραφή των προσωπικών σας
      δεδομένων, υπό ορισμένες νομικές προϋποθέσεις. Δικαίωμα Φορητότητας
      Δεδομένων: Μπορείτε να ζητήσετε τα προσωπικά σας δεδομένα σε μορφή που
      χρησιμοποιείται ευρέως για να τα μεταφέρετε σε άλλη υπηρεσία. Δικαίωμα
      Εναντίωσης: Μπορείτε να εναντιωθείτε σε ορισμένες μορφές επεξεργασίας
      δεδομένων, συμπεριλαμβανομένων των προωθητικών ενεργειών. Δικαίωμα
      Ανάκλησης Συγκατάθεσης: Μπορείτε να ανακαλέσετε τη συγκατάθεσή σας για την
      επεξεργασία δεδομένων οποιαδήποτε στιγμή. Για να ασκήσετε οποιοδήποτε από
      αυτά τα δικαιώματα, παρακαλούμε επικοινωνήστε μαζί μας στη διεύθυνση
      support@apppoint.gr.
      <h2>9. Ασφάλεια Δεδομένων</h2>
      Εφαρμόζουμε μέτρα ασφαλείας για την προστασία των προσωπικών σας
      δεδομένων, όπως κρυπτογράφηση και έλεγχο πρόσβασης. Ωστόσο, καμία μέθοδος
      μετάδοσης μέσω διαδικτύου δεν είναι απολύτως ασφαλής και δεν μπορούμε να
      εγγυηθούμε απόλυτη ασφάλεια.
      <h2>10. Cookies και Τεχνολογίες Παρακολούθησης</h2>
      Χρησιμοποιούμε cookies για να βελτιώσουμε τη λειτουργικότητα της
      ιστοσελίδας μας και να ενισχύσουμε την εμπειρία χρήστη. Μπορείτε να
      διαχειριστείτε τις προτιμήσεις σας για τα cookies μέσω των ρυθμίσεων του
      προγράμματος περιήγησής σας. Για περισσότερες πληροφορίες, παρακαλούμε
      ανατρέξτε στην Πολιτική Cookies μας.
      <h2>11. Μεταφορές Δεδομένων Εκτός ΕΕ</h2>
      Εάν μεταφέρουμε προσωπικά δεδομένα εκτός του Ευρωπαϊκού Οικονομικού Χώρου
      (ΕΟΧ), θα διασφαλίσουμε ότι θα υπάρχουν τα κατάλληλα μέτρα προστασίας,
      όπως οι Τυποποιημένες Συμβατικές Ρήτρες, για την προστασία των δεδομένων
      σας σύμφωνα με τον GDPR.
      <h2>12. Αλλαγές στην Πολιτική Απορρήτου</h2>
      Μπορεί να ενημερώνουμε αυτήν την Πολιτική Απορρήτου κατά καιρούς.
      Οποιεσδήποτε αλλαγές θα αναρτώνται σε αυτήν τη σελίδα. Η συνέχιση της
      χρήσης της ιστοσελίδας μετά από οποιεσδήποτε αλλαγές υποδηλώνει την
      αποδοχή της ενημερωμένης πολιτικής.
      <h2>13. Στοιχεία Επικοινωνίας</h2>
      Εάν έχετε οποιεσδήποτε ερωτήσεις ή ανησυχίες σχετικά με αυτήν την Πολιτική
      Απορρήτου ή με τον τρόπο που χειριζόμαστε τα προσωπικά σας δεδομένα,
      παρακαλούμε επικοινωνήστε μαζί μας στη διεύθυνση support@apppoint.gr.
    </div>
  );
}
