import { Link } from "react-router-dom";
import { formatFirebaseDate } from "../classes/Utils";

export function NewAppointmentTemplate({
  user,
  date,
  shopName,
  shopUrl,
  description,
}: any) {
  return `<body style="margin: 0; padding: 0; font-family: Arial, sans-serif; background-color: #f4f4f4;">
        <div style="width: 100%; max-width: 1280px; margin: 20px auto; text-align: center; background-color: #fff; padding: 20px;">
            <div style="width: 100%;margin: 0 auto;">
                <img src="https://firebasestorage.googleapis.com/v0/b/date-helper-166f3.appspot.com/o/email_images%2Fnew.png?alt=media" alt="new" style="width: 30%;">
            </div>
            <div style="text-align: center; padding: 40px 24px;">
                <h2 style="font-size: 24px; margin: 24px 0;">New appointment request!</h2>
                <p style="color: #718096;">You have a new appointment request from ${
                  user.name
                } for your shop: ${shopName}.</p>
                <p style="color: #718096;">Date: ${date}</p>
                <p style="color: #718096;">Contact: ${user.phone}, ${
    user.email
  }</p>
  <p style="color: #718096;">Description: ${description}</p>
                <a href="${
                  window.location.origin
                }/dashboard/shop/${shopUrl}/appointments?name=${user.name.replace(
    " ",
    "%20"
  )}" style="text-decoration: none;">
                    <button style="background-color: #3182ce; color: white; padding: 12px 24px; border: none; border-radius: 4px; cursor: pointer; margin-top: 40px;">View on Appointments list</button>
                </a>
                <a href="${
                  window.location.origin
                }/dashboard/shop/${shopUrl}/appointments" style="text-decoration: none;">
                    <button style="background-color: #3182ce; color: white; padding: 12px 24px; border: none; border-radius: 4px; cursor: pointer; margin-top: 40px;">View all Appointments</button>
                </a>
            </div>
        </div>
    </body>`;
}

export function ConfirmAppointmentTemplate({ shopName, shopPhone, date }: any) {
  return `<body style="margin: 0; padding: 0; font-family: Arial, sans-serif; background-color: #f4f4f4;">
    <div style="width: 100%; max-width: 1280px; margin: 20px auto; text-align: center; background-color: #fff; padding: 20px;">
        <div style="width: 100%;margin: 0 auto;">
              <img src="https://firebasestorage.googleapis.com/v0/b/date-helper-166f3.appspot.com/o/email_images%2Fsuccess.png?alt=media" alt="success" style="width: 30%;">
          </div>
        <div style="text-align: center; padding: 40px 24px;">
            <h2 style="font-size: 24px; margin: 24px 0;">Your appointment has been confirmed!</h2>
            <p style="color: #718096;">Your appointment has been successfully confirmed by the shop owner!</p>
            <p style="color: #718096;">Shop Name: ${shopName}</p>
            <p style="color: #718096;">Date: ${date}</p>
            <p style="color: #718096;">Contact: ${shopPhone}</p>
            <a href="${window.location.origin}/user/appointments" style="text-decoration: none;">
                <button style="background-color: #3182ce; color: white; padding: 12px 24px; border: none; border-radius: 4px; cursor: pointer; margin-top: 40px;">View your appointments</button>
            </a>
        </div>
    </div>
</body>`;
}

export function DeclineAppointmentTemplate({ shopName, shopPhone, date }: any) {
  return `<body style="margin: 0; padding: 0; font-family: Arial, sans-serif; background-color: #f4f4f4;">
      <div style="width: 100%; max-width: 1280px; margin: 20px auto; text-align: center; background-color: #fff; padding: 20px;">
          <div style="width: 100%;margin: 0 auto;">
              <img src="https://firebasestorage.googleapis.com/v0/b/date-helper-166f3.appspot.com/o/email_images%2Fdecline.png?alt=media" alt="decline" style="width: 30%;">
          </div>
          <div style="text-align: center; padding: 40px 24px;">
              <h2 style="font-size: 24px; margin: 24px 0;">Unfortunately, your appointment has been declined.</h2>
              <p style="color: #718096;">Your appointment has been declined by the shop owner. Contact them for more information.</p>
              <p style="color: #718096;">Shop Name: ${shopName}</p>
              <p style="color: #718096;">Date: ${date}</p>
              <p style="color: #718096;">Contact: ${shopPhone}</p>
              <a href="${window.location.origin}/user/appointments" style="text-decoration: none;">
                  <button style="background-color: #3182ce; color: white; padding: 12px 24px; border: none; border-radius: 4px; cursor: pointer; margin-top: 40px;">View your appointments</button>
              </a>
          </div>
      </div>
  </body>`;
}

export function CancelAppointmentTemplate({
  user,
  date,
  shopUrl,
  shopName,
}: any) {
  return `<body style="margin: 0; padding: 0; font-family: Arial, sans-serif; background-color: #f4f4f4;">
        <div style="width: 100%; max-width: 1280px; margin: 20px auto; text-align: center; background-color: #fff; padding: 20px;">
            <div style="width: 100%;margin: 0 auto;">
              <img src="https://firebasestorage.googleapis.com/v0/b/date-helper-166f3.appspot.com/o/email_images%2Fcancel.png?alt=media" alt="cancel" style="width: 30%;">
          </div>
            <div style="text-align: center; padding: 40px 24px;">
                <h2 style="font-size: 24px; margin: 24px 0;">User cancelled the appointment.</h2>
                <p style="color: #718096;">Appointment has been cancelled by the user.</p>
                <p style="color: #718096;">Shop Name: ${shopName}</p>
                <p style="color: #718096;">Date: ${date}</p>
                <p style="color: #718096;">Contact: ${user.phone}, ${
    user.email
  }</p>
                <a href="${
                  window.location.origin
                }/dashboard/shop/${shopUrl}/appointments?name=${user.name.replace(
    " ",
    "%20"
  )}" style="text-decoration: none;">
                    <button style="background-color: #3182ce; color: white; padding: 12px 24px; border: none; border-radius: 4px; cursor: pointer; margin-top: 40px;">View on Appointments list</button>
                </a>
                <a href="${
                  window.location.origin
                }/dashboard/shop/${shopUrl}/appointments" style="text-decoration: none;">
                    <button style="background-color: #3182ce; color: white; padding: 12px 24px; border: none; border-radius: 4px; cursor: pointer; margin-top: 40px;">View all Appointments</button>
                </a>
            </div>
        </div>
    </body>`;
}

export const getEmailTemplate = (status: string, appointment: any) => {
  if (status === "accepted") {
    return ConfirmAppointmentTemplate({
      shopName: appointment.shopName,
      shopPhone: appointment.shopPhone,
      date: formatFirebaseDate(appointment.date, true),
    });
  }
  if (status === "declined") {
    return DeclineAppointmentTemplate({
      shopName: appointment.shopName,
      shopPhone: appointment.shopPhone,
      date: formatFirebaseDate(appointment.date, true),
    });
  }
  if (status === "cancelled") {
    return CancelAppointmentTemplate({
      user: {
        name: appointment.name,
        email: appointment.email,
        phone: appointment.phone,
      },
      shopName: appointment.shopName,
      date: formatFirebaseDate(appointment.date, true),
      shopUrl: appointment.shopUrl,
    });
  }

  return "";
};
