import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import { useAuthModal } from "../context/AuthModalContext";
import {
  _mainColor,
  formatFirebaseDate,
  getAppointmentStatusColor,
  getAppointmentStatusIcon,
  getDateAndTime,
} from "../classes/Utils";
import { LoadingAnimation } from "../repeatable-components/RepeatableComponents";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  VStack,
  FormControl,
  FormLabel,
  HStack,
  Divider,
  ModalFooter,
  Button,
  Heading,
  Input,
  Card,
  CardBody,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  Text,
  Stack,
  ScaleFade,
  Box,
  Switch,
  Select,
  useToast,
} from "@chakra-ui/react";
import { ShopPresentation } from "./ShopPresentation";
import { format } from "date-fns";
import {
  useMyShopWatchAppointments,
  useShop,
  useShopWatchAppointments,
} from "../hooks/useShop";
import { changeStatus } from "../classes/Appointment";

export const Appointments = () => {
  const [user] = useAuthState(auth);
  const { url } = useParams();
  //   const { data, isLoading } = useShop(url || "");
  const { data, appointments, isLoading } = useMyShopWatchAppointments(
    url || ""
  );

  //   const shop = new useShop(url || "").state;
  const { isModalOpen, openModal, modalType, closeModal } = useAuthModal();
  const toast = useToast();
  const navigate = useNavigate();
  const [selectedAppointment, setSelectedAppointment] = useState<any>(null);
  const [futureOnly, setFutureOnly] = useState(true);
  const [sortBy, setSortBy] = useState("date");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: iIsOpen,
    onOpen: iOnOpen,
    onClose: iOnClose,
  } = useDisclosure();
  const [search, setSearch] = useState("");
  const { t } = useTranslation();
  useEffect(() => {
    const get = async () => {
      if (!user) {
        openModal("signin");
        return;
      }
    };

    get().catch((error) => {
      console.error(error);
    });
  }, [isLoading]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!data || !data.id) {
      toast({
        title: t("Oops"),
        description: t("ShopNotFound"),
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      navigate("/dashboard");
      return;
    }
  }, [data]);

  const viewAppointment = (appointment: string) => {
    setSelectedAppointment(appointment);
    onOpen();
  };

  return (
    <>
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <>
          {selectedAppointment && (
            <Modal onClose={onClose} isOpen={isOpen} isCentered>
              <ModalOverlay />
              <ModalContent borderRadius={"10px"} maxW={["90%", "md", "md"]}>
                <>
                  <ModalHeader></ModalHeader>
                  <ModalCloseButton />
                  <ModalBody padding={"20px"}>
                    <Text fontWeight={"bold"}>{t("Name")}</Text>
                    <Text>{selectedAppointment?.name}</Text>
                    <Text fontWeight={"bold"} marginTop="10px">
                      {t("Email")}
                    </Text>
                    <Text>{selectedAppointment?.email}</Text>
                    <Text fontWeight={"bold"} marginTop="10px">
                      {t("Phone")}
                    </Text>
                    <Text>{selectedAppointment?.phone}</Text>
                    <Text fontWeight={"bold"} marginTop="10px">
                      {t("Date")}
                    </Text>
                    <Text>{formatFirebaseDate(selectedAppointment?.date)}</Text>
                    <Text fontWeight={"bold"} marginTop="10px">
                      {t("Status")}
                    </Text>
                    <HStack
                      color={getAppointmentStatusColor(
                        selectedAppointment?.status
                      )}
                    >
                      {selectedAppointment.status &&
                        getAppointmentStatusIcon(
                          selectedAppointment?.status
                        ) && (
                          <Text fontWeight={"600"}>
                            {t(
                              selectedAppointment?.status
                                .charAt(0)
                                .toUpperCase() +
                                selectedAppointment?.status.slice(1)
                            )}{" "}
                            {selectedAppointment?.statusChangeAt &&
                              t("StatusChangedAt", {
                                date: formatFirebaseDate(
                                  selectedAppointment?.statusChangeAt
                                ),
                              })}
                          </Text>
                        )}
                    </HStack>

                    <Divider my={"10px"} />
                    <Text fontWeight={"bold"}>{t("BookingDetails")}</Text>
                    <Text>{selectedAppointment?.description}</Text>
                    <Text
                      fontWeight={"bold"}
                      textAlign={"right"}
                      color={"gray.700"}
                    >
                      {t("CreatedAt")}
                    </Text>
                    <Text textAlign={"right"} color={"gray.700"}>
                      {formatFirebaseDate(selectedAppointment?.createdAt)}
                    </Text>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      onClick={onClose}
                      colorScheme="gray"
                      variant={"outline"}
                      size={"sm"}
                      disabled={false}
                    >
                      {t("Close")}
                    </Button>
                  </ModalFooter>
                </>
              </ModalContent>
            </Modal>
          )}
          {selectedAppointment && (
            <Modal onClose={iOnClose} size={"sm"} isOpen={iIsOpen} isCentered>
              <ModalOverlay />
              <ModalContent borderRadius={"10px"} maxW={["90%", "md", "md"]}>
                <>
                  <ModalHeader>
                    {selectedAppointment?.name} (
                    {formatFirebaseDate(selectedAppointment?.date)})
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody padding={"20px"}>
                    <ScaleFade initialScale={0.9} in={iIsOpen}>
                      <VStack>
                        <Button
                          w="100%"
                          onClick={() => {
                            viewAppointment(selectedAppointment);
                          }}
                          variant={"ghost"}
                          color={_mainColor()}
                        >
                          {t("View")}
                        </Button>
                        <Divider />
                        {selectedAppointment.status === "pending" && (
                          <Button
                            onClick={async () => {
                              await changeStatus(
                                selectedAppointment,
                                "accepted"
                              )
                                .then((res) => {
                                  iOnClose();
                                })
                                .catch((error) => {
                                  toast({
                                    title: t("Oops"),
                                    description: t("SomethingWentWrong"),
                                    status: "error",
                                    duration: 4000,
                                    isClosable: true,
                                  });
                                  iOnClose();
                                });
                            }}
                            w="100%"
                            variant={"ghost"}
                            color={"green"}
                          >
                            {t("Accept")}
                          </Button>
                        )}
                        {selectedAppointment.status === "pending" && (
                          <Button
                            onClick={async () => {
                              await changeStatus(
                                selectedAppointment,
                                "declined"
                              )
                                .then((res) => {
                                  iOnClose();
                                })
                                .catch((error) => {
                                  toast({
                                    title: t("Oops"),
                                    description: t("SomethingWentWrong"),
                                    status: "error",
                                    duration: 4000,
                                    isClosable: true,
                                  });
                                  iOnClose();
                                });
                            }}
                            w="100%"
                            variant={"ghost"}
                            color={"red"}
                          >
                            {t("Decline")}
                          </Button>
                        )}
                      </VStack>
                    </ScaleFade>
                  </ModalBody>
                </>
              </ModalContent>
            </Modal>
          )}
          {data && <ShopPresentation shop={data} />}
          <Stack spacing={5} mb={4} direction={["column", "row"]}>
            <Heading as="h1" size="xl" width={"90%"} fontWeight={100} mb={4}>
              {t("Appointments")}
            </Heading>
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="active" mb="0">
                {t("FutureAppointmentsOnly")}
              </FormLabel>
              <Switch
                size="md"
                colorScheme="blue"
                isChecked={futureOnly}
                onChange={(e: any) => {
                  setFutureOnly(e.target.checked);
                }}
              />
            </FormControl>
            <FormControl display="flex" alignItems="center">
              <FormLabel mb="0" w="50%">
                {t("OrderBy")}:
              </FormLabel>
              <Select
                size="sm"
                colorScheme="teal"
                value={sortBy}
                bg={"white"}
                onChange={(e: any) => {
                  setSortBy(e.target.value);
                }}
              >
                <option value={"date"}>{t("Date")}</option>
                <option value={"status"}>{t("Status")}</option>
              </Select>
            </FormControl>
            <Input
              placeholder={t("SearchAppointmentsByName")}
              value={search}
              onChange={(e: any) => {
                setSearch(e.target.value);
              }}
            />
          </Stack>

          <Card p="16px" overflowX={{ sm: "scroll", xl: "hidden" }}>
            <CardBody>
              <Table variant={"simple"}>
                <Thead>
                  <Tr>
                    <Th>{t("Name")}</Th>
                    <Th>{t("Date")}</Th>
                    <Th>{t("Status")}</Th>
                    <Th isNumeric></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {appointments?.filter((a: any) => {
                    return (
                      a.name.toLowerCase().includes(search.toLowerCase()) &&
                      (futureOnly
                        ? new Date(a.date.toDate()).getTime() >=
                          new Date().getTime()
                        : new Date(a.date.toDate()).getTime() <
                          new Date().getTime())
                    );
                  }).length === 0 ? (
                    <Tr>
                      <Td colSpan={4} textAlign={"center"}>
                        <Text>{t("NoAppointmentsFound")}</Text>
                      </Td>
                    </Tr>
                  ) : (
                    appointments
                      ?.filter((a: any) => {
                        return (
                          a.name.toLowerCase().includes(search.toLowerCase()) &&
                          (futureOnly
                            ? new Date(a.date.toDate()).getTime() >=
                              new Date().getTime()
                            : new Date(a.date.toDate()).getTime() <
                              new Date().getTime())
                        );
                      })
                      .sort((a: any, b: any) => {
                        if (sortBy === "date") {
                          return (
                            new Date(b.date).getTime() -
                            new Date(a.date).getTime()
                          );
                        } else {
                          return a.status > b.status ? 1 : -1;
                        }
                      })
                      .map((item: any) => (
                        <Tr key={item.id}>
                          <Td>{item.name}</Td>
                          <Td>{formatFirebaseDate(item.date)}</Td>
                          <Td color={getAppointmentStatusColor(item.status)}>
                            <HStack>
                              {getAppointmentStatusIcon(item.status)}
                              <Text fontWeight={"600"}>
                                {t(
                                  item?.status.charAt(0).toUpperCase() +
                                    item?.status.slice(1)
                                )}
                              </Text>
                            </HStack>
                          </Td>
                          <Td isNumeric>
                            <Box display={["inline-block", "inline-block"]}>
                              <Button
                                px={4}
                                py={2}
                                transition="all 0.2s"
                                fontWeight={"600"}
                                color={_mainColor()}
                                variant={"ghost"}
                                onClick={() => {
                                  setSelectedAppointment(item);
                                  iOnOpen();
                                }}
                              >
                                {t("More")} <ChevronDownIcon />
                              </Button>
                            </Box>
                          </Td>
                        </Tr>
                      ))
                  )}
                </Tbody>
              </Table>
            </CardBody>
          </Card>
        </>
      )}
    </>
  );
};
