import i18n, { t } from "i18next";
import { initReactI18next } from "react-i18next";
import { resources } from "./translations/translations";
import { useNavigate } from "react-router-dom";

i18n.use(initReactI18next).init({
  lng: localStorage.getItem("lang") || "el",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  resources: resources,
});

export default i18n;

export const LANGUAGES = [
  { label: t("Greek"), code: "el" },
  { label: t("English"), code: "en" },
];

// export const ChangeLanguage = (value: any) => {
//   const navigate = useNavigate();
//   const lang_code = value;
//   i18n.changeLanguage(lang_code);

//   navigate(0);
// };
