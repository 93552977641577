import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  Image,
  Avatar,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Input,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useAuthModal } from "../context/AuthModalContext";
import { Link, matchPath, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";

import { useTranslation } from "react-i18next";
import { set, setDefaultOptions } from "date-fns";
import { enUS, el } from "date-fns/locale";
import { IoMdGlobe } from "react-icons/io";
import React from "react";
import { SidebarMobile } from "../theme/components/Sidebar";

export default function TopMenu() {
  const {
    isOpen: rIsOpen,
    onOpen: rOnOpen,
    onClose: rOnClose,
    onToggle: rOnToggle,
  } = useDisclosure();

  const {
    isOpen: lIsOpen,
    onOpen: lOnOpen,
    onClose: lOnClose,
    onToggle: lOnToggle,
  } = useDisclosure();
  const { isModalOpen, openModal, modalType, closeModal } = useAuthModal();

  const avatarRef: any = React.useRef();
  const [user]: any = useAuthState(auth);
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const isHomeRoute = matchPath("/", location.pathname);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // ...
    } else {
      // User is signed out
      // ...
    }
  });

  return (
    <Box>
      <Flex
        bg={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.600", "white")}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={lOnToggle}
            size={"xs"}
            icon={
              lIsOpen ? (
                <CloseIcon w={3} h={3} />
              ) : (
                <HamburgerIcon w={5} h={5} />
              )
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
          <Text
            textAlign={useBreakpointValue({ base: "center", md: "left" })}
            fontFamily={"heading"}
            color={useColorModeValue("gray.800", "white")}
          >
            <Link to="/">
              <Image src="/logo.svg" alt="Logo" width={5} />
            </Link>
          </Text>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={6}
        >
          {isHomeRoute && (
            <IconButton
              size={"md"}
              icon={<IoMdGlobe />}
              aria-label={"Change Language"}
              onClick={() => {
                i18n.changeLanguage(i18n.language === "en" ? "el" : "en");
              }}
            />
          )}
          {!user ? (
            <>
              <Button
                as={"a"}
                fontSize={"sm"}
                fontWeight={400}
                variant={"link"}
                // href={"/sign-in"}
                onClick={() => {
                  openModal("signin");
                }}
                _hover={{
                  cursor: "pointer",
                }}
              >
                {t("SignIn")}
              </Button>

              <Button
                as={"a"}
                display={{ base: "inline-flex", md: "inline-flex" }}
                fontSize={"sm"}
                fontWeight={600}
                color={"white"}
                bg={"pink.400"}
                // href={"/sign-up"}
                onClick={() => openModal("signup")}
                _hover={{
                  bg: "pink.300",
                  cursor: "pointer",
                }}
              >
                {t("SignUp")}
              </Button>
            </>
          ) : (
            <>
              <Avatar
                name={user.displayName}
                src={user.photoURL}
                size={"sm"}
                _hover={{
                  cursor: "pointer",
                }}
                ref={avatarRef}
                onClick={rOnOpen}
              />
              <Drawer
                isOpen={rIsOpen}
                placement="right"
                onClose={rOnClose}
                finalFocusRef={avatarRef}
              >
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerCloseButton />
                  <DrawerHeader>
                    <Text fontWeight={500}>{user.displayName}</Text>
                  </DrawerHeader>

                  <DrawerBody>
                    <Stack>
                      <Link to={"/dashboard"}>
                        <Button
                          onClick={rOnClose}
                          colorScheme="gray"
                          variant={"ghost"}
                          w={"100%"}
                        >
                          {t("Dashboard")}
                        </Button>
                      </Link>
                      <Link to={"/user/appointments"}>
                        <Button
                          onClick={rOnClose}
                          colorScheme="gray"
                          variant={"ghost"}
                          w={"100%"}
                        >
                          {t("MyAppointments")}
                        </Button>
                      </Link>
                      <Link to={"/user/profile"}>
                        <Button
                          onClick={rOnClose}
                          colorScheme="gray"
                          variant={"ghost"}
                          w={"100%"}
                        >
                          {t("Profile")}
                        </Button>
                      </Link>
                      <Button
                        colorScheme="pink"
                        onClick={() => {
                          rOnClose();
                          auth.signOut();
                          // navigate("/");
                        }}
                      >
                        {t("SignOut")}
                      </Button>
                    </Stack>
                  </DrawerBody>

                  <DrawerFooter></DrawerFooter>
                </DrawerContent>
              </Drawer>
            </>
          )}
          <SidebarMobile isOpen={lIsOpen} onClose={lOnClose} />
        </Stack>
      </Flex>
    </Box>
  );
}
