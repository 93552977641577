export const resources = {
  en: {
    translation: {
      GlobalMessage: `This is an alpha version of our platform. Please report any issues you encounter to our support team, also, all suggestions are welcomed. Thank you! `,
      Dashboard: "Dashboard",
      DashboardDescription: "View details about your shop and appointments",
      Shops: "Shops",
      SearchShopByName: "Search Shop By Name",
      AddShop: "Add Shop",
      Name: "Name",
      SubscriptionStatus: "Subscription Status",
      NoShopsFound: "No Shops Found",
      Active: "Active",
      TrialUntil: "Trial Until {{date}}",
      AddSubscription: "Add Subscription",
      More: "More",
      Edit: "Edit",
      ViewAppointments: "View Appointments",
      ViewCalendar: "View Calendar",
      GetQrCode: "Get QR Code",
      DownloadAsImage: "Download as Image",
      GoToStripeSubscriptionPage: "Go To Stripe (Subscription Page)",
      Step: "Step",
      ContactInformation: "Contact Information",
      CalendarInformation: "Calendar Information",
      FinalSteps: "Final Steps",
      GiveAName: "Give A Name",
      GiveANameSpecification: "How you'd like to be called.",
      Url: "URL",
      UrlSpecification: "Which url users will use to book.",
      GiveADescription: "Give A Description",
      GiveADescriptionSpecification:
        "Write a description for your shop. It can be something like 'Nail Salon' or 'Barber Shop', or your address.",
      Logo: "Logo",
      LogoSpecification: "Your logo image. It's ok if you don't have one yet.",
      Email: "Email",
      EmailSpecification: "Write your email",
      EmailNotice:
        "For now, we need to use your email that is registered with us.",
      PhoneNumber: "Phone Number",
      PhoneNumberSpecification:
        "Write your phone number that users can use to contact you.",
      Next: "Next",
      Back: "Back",
      DayAvailability: "Day Availability",
      DayAvailabilitySpecification:
        "Days that users can book a date. Set the time range for each day.",
      AllDays: "All Days",
      ApplyToAllDays: "Apply To All Days",
      Monday: "Monday",
      Tuesday: "Tuesday",
      Wednesday: "Wednesday",
      Thursday: "Thursday",
      Friday: "Friday",
      Saturday: "Saturday",
      Sunday: "Sunday",
      Duration: "Duration",
      DurationSpecification:
        "Duration of each booking in minutes. Currently we have a limit of 60 minutes. And minimum is 10 minutes.",
      AvailableSlotPerBooking: "Available Slot Per Booking",
      AvailableSlotPerBookingSpecification:
        "Number of slots available for each booking. Eg. If you have 2 slots, 2 people can book the same time slot. Currently we have a limit of 10 slots.",
      BookLimitation: "Book Limitation",
      InDays: "In Days",
      BookLimitationSpecification:
        "How many days in the future can a user book a slot? Currently we have a limit of 120 days.",
      YouHaveDaysFree: "You Have {{days}} Days Free",
      YouHaveDaysFreeSpecification:
        "Don't worry, we won't ask for your credit card during the trial, and you can cancel at any time.",
      StartDayTrial: "Start {{days}}-Day Trial",
      YourShopHasBeenCreated: "Your Shop Has Been Created",
      YourShopHasBeenCreatedSpecification:
        "You can now start sharing your shop link with your customers.",
      GoToDashboard: "Go To Dashboard",
      Appointments: "Appointments",
      SearchAppointmentsByName: "Search Appointments By Name",
      Date: "Date",
      Status: "Status",
      NoAppointmentsFound: "No Appointments Found",
      Cancel: "Cancel",
      Decline: "Decline",
      Accept: "Accept",
      Cancelled: "Cancelled",
      Declined: "Declined",
      Accepted: "Accepted",
      View: "View",
      CreatedAt: "Created At",
      Description: "Description",
      StatusChangedAt: " (Status Changed At {{date}})",
      Close: "Close",
      SaveChanges: "Save Changes",
      YourChangesHaveBeenSaved: "Your changes have been saved!",
      DeleteShop: "Delete Shop",
      DeleteShopWarning: "Delete Shop Warning",
      Delete: "Delete",
      Type: "Type",
      ToConfirm: "to Confirm",
      DeleteShopInputPlaceholder: "Type your shop url to confirm",
      PermanentlyDeleteShopAndData: "Permanently Delete Shop And Data",
      MyAppointments: "My Appointments",
      Profile: "Profile",
      FullName: "Full Name",
      DisplayName: "Display Name",
      DisplayNameSpecification: "Your name.",
      EmailIsVerified: "Email Is Verified",
      PleaseVerifyYourEmail: "Please Verify Your Email",
      DeleteAccount: "Delete Account",
      DeleteAccountWarning: "Delete Account Warning",
      DeleteAccountInputPlaceholder: "Type your email to confirm",
      PermanentlyDeleteAccountAndData: "Permanently Delete Account And Data",
      DeleteAccountAlert:
        "If you have active shops please check your appointments since users won't have access anymore. Also all your personal appointments will get cancelled and all your shops will be deleted. Also, you will lose all your subscriptions.",
      NoSubscription: "No Subscription",
      QRCodeFor: "QR code for {{shopName}}",
      Shop: "Shop",
      SignIn: "Sign In",
      SignUp: "Sign Up",
      SignOut: "Sign Out",
      PleaseSignInToContinue: "Please sign in to continue",
      SignInToYourAccount: "Sign in to your Account",
      Password: "Password",
      FirstName: "First Name",
      LastName: "Last Name",
      EmailAddress: "Email Address",
      FormValidationError: "Please fill in all fields",
      RememberMe: "Remember Me",
      ForgotPassword: "Forgot password?",
      SignInWithGoogle: "Sign in with Google",
      SignUpWithGoogle: "Sign up with Google",
      NextPayment: "Next payment: {{date}}",
      PayNow: "Pay Now",
      Phone: "Phone",
      Pending: "Pending",
      Greek: "Ελληνικά",
      English: "English",
      ShopNotFound: "Shop not found",
      ShopDoesNotExist: "The shop you are looking for does not exist!",
      WelcomeBack: "Welcome back, {{name}}!",
      Success: "Success",
      SuccessfullyCreatedShop: "You have successfully created your shop!",
      SomethingWentWrong:
        "Something went wrong! Please try again in a few minutes.",
      Oops: "Oops!",
      UrlAlreadyTaken: "This URL is already taken. Please choose another one.",
      CreatingYourShop: "Creating your shop",
      WaitWhileWeCreateYourShop: "Please wait while we create your shop.",
      UrlCopiedToClipboard: "URL copied to clipboard",
      FailedToCopyUrlToClipboard: "Failed to copy URL to clipboard",
      AppointmentStatusChanged: "Appointment status changed",
      AppointmentCancelled: "Appointment cancelled",
      AccountDeleted: "Account deleted",
      AccountDeletedDescription: "Your account has been deleted.",
      YouHaveSuccessfullyBooked: "You have successfully booked for {{date}}!",
      BookFor: "Book for {{date}}",
      BookingDetails: "Booking Details",
      BookingDetailsSpecification: "Write any details for the booking here.",
      BookingDetailsPlaceholder:
        "I'd like to book for a haircut. I have long hair. I'd like to have a trim.",
      Book: "Book",
      ShopIsNotCurrentlyAvailable:
        "Shop is not currently available for bookings.",
      ShopIsNotCurrentlyAvailableSpecification:
        "Shop is not currently available for bookings. Please try again later.",
      YourAppointmentHasBeenBooked: "Your appointment has been booked!",
      YourAppointmentHasBeenBookedSpecification:
        "You have successfully booked for {{date}}! You will recieve an email when the shop accepts your booking.",
      Loading: "Loading...",
      Today: "Today",
      Previous: "Previous",
      YouAlreadyHaveASubscription:
        "You already have a subscription until {{date}}",
      LoadingYourPayment: "Loading your payment!",
      PleaseWaitWhileWeRedirectYouToThePaymentPage:
        "Please wait while we redirect you to the payment page.",
      Monthly: "Monthly",
      Yearly: "Yearly",
      ShopDeleted: "Shop deleted",
      ShopDeletedSpecification: "Your shop has been deleted.",
      ErrorUploadingLogo: "An error occured while uploading your logo.",
      ShopDeletionWarning:
        "All pending and accepted appointments will be cancelled. Your shop and all its data will be deleted.",
      YourShopIsInactive:
        "Your shop is inactive. If you have active appointments please warn your customers.",
      YouHaveActiveFutureAppointments:
        "You have active future appointments. You cannot edit your working schedule. Please decline all appointments first.",
      YourEmailIsUnverified:
        "Your email is unverified. Please check your inbox. It might be in the spam folder.",
      YourEmailIsVerified: "Your email is verified.",
      YouHaveAnActiveSubscription:
        "You have an active subscription. Please cancel it first.",
      CancelSubscription: "Cancel Subscription",
      FieldIsRequired: "This field is required.",
      InvalidPhoneNumber: "Invalid phone number.",
      ValueOutOfRange: "Value out of range.",
      NeedHelp: "Need help?",
      ContactUs: "Contact us",
      TimeslotsApplied: "Timeslots applied to all days.",
      AnApppointShop: "A shop on Apppoint.gr",
      ActiveSubscription: "Active Subscription",
      ChangeFile: "Change File",
      UploadFile: "Upload File",
      Closed: "Closed",
      SelectATimeslot: "Select a Timeslot",
      SelectATimeslotSpecification: "Select a timeslot to book.",
      ThisDayIsNotAvailable: "This day is not available for booking.",
      FileSizeLimit: "File size limit is 10MB.",
      BySigningUpYouAgreeToOur: "By signing up you agree to our",
      TermsOfService: "Terms of Service",
      PrivacyPolicy: "Privacy Policy",
      And: "and",
      StripeMessage: "All payments and subscriptions are handled by",
      TimeChanged: "Time changed",
      SomeoneBookedOnSelectedTime: "Someone else booked on your selected time.",
      PasswordResetEmailSent: "Password reset email sent.",
      PleaseFillYourEmail: "Please fill in your email.",
      FutureAppointmentsOnly: "Future Appointments Only",
      OrderBy: "Order By",
      EmailAlreadyInUse: "Email already in use.",
      InvalidCredentials: "Invalid credential.",
      RequiresRecentLogin: "Requires recent login. Please sign in again.",
      year: "year",
      month: "month",
      trialing: "Trialing",
      active: "Active",
      validUntil: "Valid until {{date}}",
      ChooseYourSubscriptionModelToStart:
        "Choose your subscription model to start",
      EverythingReady: "Everything is ready!",
      CreateShop: "Create Shop",
      CancelSubscriptionFor: "Cancel Subscription for {{shopName}}",
      CancelSubscriptionSpecification:
        "If you cancel your subscription, your shop will be suspended at {{date}} and your customers won't be able to book appointments. You will need to make a new subscription after {{date}}.",
      YourSubscriptionWillBeCancelledAt:
        "Your subscription will be cancelled at {{date}}",
      ResumeSubscription: "Resume Subscription",
      SubscriptionCancelled: "Subscription Cancelled",
      SubscriptionCancelledDescription:
        "Your subscription has been cancelled successfully.",
      PasswordDoesNotMeetRequirements:
        "Password does not meet requirements. Password must be at least 10 characters long and contain at least one uppercase letter, one lowercase letter and one number.",
      CancelSubscriptionFromDashboard: "Cancel Subscription from Dashboard.",

      YourOnlineShop: "Your Online Shop",
      Simplified: "Simplified",
      EffortlesslyManage:
        "Effortlessly manage appointments and grow your business.",
      StartYourShopToday: "Start Your Shop Today",
      Features: "Features",
      AppointmentScheduling: "Appointment Scheduling",
      AppointmentSchedulingSpecification:
        "Create and manage appointments with ease. Send automated reminders to keep customers informed.",
      CustomerManagement: "Customer Management",
      CustomerManagementSpecification:
        "Track customer information, preferences, and appointment history. Build strong relationships with your clients.",
      MobileOptimization: "Mobile/Desktop Optimization",
      MobileOptimizationSpecification:
        "Access your shop and manage appointments from anywhere, anytime. Keep your business running smoothly, even on the go.",
      AndMuchMoreFeatures: "And much more features...",
      EasilyManageAppointments:
        "Ready to take your business to the next level? Create your online shop today and start benefiting from our powerful features. It's easy, efficient, and designed to help you succeed.",
      Quote: "Easily manage appointments and grow your business.",
      StartYourShop: "Start Your Shop",
      Quote2:
        "How Our Platform Can Streamline Your Business and Appointment Management",
      Quote2Specification:
        "Are you tired of juggling multiple calendars and struggling to keep track of appointments? Our platform is designed to simplify your business operations and make appointment management a breeze.",
    },
  },
  el: {
    translation: {
      GlobalMessage:
        "Αυτή είναι μια έκδοση alpha της πλατφόρμας μας. Παρακαλούμε αναφέρετε οποιοδήποτε πρόβλημα αντιμετωπίσετε στην ομάδα υποστήριξης μας, επίσης, όλες οι προτάσεις είναι ευπρόσδεκτες. Ευχαριστούμε! ",
      Dashboard: "Πίνακας ελέγχου",
      DashboardDescription:
        "Δείτε λεπτομέρειες για το κατάστημά σας και τα ραντεβού",
      Shops: "Καταστήματα",
      SearchShopByName: "Αναζήτηση καταστήματος με όνομα",
      AddShop: "Προσθήκη καταστήματος",
      Name: "Όνομα",
      SubscriptionStatus: "Κατάσταση συνδρομής",
      NoShopsFound: "Δεν βρέθηκαν καταστήματα",
      Active: "Ενεργό",
      TrialUntil: "Δοκιμή έως {{date}}",
      AddSubscription: "Προσθήκη συνδρομής",
      More: "Περισσότερα",
      Edit: "Επεξεργασία",
      ViewAppointments: "Προβολή ραντεβού",
      ViewCalendar: "Προβολή ημερολογίου",
      GetQrCode: "Λήψη QR κωδικού",
      DownloadAsImage: "Λήψη ως εικόνα",
      GoToStripeSubscriptionPage: "Μετάβαση στη σελίδα συνδρομής Stripe",
      Step: "Βήμα",
      ContactInformation: "Πληροφορίες επικοινωνίας",
      CalendarInformation: "Πληροφορίες ημερολογίου",
      FinalSteps: "Τελικά βήματα",
      GiveAName: "Δώστε ένα όνομα",
      GiveANameSpecification: "Πώς θα θέλατε να σας αποκαλούν.",
      Url: "URL",
      UrlSpecification:
        "Το URL που θα χρησιμοποιούν οι χρήστες για να κλείνουν ραντεβού.",
      GiveADescription: "Δώστε μια περιγραφή",
      GiveADescriptionSpecification:
        "Γράψτε μια περιγραφή για το κατάστημά σας. Μπορεί να είναι κάτι σαν 'Κέντρο Ομορφιάς' ή 'Κομμωτήριο', ή η διεύθυνση σας.",
      Logo: "Λογότυπο",
      LogoSpecification:
        "Η εικόνα του λογοτύπου σας. Είναι εντάξει αν δεν έχετε ακόμα.",
      Email: "Email",
      EmailSpecification: "Γράψτε το email σας",
      EmailNotice:
        "Προς το παρόν, πρέπει να χρησιμοποιήσουμε το email που έχετε καταχωρίσει.",
      PhoneNumber: "Αριθμός τηλεφώνου",
      PhoneNumberSpecification:
        "Γράψτε τον αριθμό τηλεφώνου σας που μπορούν να χρησιμοποιούν οι χρήστες για να επικοινωνήσουν μαζί σας.",
      Next: "Επόμενο",
      Back: "Πίσω",
      DayAvailability: "Διαθεσιμότητα ημέρας",
      DayAvailabilitySpecification:
        "Οι ημέρες που οι χρήστες μπορούν να κλείσουν ραντεβού. Ορίστε το χρονικό διάστημα για κάθε ημέρα.",
      AllDays: "Όλες οι ημέρες",
      ApplyToAllDays: "Εφαρμογή σε όλες τις ημέρες",
      Monday: "Δευτέρα",
      Tuesday: "Τρίτη",
      Wednesday: "Τετάρτη",
      Thursday: "Πέμπτη",
      Friday: "Παρασκευή",
      Saturday: "Σάββατο",
      Sunday: "Κυριακή",
      Duration: "Διάρκεια",
      DurationSpecification:
        "Διάρκεια κάθε κράτησης σε λεπτά. Αυτή τη στιγμή έχουμε όριο 60 λεπτών. Και το ελάχιστο είναι 10 λεπτά.",
      AvailableSlotPerBooking: "Διαθέσιμες θέσεις ανά κράτηση",
      AvailableSlotPerBookingSpecification:
        "Ο αριθμός των θέσεων που είναι διαθέσιμες για κάθε κράτηση. Π.χ., αν έχετε 2 θέσεις, 2 άτομα μπορούν να κλείσουν την ίδια χρονική θέση. Αυτή τη στιγμή έχουμε όριο 10 θέσεων.",
      BookLimitation: "Περιορισμός κρατήσεων",
      InDays: "Σε ημέρες",
      BookLimitationSpecification:
        "Πόσες ημέρες στο μέλλον μπορεί ένας χρήστης να κλείσει μια θέση; Αυτή τη στιγμή έχουμε όριο 120 ημερών.",
      YouHaveDaysFree: "Έχετε {{days}} ημέρες δωρεάν",
      YouHaveDaysFreeSpecification:
        "Μην ανησυχείτε, δεν θα ζητήσουμε την πιστωτική σας κάρτα κατά τη διάρκεια της δοκιμής, και μπορείτε να ακυρώσετε οποιαδήποτε στιγμή.",
      StartDayTrial: "Ξεκινήστε την {{days}}-ημέρη δοκιμή",
      YourShopHasBeenCreated: "Το κατάστημά σας έχει δημιουργηθεί",
      YourShopHasBeenCreatedSpecification:
        "Μπορείτε τώρα να αρχίσετε να μοιράζεστε τον σύνδεσμο του καταστήματός σας με τους πελάτες σας.",
      GoToDashboard: "Μετάβαση στον πίνακα ελέγχου",
      Appointments: "Ραντεβού",
      SearchAppointmentsByName: "Αναζήτηση ραντεβού με όνομα",
      Date: "Ημερομηνία",
      Status: "Κατάσταση",
      NoAppointmentsFound: "Δεν βρέθηκαν ραντεβού",
      Cancel: "Ακύρωση",
      Decline: "Άρνηση",
      Accept: "Αποδοχή",
      Cancelled: "Ακυρώθηκε",
      Declined: "Απορρίφθηκε",
      Accepted: "Αποδεκτό",
      View: "Προβολή",
      CreatedAt: "Δημιουργήθηκε στις",
      Description: "Περιγραφή",
      StatusChangedAt: " (Κατάσταση άλλαξε στις {{date}})",
      Close: "Κλείσιμο",
      SaveChanges: "Αποθήκευση αλλαγών",
      YourChangesHaveBeenSaved: "Οι αλλαγές σας έχουν αποθηκευτεί!",
      DeleteShop: "Διαγραφή καταστήματος",
      DeleteShopWarning: "Προειδοποίηση διαγραφής καταστήματος",
      Delete: "Διαγραφή",
      Type: "Γράψτε",
      ToConfirm: "για επιβεβαίωση",
      DeleteShopInputPlaceholder: "Πληκτρολογήστε το URL του καταστήματός σας",
      PermanentlyDeleteShopAndData:
        "Μόνιμη διαγραφή καταστήματος και δεδομένων",
      MyAppointments: "Τα ραντεβού μου",
      Profile: "Προφίλ",
      FullName: "Πλήρες όνομα",
      DisplayName: "Όνομα εμφάνισης",
      DisplayNameSpecification: "Το όνομά σας.",
      EmailIsVerified: "Το email έχει επαληθευτεί",
      PleaseVerifyYourEmail: "Παρακαλώ επαληθεύστε το email σας",
      DeleteAccount: "Διαγραφή λογαριασμού",
      DeleteAccountWarning: "Προειδοποίηση διαγραφής λογαριασμού",
      DeleteAccountInputPlaceholder:
        "Πληκτρολογήστε το email σας για επιβεβαίωση",
      PermanentlyDeleteAccountAndData:
        "Μόνιμη διαγραφή λογαριασμού και δεδομένων",
      DeleteAccountAlert:
        "Αν έχετε ενεργά καταστήματα, παρακαλώ ελέγξτε τα ραντεβού σας αφού οι χρήστες δεν θα έχουν πρόσβαση πλέον. Επίσης, όλα τα προσωπικά σας ραντεβού θα ακυρωθούν και όλα τα καταστήματά σας θα διαγραφούν. Επίσης, θα χάσετε όλες τις συνδρομές σας.",
      NoSubscription: "Δεν υπάρχει συνδρομή",
      QRCodeFor: "QR κωδικός για το {{shopName}}",
      Shop: "Κατάστημα",
      SignIn: "Σύνδεση",
      SignUp: "Εγγραφή",
      SignOut: "Αποσύνδεση",
      PleaseSignInToContinue: "Παρακαλώ συνδεθείτε για να συνεχίσετε",
      SignInToYourAccount: "Συνδεθείτε στον λογαριασμό σας",
      Password: "Κωδικός",
      FirstName: "Όνομα",
      LastName: "Επώνυμο",
      EmailAddress: "Διεύθυνση Email",
      FormValidationError: "Παρακαλώ συμπληρώστε όλα τα πεδία",
      RememberMe: "Να με θυμάσαι",
      ForgotPassword: "Ξεχάσατε τον κωδικό;",
      SignInWithGoogle: "Σύνδεση με Google",
      SignUpWithGoogle: "Εγγραφή με Google",
      NextPayment: "Επόμενη πληρωμή: {{date}}",
      PayNow: "Πληρώστε τώρα",
      Phone: "Τηλέφωνο",
      Pending: "Εκκρεμεί",
      Greek: "Ελληνικά",
      English: "English",
      ShopNotFound: "Το κατάστημα δεν βρέθηκε",
      ShopDoesNotExist: "Το κατάστημα που ψάχνετε δεν υπάρχει!",
      WelcomeBack: "Καλώς ήρθατε, {{name}}!",
      Success: "Επιτυχία",
      SuccessfullyCreatedShop: "Δημιουργήσατε με επιτυχία το κατάστημά σας!",
      SomethingWentWrong:
        "Κάτι πήγε στραβά! Παρακαλώ δοκιμάστε ξανά σε λίγα λεπτά.",
      Oops: "Ουπς!",
      UrlAlreadyTaken:
        "Αυτό το URL είναι ήδη κατειλημμένο. Παρακαλώ επιλέξτε ένα άλλο.",
      CreatingYourShop: "Δημιουργία του καταστήματός σας",
      WaitWhileWeCreateYourShop:
        "Παρακαλώ περιμένετε ενώ δημιουργούμε το κατάστημά σας.",
      UrlCopiedToClipboard: "Το URL αντιγράφηκε στο πρόχειρο",
      FailedToCopyUrlToClipboard: "Απέτυχε η αντιγραφή του URL στο πρόχειρο",
      AppointmentStatusChanged: "Η κατάσταση του ραντεβού άλλαξε",
      AppointmentCancelled: "Το ραντεβού ακυρώθηκε",
      AccountDeleted: "Ο λογαριασμός διαγράφηκε",
      AccountDeletedDescription: "Ο λογαριασμός σας διαγράφηκε.",
      YouHaveSuccessfullyBooked: "Έχετε κλείσει επιτυχώς για την {{date}}!",
      BookFor: "Κράτηση για {{date}}",
      BookingDetails: "Λεπτομέρειες κράτησης",
      BookingDetailsSpecification:
        "Γράψτε οποιεσδήποτε λεπτομέρειες για την κράτηση εδώ.",
      Book: "Κράτηση",
      ShopIsNotCurrentlyAvailable:
        "Το κατάστημα δεν είναι διαθέσιμο για κρατήσεις.",
      ShopIsNotCurrentlyAvailableSpecification:
        "Το κατάστημα δεν είναι διαθέσιμο για κρατήσεις. Παρακαλώ δοκιμάστε ξανά αργότερα.",
      BookingDetailsPlaceholder:
        "Θα ήθελα να κλείσω για ένα κούρεμα. Έχω μακριά μαλλιά. Θα ήθελα να κοντίνω.",
      YourAppointmentHasBeenBooked: "Η κράτησή σας έχει κλείσει!",
      YourAppointmentHasBeenBookedSpecification:
        "Έχετε κλείσει επιτυχώς για την {{date}}! Θα λάβετε email όταν το κατάστημα αποδεχτεί την κράτησή σας.",
      Loading: "Φόρτωση...",
      Today: "Σήμερα",
      Previous: "Προηγούμενο",
      YouAlreadyHaveASubscription: "Έχετε ήδη μια συνδρομή μέχρι τις {{date}}",
      LoadingYourPayment: "Φόρτωση της πληρωμής σας!",
      PleaseWaitWhileWeRedirectYouToThePaymentPage:
        "Παρακαλώ περιμένετε ενώ σας ανακατευθύνουμε στη σελίδα πληρωμής.",
      Monthly: "Μήνα",
      Yearly: "Έτος",
      ShopDeleted: "Το κατάστημα διαγράφηκε",
      ShopDeletedSpecification: "Το κατάστημά σας διαγράφηκε.",
      ErrorUploadingLogo: "Σφάλμα κατά τη μεταφόρτωση του λογότυπου",
      ShopDeletionWarning:
        "Όλα τα εκκρεμή και αποδεκτά ραντεβού θα ακυρωθούν. Το κατάστημά σας και όλα τα δεδομένα του θα διαγραφούν.",
      YourShopIsInactive:
        "Το κατάστημά σας είναι ανενεργό. Αν έχετε ενεργά ραντεβού, παρακαλώ ειδοποιήστε τους πελάτες σας.",
      YouHaveActiveFutureAppointments:
        "Έχετε ενεργά μελλοντικά ραντεβού. Δεν μπορείτε να επεξεργαστείτε το ωράριο εργασίας σας. Παρακαλώ απορρίψτε όλα τα ραντεβού πρώτα.",
      YourEmailIsUnverified:
        "Το email σας δεν έχει επαληθευτεί. Παρακαλώ ελέγξτε τα εισερχόμενά σας. Μπορεί να βρίσκεται στο φάκελο ανεπιθύμητης αλληλογραφίας.",
      YourEmailIsVerified: "Το email σας έχει επαληθευτεί.",
      YouHaveAnActiveSubscription:
        "Έχετε μια ενεργή συνδρομή. Παρακαλώ ακυρώστε την πρώτα.",
      CancelSubscription: "Ακύρωση συνδρομής",
      FieldIsRequired: "Αυτό το πεδίο είναι υποχρεωτικό.",
      InvalidPhoneNumber: "Μη έγκυρος αριθμός τηλεφώνου.",
      ValueOutOfRange: "Η τιμή είναι εκτός εύρους.",
      NeedHelp: "Χρειάζεστε βοήθεια;",
      ContactUs: "Επικοινωνήστε μαζί μας",
      TimeslotsApplied: "Οι ώρες εφαρμόστηκαν σε όλες τις ημέρες.",
      AnApppointShop: "Ένα κατάστημα στο Apppoint.gr",
      ActiveSubscription: "Ενεργή συνδρομή",
      ChangeFile: "Αλλαγή αρχείου",
      UploadFile: "Μεταφόρτωση αρχείου",
      Closed: "Κλειστό",
      SelectATimeslot: "Επιλέξτε ώρα",
      SelectATimeslotSpecification: "Επιλέξτε μια ώρα για κράτηση.",
      ThisDayIsNotAvailable: "Αυτή η ημέρα δεν είναι διαθέσιμη για κράτηση.",
      FileSizeLimit:
        "Το μέγεθος του αρχείου πρέπει να είναι μικρότερο από 10MB.",
      BySigningUpYouAgreeToOur: "Με την εγγραφή σας συμφωνείτε με τους",
      TermsOfService: "Όρους Χρήσης",
      PrivacyPolicy: "Πολιτική Απορρήτου",
      And: "και",
      StripeMessage:
        "Οι πληρωμές και η διαχείρηση των συνδρομών γίνονται μέσω της πλατφόρμας",
      TimeChanged: "Η ώρα άλλαξε",
      SomeoneBookedOnSelectedTime:
        "Κάποιος πρόλαβε την ώρα που επιλέξατε. Παρακαλώ επιλέξτε μια άλλη.",
      PasswordResetEmailSent:
        "Σας έχουμε στείλει email για την επαναφορά του κωδικού σας.",
      PleaseFillYourEmail: "Παρακαλώ συμπληρώστε το email σας.",
      FutureAppointmentsOnly: "Μόνο μελλοντικά ραντεβού",
      OrderBy: "Ταξινόμηση κατά",
      EmailAlreadyInUse: "Το email χρησιμοποιείται ήδη.",
      InvalidCredentials: "Μη έγκυρα στοιχεία.",
      RequiresRecentLogin:
        "Απαιτείται πρόσφατη σύνδεση. Παρακαλώ συνδεθείτε ξανά.",
      year: "έτος",
      month: "μήνα",
      trialing: "Δοκιμαστικό",
      active: "Ενεργό",
      validUntil: "Έγκυρη μέχρι τις {{date}}",
      ChooseYourSubscriptionModelToStart:
        "Επιλέξτε το μοντέλο συνδρομής σας για να ξεκινήσετε",
      EverythingReady: "Όλα έτοιμα!",
      CreateShop: "Δημιουργία καταστήματος",
      CancelSubscriptionFor: "Ακύρωση συνδρομής για {{shopName}}",
      YourSubscriptionWillBeCancelledAt:
        "Η συνδρομή σας θα ακυρωθεί στις {{date}}",
      CancelSubscriptionSpecification:
        "Αν ακυρώσετε τη συνδρομή σας, το κατάστημά σας θα ανασταλεί στις {{date}} και οι πελάτες σας δεν θα μπορούν να κλείσουν ραντεβού. Θα πρέπει να κάνετε νεα συνδρομή μετα τη τις {{date}}.",
      ResumeSubscription: "Επαναφορά συνδρομής",
      SubscriptionCancelled: "Η συνδρομή ακυρώθηκε",
      SubscriptionCancelledDescription: "Η συνδρομή σας ακυρώθηκε επιτυχώς.",
      PasswordDoesNotMeetRequirements:
        "Ο κωδικός δεν πληροί τις απαιτήσεις. Πρέπει να είναι τουλάχιστον 10 χαρακτήρες και να περιέχει τουλάχιστον έναν αριθμό, ένα κεφαλαίο γράμμα και ένα μικρό γράμμα.",
      CancelSubscriptionFromDashboard:
        "Ακυρώστε τη συνδρομή σας από τον πίνακα ελέγχου.",

      YourOnlineShop: "Το Κατάστημά σας",
      Simplified: "Απλοποιημένο",
      EffortlesslyManage:
        "Διαχειριστείτε εύκολα τα ραντεβού και αυξήστε την πελατία σας.",
      StartYourShopToday: "Ξεκινήστε το κατάστημά σας σήμερα!",
      Features: "Χαρακτηριστικά",
      AppointmentScheduling: "Προγραμματισμός ραντεβού",
      AppointmentSchedulingSpecification:
        "Δημιουργήστε και διαχειριστείτε ραντεβού με ευκολία. Στείλτε αυτοματοποιημένες υπενθυμίσεις για να κρατήσετε τους πελάτες ενήμερους.",
      CustomerManagement: "Διαχείριση πελατών",
      CustomerManagementSpecification:
        "Καταγράψτε πληροφορίες πελατών, προτιμήσεις και ιστορικό ραντεβού. Δημιουργήστε ισχυρές σχέσεις με τους πελάτες σας.",
      MobileOptimization: "Φτιαγμένο για κινητά & υπολογιστές",
      MobileOptimizationSpecification:
        "Αποκτήστε πρόσβαση στο κατάστημά σας και διαχειριστείτε ραντεβού από οπουδήποτε, οποτεδήποτε. Διατηρήστε την επιχείρησή σας να λειτουργεί ομαλά, ακόμα και όταν είστε κινητός.",
      AndMuchMoreFeatures: "Και πολλά άλλα...",
      EasilyManageAppointments:
        "Έτοιμοι να πάτε την επιχείρησή σας σε άλλο επίπεδο; Δημιουργήστε σήμερα το online κατάστημά σας και αρχίστε να επωφελείστε από τα ισχυρά μας χαρακτηριστικά. Είναι εύκολο, αποτελεσματικό και σχεδιασμένο για να σας βοηθήσει να επιτύχετε.",
      Quote: "Διαχειριστείτε εύκολα τα ραντεβού και αυξήστε την πελατία σας.",
      StartYourShop: "Ξεκινήστε το κατάστημά σας",
      Quote2:
        "Πώς η πλατφόρμα μας μπορεί να βελτιώσει την επιχείρησή σας και τη διαχείριση των ραντεβού.",
      Quote2Specification:
        "Έχετε κουραστεί να αλλάζεται πολλα ημερολόγια και να παλεύετε να κρατήσετε τα ραντεβού; Η πλατφόρμα μας σχεδιάστηκε για να απλοποιήσει τις επιχειρηματικές σας λειτουργίες και να κάνει τη διαχείριση των ραντεβού μια παιχνιδιάρικη διαδικασία.",
    },
  },
};
