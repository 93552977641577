// context/AuthModalContext.tsx
import React, { createContext, useContext, useState, ReactNode } from "react";

interface AuthModalContextProps {
    isModalOpen: boolean;
  modalType: "signin" | "signup";
  openModal: (type: "signin" | "signup") => void;
  closeModal: () => void;
}

const AuthModalContext = createContext<AuthModalContextProps | undefined>(
  undefined
);

export const useAuthModal = () => {
  const context = useContext(AuthModalContext);
  if (!context) {
    throw new Error("useAuthModal must be used within an AuthModalProvider");
  }
  return context;
};

export const AuthModalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState<"signin" | "signup">("signin");

  const openModal = (type: "signin" | "signup") => {
    setModalType(type);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <AuthModalContext.Provider
      value={{ isModalOpen, modalType, openModal, closeModal }}
    >
      {children}
    </AuthModalContext.Provider>
  );
};
