import { background, NumberInput, Textarea } from "@chakra-ui/react";
import { _mainColor } from "../../classes/Utils";

export const inputStyles = {
  components: {
    Input: {
      variants: {
        outline: {
          field: {
            background: "white",
            padding: "25px 20px",
          },
        },
      },
      baseStyle: {
        bg: "white",
      },
    },
    NumberInput: {
      variants: {
        outline: {
          field: {
            background: "white",
            padding: "25px 20px",
          },
        },
      },
      baseStyle: {
        bg: "white",
      },
    },
    Textarea: {
      variants: {
        outline: {
          background: "white",
          maxHeight: "300px",
        },
      },
    },
  },
};
