import {
  AbsoluteCenter,
  Alert,
  AlertIcon,
  Box,
  Button,
  Container,
  Heading,
  HStack,
  useToast,
  Image,
  Center,
  VStack,
  Text,
  Fade,
  Flex,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { auth, firestore } from "../firebase";
import { getCheckoutUrl, getShopSubscription } from "../stripe/Stripe";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { LoadingAnimation } from "../repeatable-components/RepeatableComponents";
import { _mainColor, formatFirebaseDate } from "../classes/Utils";
import { ShopPresentation } from "./ShopPresentation";
import { useTranslation } from "react-i18next";
import Card from "../theme/components/Card/Card";
import CardBody from "../theme/components/Card/CardBody";
import IconBox from "../theme/components/Icons/IconBox";
import { Separator } from "../theme/components/Separator/Separator";
import { FaDiceOne, FaWallet } from "react-icons/fa";
import { useMyShop, useShop } from "../hooks/useShop";

export const UpdateSubscription = () => {
  const [user] = useAuthState(auth);
  const { url } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const [products, setProducts] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [urlLoading, setUrlLoading] = useState(false);
  const { t } = useTranslation();
  const { data: shop } = useMyShop(url || "");
  useEffect(() => {
    const get = async () => {
      const q_products = query(
        collection(firestore, "products"),
        where("active", "==", true)
      );
      const snapshot_products: any = await getDocs(q_products);
      if (snapshot_products.empty) {
        setIsLoading(false);
        return;
      }
      setProducts([]);
      snapshot_products.docs
        .map((doc: any) => doc)
        .forEach(async (doc: any) => {
          const q = query(collection(firestore, "products", doc.id, "prices"));
          await getDocs(q).then((snapshot_prices: any) => {
            snapshot_prices.docs.map((doc: any) => {
              const p = doc.data();
              p.id = doc.id;
              setProducts((prev: any) => [...prev, p]);
            });
          });
        });
      setIsLoading(false);
    };

    get().catch((error) => {
      toast({
        title: t("SomethingWentWrong"),
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    });
  }, []);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!shop || !shop.id) {
      toast({
        title: t("Oops"),
        description: t("ShopNotFound"),
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      navigate("/dashboard");
      return;
    }
  }, [shop]);

  if (shop) {
    if (
      shop.subscription &&
      (shop.subscription.status === "active" ||
        shop.subscription.status === "trialing")
    ) {
      // if (shop.subscription) {
      return (
        <>
          <ShopPresentation shop={shop} />
          <Center>
            <Box w={"20%"} my="40px">
              <Image src={"/illustrations/heart.svg"} alt="heart" w="30%" />
            </Box>
          </Center>

          <Alert status="success" variant="left-accent">
            <AlertIcon />
            {t("YouAlreadyHaveASubscription", {
              date: formatFirebaseDate(shop.subscription.current_period_end),
            })}
          </Alert>
          <Link to={`/dashboard`}>
            <Button variant={"solid"} colorScheme="blue" w={"100%"} my="20px">
              {t("GoToDashboard")}
            </Button>
          </Link>
        </>
      );
    }
  }

  return (
    // <Container maxW="container.xl" my="40px">
    <>
      {shop && products && !isLoading ? (
        <>
          {!urlLoading ? (
            <Fade in={!urlLoading}>
              <ShopPresentation shop={shop} />
              <HStack mb="20px">
                {products.map((product: any, index: number) => (
                  <Card
                    key={index}
                    p="16px"
                    display="flex"
                    align="center"
                    justify="center"
                    border="2px solid transparent"
                    _hover={{
                      cursor: "pointer",
                      border: `2px solid ${_mainColor()}`,
                      boxShadow: "0 0 20px rgba(0,0,0,0.1)",
                    }}
                    onClick={async () => {
                      if (urlLoading) return;
                      setUrlLoading(true);
                      await getCheckoutUrl(product.id, shop).then(
                        (res: any) => {
                          if (res.session.url) {
                            window.location.href = res.session.url;
                          } else {
                            toast({
                              title: t("Oops"),
                              description: t("SomethingWentWrong"),
                              status: "error",
                              duration: 9000,
                              isClosable: true,
                            });
                            setUrlLoading(false);
                          }
                        }
                      );
                    }}
                  >
                    <CardBody>
                      <Flex
                        direction="column"
                        align="center"
                        w="100%"
                        py="14px"
                      >
                        <IconBox
                          as="box"
                          h={"60px"}
                          w={"60px"}
                          bg={_mainColor()}
                          color="white"
                        >
                          <FaWallet />
                        </IconBox>
                        <Flex
                          direction="column"
                          m="14px"
                          justify="center"
                          textAlign="center"
                          align="center"
                          w="100%"
                        >
                          <Text fontSize="md" fontWeight="bold">
                            {product.unit_amount / 100}€/
                            {t(product.interval)}
                          </Text>
                          <Text
                            mb="24px"
                            fontSize="xs"
                            color="gray.400"
                            fontWeight="semibold"
                          >
                            {product.name}
                          </Text>
                          <Separator />
                        </Flex>
                        <Text fontSize="lg" fontWeight="bold">
                          {shop?.subscription?.trialUsed == false ? (
                            <>{t("StartDayTrial", { days: 14 })}</>
                          ) : (
                            <>
                              {t("PayNow")}: {`${product.unit_amount / 100}€`}
                            </>
                          )}
                        </Text>
                      </Flex>
                    </CardBody>
                  </Card>
                ))}
              </HStack>
              <Center>
                <Box w="100%" mt="60px">
                  <Text color={"gray.400"} textAlign="center">
                    {t("StripeMessage")}
                  </Text>
                  <Image
                    src={"/images/stripe.png"}
                    alt="stripe"
                    w="10%"
                    m="0 auto"
                  />
                </Box>
              </Center>
            </Fade>
          ) : (
            <>
              <Fade in={urlLoading}>
                <Box w={"100%"}>
                  <Center>
                    <Image
                      src={"/illustrations/sync.svg"}
                      alt="heart"
                      w="20%"
                    />
                  </Center>
                </Box>
                <Box textAlign="center" py={10} px={6}>
                  <Heading as="h2" size="xl" mt={6} mb={2}>
                    {t("LoadingYourPayment")}
                  </Heading>
                  <Text color={"gray.500"}>
                    {t("PleaseWaitWhileWeRedirectYouToThePaymentPage")}
                  </Text>
                </Box>
              </Fade>
            </>
          )}
          {/* </VStack> */}
        </>
      ) : (
        <LoadingAnimation />
      )}
    </>
  );
};
