import { IAppointment } from "../interfaces/interfaces";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../firebase";
import { getEmailTemplate } from "../email-templates/EmailTemplates";
import { SendEmail } from "./Email";

export const changeStatus = async (
  appointment: IAppointment,
  status: string
) => {
  return new Promise((resolve, reject) => {
    try {
      if (!appointment) {
        reject(false);
        return;
      }
      const appointmentRef = doc(
        firestore,
        "shops",
        appointment.shop,
        "appointments",
        appointment.id
      );
      updateDoc(appointmentRef, {
        status: status,
        statusChangeAt: new Date(),
      });
      SendEmail({
        recipient:
          status == "cancelled" ? appointment.shopEmail : appointment.email,
        subject: "Appointment status changed.",
        message: getEmailTemplate(status, appointment),
      });
      resolve(true);
    } catch (error) {
      reject(false);
      console.error(error);
    }
  });
};
