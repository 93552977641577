import {
  Avatar,
  Button,
  Card,
  CardBody,
  Divider,
  Heading,
  HStack,
  Input,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
  Stack,
  ScaleFade,
  Box,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, firestore } from "../firebase";
import { useAuthModal } from "../context/AuthModalContext";
import {
  _mainColor,
  formatFirebaseDate,
  getAppointmentStatusColor,
  getAppointmentStatusIcon,
} from "../classes/Utils";
import { LoadingAnimation } from "../repeatable-components/RepeatableComponents";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { IAppointment } from "../interfaces/interfaces";
import { changeStatus } from "../classes/Appointment";
import { useQuery } from "react-query";
import { useMyAppointments } from "../hooks/useAppointment";

export const MyAppointments = () => {
  const [user] = useAuthState(auth);
  const toast = useToast();
  const [search, setSearch] = useState("");
  const { openModal } = useAuthModal();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: iIsOpen,
    onOpen: iOnOpen,
    onClose: iOnClose,
  } = useDisclosure();
  const [selectedAppointment, setSelectedAppointment] =
    useState<IAppointment>();
  const { t } = useTranslation();
  useEffect(() => {
    const get = async () => {
      if (!user) {
        openModal("signin");
        return;
      }
    };

    get().catch((error) => {
      console.error(error);
    });
  }, []);

  const { data, isLoading, refetch } = useMyAppointments();

  const filteredData = () => {
    return data?.filter((item: any) =>
      item.shopName.toLowerCase().includes(search.toLowerCase())
    );
  };

  const viewAppointment = (appointment: IAppointment) => {
    setSelectedAppointment(appointment);
    onOpen();
  };

  return (
    <>
      {selectedAppointment && (
        <Modal
          onClose={onClose}
          isOpen={isOpen}
          isCentered
          scrollBehavior="inside"
        >
          <ModalOverlay />
          <ModalContent borderRadius={"10px"} maxW={["90%", "md", "md"]}>
            {/* <Box h="100%" overflowY={"scroll"}> */}
            <ModalHeader></ModalHeader>
            <ModalCloseButton />
            <ModalBody padding={"20px"}>
              <Text fontWeight={"bold"}>{t("Name")}</Text>
              <Text>{selectedAppointment?.name}</Text>
              <Text fontWeight={"bold"} marginTop="10px">
                {t("Email")}
              </Text>
              <Text>{selectedAppointment?.email}</Text>
              <Text fontWeight={"bold"} marginTop="10px">
                {t("Phone")}
              </Text>
              <Text>{selectedAppointment?.phone}</Text>
              <Text fontWeight={"bold"} marginTop="10px">
                {t("Date")}
              </Text>
              <Text>{formatFirebaseDate(selectedAppointment?.date)}</Text>
              <Text fontWeight={"bold"} marginTop="10px">
                {t("Status")}
              </Text>
              <HStack
                color={getAppointmentStatusColor(selectedAppointment?.status)}
              >
                {selectedAppointment.status &&
                  getAppointmentStatusIcon(selectedAppointment?.status) && (
                    <Text fontWeight={"600"}>
                      {t(
                        selectedAppointment?.status.charAt(0).toUpperCase() +
                          selectedAppointment?.status.slice(1)
                      )}{" "}
                      {selectedAppointment?.statusChangeAt &&
                        t("StatusChangedAt", {
                          date: formatFirebaseDate(
                            selectedAppointment?.statusChangeAt
                          ),
                        })}
                    </Text>
                  )}
              </HStack>

              <Divider my={"10px"} />
              <Text fontWeight={"bold"}>{t("BookingDetails")}</Text>
              <Text>{selectedAppointment?.description}</Text>
              <Text fontWeight={"bold"} textAlign={"right"} color={"gray.700"}>
                {t("CreatedAt")}
              </Text>
              <Text textAlign={"right"} color={"gray.700"}>
                {formatFirebaseDate(selectedAppointment?.createdAt)}
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={onClose}
                colorScheme="gray"
                variant={"outline"}
                size={"sm"}
                disabled={false}
              >
                {t("Close")}
              </Button>
            </ModalFooter>
            {/* </Box> */}
          </ModalContent>
        </Modal>
      )}
      {selectedAppointment && (
        <Modal onClose={iOnClose} size={"sm"} isOpen={iIsOpen} isCentered>
          <ModalOverlay />
          <ModalContent borderRadius={"10px"} maxW={["90%", "md", "md"]}>
            <>
              <ModalHeader>
                {selectedAppointment?.shopName} (
                {formatFirebaseDate(selectedAppointment?.date)})
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody padding={"20px"}>
                <ScaleFade initialScale={0.9} in={iIsOpen}>
                  <VStack>
                    <Button
                      w="100%"
                      onClick={() => {
                        viewAppointment(selectedAppointment);
                      }}
                      variant={"ghost"}
                      color={_mainColor()}
                    >
                      {t("View")}
                    </Button>
                    <Divider />
                    {(selectedAppointment.status === "pending" ||
                      selectedAppointment.status === "accepted") && (
                      <Button
                        onClick={async () => {
                          await changeStatus(selectedAppointment, "cancelled")
                            .then((res) => {
                              refetch();
                              iOnClose();
                            })
                            .catch((error) => {
                              toast({
                                title: t("Oops"),
                                description: t("SomethingWentWrong"),
                                status: "error",
                                duration: 4000,
                                isClosable: true,
                              });
                              iOnClose();
                            });
                        }}
                        w="100%"
                        variant={"ghost"}
                        color={"red"}
                      >
                        {t("Cancel")}
                      </Button>
                    )}
                  </VStack>
                </ScaleFade>
              </ModalBody>
            </>
          </ModalContent>
        </Modal>
      )}
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <>
          <Stack direction={["column", "row"]} spacing={4} mb={4}>
            <Heading as="h1" size="xl" width={"90%"} fontWeight={100} mb={4}>
              {t("MyAppointments")}
            </Heading>
            <Input
              placeholder={t("SearchAppointmentsByName")}
              value={search}
              onChange={(e: any) => {
                setSearch(e.target.value);
                // searchByShopName(e.target.value);
              }}
            />
          </Stack>

          <Card p="16px" overflowX={{ sm: "scroll", xl: "hidden" }}>
            <CardBody>
              <Table variant={"simple"}>
                <Thead>
                  <Tr>
                    <Th></Th>
                    <Th>{t("Shop")}</Th>
                    <Th>{t("Date")}</Th>
                    <Th>{t("Status")}</Th>
                    <Th isNumeric></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredData()?.length === 0 ? (
                    <Tr>
                      <Td colSpan={4} textAlign={"center"}>
                        <Text>{t("NoAppointmentsFound")}</Text>
                      </Td>
                    </Tr>
                  ) : (
                    filteredData()?.map((item: any) => (
                      <Tr key={item.id}>
                        <Td>
                          <Avatar size="sm" src={item.shopLogo} />
                        </Td>
                        <Td>{item.shopName}</Td>
                        <Td>{formatFirebaseDate(item.date)}</Td>
                        <Td color={getAppointmentStatusColor(item.status)}>
                          <HStack>
                            {getAppointmentStatusIcon(item.status)}
                            <Text fontWeight={"600"}>
                              {t(
                                item?.status.charAt(0).toUpperCase() +
                                  item?.status.slice(1)
                              )}
                            </Text>
                          </HStack>
                        </Td>
                        <Td isNumeric>
                          <Box display={["inline-block", "inline-block"]}>
                            <Button
                              px={4}
                              py={2}
                              transition="all 0.2s"
                              fontWeight={"600"}
                              color={_mainColor()}
                              variant={"ghost"}
                              onClick={() => {
                                setSelectedAppointment(item);
                                iOnOpen();
                              }}
                            >
                              {t("More")} <ChevronDownIcon />
                            </Button>
                          </Box>
                        </Td>
                      </Tr>
                    ))
                  )}
                </Tbody>
              </Table>
            </CardBody>
          </Card>
        </>
      )}
    </>
  );
};
