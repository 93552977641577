import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { auth, firestore } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  AbsoluteCenter,
  Box,
  Heading,
  useToast,
  Text,
  HStack,
  Tab,
  TabList,
  Tabs,
  Container,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Textarea,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Center,
  Image,
  Alert,
  AlertIcon,
  Fade,
  Grid,
  Select,
  Flex,
  VStack,
  Tag,
} from "@chakra-ui/react";
import {
  addDays,
  format,
  getDaysInMonth,
  set,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  _mainColor,
  formatDate,
  formatFirebaseDate,
  getDateAndTime,
  isShopAvailable,
  languageCode,
} from "../classes/Utils";
import { Link, useParams } from "react-router-dom";
import { ShopPresentation } from "./ShopPresentation";
import { LoadingAnimation } from "../repeatable-components/RepeatableComponents";
import { SendEmail } from "../classes/Email";
import { NewAppointmentTemplate } from "../email-templates/EmailTemplates";
import { useTranslation } from "react-i18next";
import { Formik, getIn } from "formik";
import Card from "../theme/components/Card/Card";
import CardHeader from "../theme/components/Card/CardHeader";
import {
  MdLockOutline,
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import CardBody from "../theme/components/Card/CardBody";
import { useAuthModal } from "../context/AuthModalContext";
import { resolve } from "path";
import { useShop, useShopWatchAppointments } from "../hooks/useShop";
import {
  IAppointment,
  IReservedTimeslot,
  IShop,
} from "../interfaces/interfaces";

export const Shop = () => {
  const [user]: any = useAuthState(auth);
  const { isModalOpen, openModal, modalType, closeModal } = useAuthModal();
  const { url } = useParams();
  const toast = useToast();
  const {
    data: shop,
    appointments,
    reservedTimeslots,
    isLoading,
  }: {
    data: IShop | undefined;
    appointments: IAppointment[];
    reservedTimeslots: IReservedTimeslot[];
    isLoading: boolean;
  } = useShopWatchAppointments(url || "");
  //   const { appointments, getAppointments } = useAppointment(shop?.id || "");
  const [dayStart, setDayStart] = useState(new Date());
  const [dayEnd, setDayEnd] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime]: any = useState({ hour: 0, minute: 0 });
  const [success, setSuccess] = useState(false);
  const { t } = useTranslation();

  const [unavailabeSlots, setUnavailableSlots] = useState<any>({});
  const [maxDate, setMaxDate] = useState<any>(null);
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date());
  const [startingDay, setStartingDay] = useState<number>(
    startOfMonth(selectedMonth).getDay()
  );
  const [activeDays, setActiveDays] = useState<boolean[]>([]);
  //   const [intervals, setIntervals] = useState<any>(null);
  const currentDate = new Date();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const timeSelectRef = React.useRef<HTMLSelectElement>(null);

  useEffect(() => {
    if (!shop) {
      return;
    }
    const date = new Date();
    date.setDate(date.getDate() + shop?.bookLimit);
    setDayEnd(date);
    setActiveDays(JSON.parse(shop?.days));
    setMaxDate(addDays(currentDate, shop?.bookLimit));
    setUnavailableSlots({});
    // const startHour = getEarliestTimeSlot(shop);
    // const endHour = getLatestTimeSlot(shop);
    // const inte: any = [];
    // for (let hour = startHour; hour <= endHour; hour++) {
    //   for (let minute = 0; minute < 60; minute += parseInt(shop.duration)) {
    //     if (hour === endHour && minute === 0) {
    //       break;
    //     }
    //     inte.push({ hour, minute });
    //   }
    // }

    // setIntervals(inte);
  }, [shop, reservedTimeslots, appointments]);

  useEffect(() => {
    const get = async () => {
      if (!shop) {
        return;
      }
      updateTimeslots().then(() => {
        let totalRequests = reservedTimeslots.filter(
          (appointment: any) =>
            formatDate(appointment.date.toDate()) ===
              formatDate(selectedDate) &&
            appointment.time.hour === selectedTime.hour &&
            appointment.time.minute === selectedTime.minute
        );
        if (totalRequests.length >= shop.slots && isOpen) {
          toast({
            title: t("TimeChanged"),
            description: t("SomeoneBookedOnSelectedTime"),
            status: "warning",
            duration: 4000,
            isClosable: true,
          });
          setTimeout(() => {
            if (timeSelectRef.current?.value) {
              timeSelectRef.current?.focus();
              timeSelectRef.current?.scrollIntoView();
              timeSelectRef.current?.dispatchEvent(
                new Event("change", { bubbles: true })
              );
              onSelection(
                selectedDate,
                JSON.parse(timeSelectRef.current?.value)
              );
            }
          }, 1000);
          return;
        }
      });
    };

    get();
  }, [shop, reservedTimeslots, appointments]);

  const getDayIntervals = (day: any) => {
    if (!shop) {
      return [];
    }
    let intervals = [];
    const timeslots = JSON.parse(shop.timeslots);
    for (let hour = timeslots[day][0]; hour < timeslots[day][1]; hour++) {
      for (let minute = 0; minute < 60; minute += shop.duration) {
        if (hour === 23 && minute === 0) {
          break;
        }
        intervals.push({ hour, minute });
      }
    }
    return intervals;
  };

  const updateTimeslots = async () => {
    if (!shop) {
      return;
    }
    const activeReservedTimeslots = reservedTimeslots;
    let us: any = {};
    for (const appointment of activeReservedTimeslots) {
      if (appointment.date.seconds in us) {
        us[appointment.date.seconds] += 1;
      } else {
        us[appointment.date.seconds] = 1;
      }
    }
    setUnavailableSlots(us);
  };

  const getEarliestTimeSlot = (s: any) => {
    let ets = 24;
    let timeslots = JSON.parse(s.timeslots);
    for (let i = 0; i < timeslots.length; i++) {
      if (timeslots[i][0] < ets) {
        ets = timeslots[i][0];
      }
    }
    return ets;
  };

  const getLatestTimeSlot = (s: any) => {
    let lts = 0;
    let timeslots = JSON.parse(s.timeslots);
    for (let i = 0; i < timeslots.length; i++) {
      if (timeslots[i][1] > lts) {
        lts = timeslots[i][1];
      }
    }
    return lts;
  };

  const isAvailable = (
    day: any,
    hour: any,
    minute: any,
    customUnavailableSlots: any = null
  ) => {
    if (!shop) {
      return false;
    }
    let date = startOfDay(day);
    date.setHours(hour);
    date.setMinutes(minute);
    if (date.getTime() < currentDate.getTime()) {
      return false;
    }
    let dt = date.getTime() / 1000;
    const us = customUnavailableSlots
      ? customUnavailableSlots
      : unavailabeSlots;
    if (dt in us) {
      if (us[dt] >= shop.slots) {
        return false;
      }
    }

    return true;
  };

  const checkIfDateIsToday = (date: any) => {
    return format(date, "yyyy-MM-dd") === format(currentDate, "yyyy-MM-dd");
  };

  const checkIfDateIsGreaterOrEqual = (date: any) => {
    return format(date, "yyyy-MM-dd") >= format(currentDate, "yyyy-MM-dd");
  };

  const checkIfDateIsLessThanMaxDate = (date: any) => {
    return format(date, "yyyy-MM-dd") <= format(maxDate, "yyyy-MM-dd");
  };

  const getNumberOfAvailableSlots = (date: any) => {
    var total = 0;
    const intervals = getDayIntervals(
      date.getDay() < 1 ? 6 : date.getDay() - 1
    );
    intervals.map((interval: any) => {
      if (isAvailable(date, interval.hour, interval.minute)) {
        total += 1;
      }
    });

    return total;
  };

  const onSelection = (date: Date, time: any) => {
    if (activeDays[date.getDay() == 0 ? 0 : date.getDay() - 1]) {
    } else {
      toast({
        title: t("Oops"),
        description: t("ThisDayIsNotAvailable"),
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    if (time === null) {
      let _time: any = null;
      const intervals = getDayIntervals(
        date.getDay() < 1 ? 6 : date.getDay() - 1
      );
      intervals.map((interval: any) => {
        if (_time) {
          return false;
        }
        if (isAvailable(date, interval.hour, interval.minute)) {
          _time = interval;
        }
      });
      if (!_time) {
        toast({
          title: t("Oops"),
          description: t("ThisDayIsNotAvailable"),
          status: "error",
          duration: 4000,
          isClosable: true,
        });
        return;
      } else {
        setSelectedDate(getDateAndTime(date, _time.hour, _time.minute));
        setSelectedTime(_time);
      }
    } else {
      setSelectedDate(getDateAndTime(date, time.hour, time.minute));
      setSelectedTime(time);
    }

    onOpen();
  };

  const onBook = async (values: any) => {
    await updateTimeslots().then(async (res) => {
      if (!isAvailable(selectedDate, selectedTime.hour, selectedTime.minute)) {
        toast({
          title: t("Oops"),
          description: t("ThisDayIsNotAvailable"),
          status: "error",
          duration: 4000,
          isClosable: true,
        });
        return;
      } else {
        onClose();
        if (!shop) {
          return;
        }
        const docRef = await addDoc(
          collection(firestore, `/shops/${shop.id}/appointments`),
          {
            user: user?.uid,
            name: values.name,
            email: user?.email,
            phone: values.phone,
            description: values.description,
            shop: shop.id,
            shopName: shop.name,
            shopLogo: shop.logoUrl,
            shopUrl: shop.url,
            shopEmail: shop.email,
            shopPhone: shop.phone,
            date: selectedDate,
            time: selectedTime,
            status: "pending",
            createdAt: new Date(),
          }
        )
          .then(async (res) => {
            toast({
              title: t("Success"),
              status: "success",
              duration: 3000,
              description: t("YouHaveSuccessfullyBooked", {
                date: formatDate(selectedDate),
              }),
            });
            await addDoc(
              collection(firestore, `/shops/${shop.id}/reserved-timeslots`),
              {
                date: selectedDate,
                time: selectedTime,
                appointmentId: res.id,
              }
            );
            setSuccess(true);

            SendEmail({
              recipient: shop.email,
              subject: "New appointment request!",
              message: NewAppointmentTemplate({
                user: {
                  name: values.name,
                  email: user.email,
                  phone: values.phone,
                },
                date: formatDate(selectedDate, true),
                shopName: shop.name,
                shopUrl: shop.url,
                description: values.description,
              }),
            });
          })
          .catch((error) => {
            toast({
              title: t("Oops"),
              description: t("SomethingWentWrong"),
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          });
      }
    });
  };

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius={"10px"} maxW={["90%", "md", "md"]}>
          <Formik
            initialValues={{
              name: user?.displayName,
              phone: "",
              description: "",
            }}
            validate={(values) => {
              const errors: any = {};
              if (!values.name) {
                errors.name = t("FieldIsRequired");
              }
              if (!values.phone) {
                errors.phone = t("FieldIsRequired");
              } else if (values.phone.length !== 13) {
                errors.phone = t("InvalidPhoneNumber");
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              onBook(values);
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }: /* and other goodies */
            any) => (
              <form onSubmit={handleSubmit}>
                <>
                  <ModalHeader>
                    {t("BookFor", {
                      date: formatDate(selectedDate),
                    })}
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <FormControl marginTop={"10px"} display={"inline-block"}>
                      <FormLabel>{t("SelectATimeslot")}</FormLabel>
                      <Select
                        id="time-selection"
                        ref={timeSelectRef}
                        disabled={isSubmitting}
                        isInvalid={
                          errors.selectedTime &&
                          touched.selectedTime &&
                          errors.selectedTime
                        }
                        value={JSON.stringify(selectedTime)}
                        name="date"
                        onChange={(e) => {
                          onSelection(selectedDate, JSON.parse(e.target.value));
                        }}
                        errorBorderColor="crimson"
                      >
                        {getDayIntervals(
                          selectedDate.getDay() < 1
                            ? 6
                            : selectedDate.getDay() - 1
                        ).map(
                          (interval: any, index: number) =>
                            isAvailable(
                              selectedDate,
                              interval.hour,
                              interval.minute
                            ) && (
                              <option
                                key={index}
                                value={JSON.stringify(interval)}
                              >
                                {format(
                                  set(selectedDate, {
                                    hours: interval.hour,
                                    minutes: interval.minute,
                                  }),
                                  "HH:mm"
                                )}
                              </option>
                            )
                        )}
                      </Select>
                      <FormHelperText>
                        {t("SelectATimeslotSpecification")}
                      </FormHelperText>
                    </FormControl>
                    <FormControl marginTop={"20px"}>
                      <FormLabel>{t("FullName")}</FormLabel>
                      <Input
                        disabled={isSubmitting}
                        type="text"
                        isInvalid={errors.name && touched.name && errors.name}
                        value={values.name}
                        name="name"
                        onChange={handleChange}
                        errorBorderColor="crimson"
                      />
                      <FormHelperText>
                        {t("DisplayNameSpecification")}
                      </FormHelperText>
                    </FormControl>
                    <FormControl marginTop={"20px"}>
                      <FormLabel>{t("PhoneNumber")}</FormLabel>
                      <PhoneInput
                        defaultCountry="GR"
                        disabled={isSubmitting}
                        autoComplete="off"
                        value={values.phone}
                        style={{
                          border: `${
                            errors.phone && touched.phone && errors.phone
                              ? "1px solid crimson"
                              : "1px solid #e2e8f0"
                          }`,
                          boxShadow: `${
                            errors.phone && touched.phone && errors.phone
                              ? "0 0 0 1px crimson"
                              : ""
                          }`,
                          backgroundColor: "white",
                          color: "black",
                          padding: "15px 20px",
                          borderRadius: "3px",
                        }}
                        name="phone"
                        className={`chakra-input css-1yqhqcq focus-none`}
                        onChange={(e: any) => {
                          handleChange("phone")(e ? e : "");
                        }}
                      />
                      <Text color="red.500" size={"sm"}>
                        {errors.phone && touched.phone && errors.phone}
                      </Text>
                      <FormHelperText>
                        {t("PhoneNumberSpecification")}
                      </FormHelperText>
                    </FormControl>
                    <FormControl marginTop={"20px"}>
                      <FormLabel>{t("BookingDetails")}</FormLabel>
                      <Textarea
                        disabled={isSubmitting}
                        value={values.description}
                        placeholder={t("BookingDetailsPlaceholder")}
                        maxH={"300px"}
                        h={"10vh"}
                        name="description"
                        onChange={handleChange}
                      />
                      <FormHelperText>
                        {t("BookingDetailsSpecification")}
                      </FormHelperText>
                    </FormControl>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="submit"
                      isLoading={isSubmitting}
                      colorScheme={"pink"}
                      marginRight={"10px"}
                    >
                      {t("Book")}
                    </Button>
                    <Button
                      colorScheme="gray"
                      variant={"outline"}
                      size={"sm"}
                      onClick={onClose}
                      isLoading={isSubmitting}
                    >
                      {t("Close")}
                    </Button>
                  </ModalFooter>
                </>
              </form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <>
          {!isShopAvailable(shop as IShop)  ? (
            <Container maxW="container.md">
              <Box w={"100%"} my="20px">
                <Center>
                  <Image src={"/illustrations/error.svg"} alt="error" w="70%" />
                </Center>
              </Box>

              <Box textAlign="center" py={10} px={6}>
                {/* <CheckCircleIcon boxSize={"50px"} color={"green.500"} /> */}
                <Heading as="h2" size="xl" mt={6} mb={2}>
                  {t("ShopIsNotCurrentlyAvailable")}
                </Heading>
                <Text color={"gray.500"}>
                  {t("ShopIsNotCurrentlyAvailableSpecification")}
                </Text>
              </Box>
            </Container>
          ) : success ? (
            <Fade in={success}>
              <Container maxW="container.md">
                <Box w={"100%"}>
                  <Center>
                    <Image
                      src={"/illustrations/book_success.svg"}
                      alt="success"
                      w="60%"
                    />
                  </Center>
                </Box>
                <Box textAlign="center" py={10} px={6}>
                  {/* <CheckCircleIcon boxSize={"50px"} color={"green.500"} /> */}
                  <Heading as="h2" size="xl" mt={6} mb={2}>
                    {t("YourAppointmentHasBeenBooked")}
                  </Heading>
                  <Text color={"gray.500"}>
                    {t("YourAppointmentHasBeenBookedSpecification", {
                      date: formatDate(selectedDate),
                    })}
                  </Text>
                  <Link to={`/user/appointments`}>
                    <Button variant="main" mt="40px">
                      {t("ViewAppointments")}
                    </Button>
                  </Link>
                </Box>
              </Container>
            </Fade>
          ) : (
            <>
              <Fade in={true}>
                <Grid
                  templateColumns={["1fr", "1fr 1fr"]}
                  gap={4}
                  w="100%"
                  h="auto"
                  textAlign={"left"}
                >
                  <ShopPresentation shop={shop} />
                  {/* Calendar */}
                  {shop && (
                    <>
                      <Card p="16px" w="100%" h="auto">
                        <CardHeader w="100%" mb="40px" p="10px">
                          <Flex w="100%" justifyContent="space-between">
                            <Heading fontSize="2xl" color="black">
                              {format(selectedMonth, "MMMM yyyy", {
                                locale: languageCode(),
                              })}
                            </Heading>
                            <HStack spacing={2}>
                              <Button
                                variant={"ghost"}
                                color={"black"}
                                isDisabled={
                                  selectedMonth.getTime() <=
                                  currentDate.getTime()
                                }
                                onClick={() => {
                                  let sm = new Date();
                                  sm.setMonth(selectedMonth.getMonth() - 1);
                                  sm.setDate(currentDate.getDate());
                                  setSelectedMonth(sm);
                                  setStartingDay(startOfMonth(sm).getDay());
                                }}
                              >
                                <Text fontSize={"3xl"}>
                                  <MdOutlineKeyboardArrowLeft />
                                </Text>
                              </Button>
                              <Button
                                variant={"ghost"}
                                color={"black"}
                                onClick={() => {
                                  let sm = new Date();
                                  sm.setMonth(selectedMonth.getMonth() + 1);
                                  sm.setDate(currentDate.getDate());
                                  setSelectedMonth(sm);
                                  setStartingDay(startOfMonth(sm).getDay());
                                }}
                              >
                                <Text fontSize={"3xl"}>
                                  <MdOutlineKeyboardArrowRight />
                                </Text>
                              </Button>
                            </HStack>
                          </Flex>
                        </CardHeader>
                        <Fade in={true}>
                          <CardBody>
                            <Grid
                              templateColumns="repeat(7, 1fr)"
                              gap={0}
                              w="100%"
                              h="auto"
                              borderRadius={"15px"}
                              textAlign={"left"}
                              //   display={["none", "grid", "grid"]}
                              //   display={{ base: "none", md: "grid", lg: "grid" }}
                            >
                              {Array.from({ length: 7 }, (_, i) =>
                                addDays(
                                  startOfWeek(new Date(), {
                                    weekStartsOn: 1,
                                  }),
                                  i
                                )
                              ).map((day: any, index: number) => (
                                <Box
                                  key={index}
                                  w="100%"
                                  h="auto"
                                  p={{ base: "5px", md: "16px", lg: "16px" }}
                                  textAlign={"start"}
                                >
                                  <Text
                                    fontSize="sm"
                                    fontWeight={"bold"}
                                    color="gray.500"
                                  >
                                    {format(day, "eee", {
                                      locale: languageCode(),
                                    }).toUpperCase()}
                                  </Text>
                                </Box>
                              ))}
                              {Array.from(
                                {
                                  length:
                                    startingDay == 0 ? 6 : startingDay - 1,
                                },
                                (_, i) => {
                                  return (
                                    <Box
                                      key={i}
                                      w="100%"
                                      h="auto"
                                      minH={{
                                        base: "60px",
                                        md: "400px",
                                        lg: "400px",
                                      }}
                                      maxH={{
                                        base: "60px",
                                        md: "400px",
                                        lg: "400px",
                                      }}
                                      bg={"white"}
                                      className={"stripes-bg"}
                                      p={{
                                        base: "10px",
                                        md: "16px",
                                        lg: "16px",
                                      }}
                                      borderLeft={"1px solid"}
                                      borderRight={
                                        i % 7 === 6 ? "1px solid" : "0px"
                                      }
                                      borderBottom={"1px solid"}
                                      borderTop={i < 7 ? "1px solid" : "0px"}
                                      borderColor={"gray.100"}
                                    ></Box>
                                  );
                                }
                              )}
                              {Array.from(
                                { length: getDaysInMonth(selectedMonth) },
                                (_, i) => {
                                  let startingDay =
                                    startOfMonth(selectedMonth).getDay();
                                  if (startingDay === 0) {
                                    startingDay = 6;
                                  }
                                  let day = addDays(
                                    startOfMonth(selectedMonth),
                                    i
                                  );
                                  return (
                                    <Box
                                      key={i}
                                      w="100%"
                                      h="auto"
                                      minH={{
                                        base: "60px",
                                        md: "400px",
                                        lg: "400px",
                                      }}
                                      maxH={{
                                        base: "60px",
                                        md: "400px",
                                        lg: "400px",
                                      }}
                                      bg={"white"}
                                      className={
                                        activeDays[
                                          day.getDay() < 1
                                            ? 6
                                            : day.getDay() - 1
                                        ] &&
                                        checkIfDateIsGreaterOrEqual(day) &&
                                        checkIfDateIsLessThanMaxDate(day)
                                          ? ""
                                          : "stripes-bg"
                                      }
                                      p={{
                                        base: "0px",
                                        md: "16px",
                                        lg: "16px",
                                      }}
                                      borderLeft={"1px solid"}
                                      borderRight={
                                        i % 7 === 6 ? "1px solid" : "0px"
                                      }
                                      borderBottom={"1px solid"}
                                      borderTop={i < 7 ? "1px solid" : "0px"}
                                      borderColor={"gray.100"}
                                    >
                                      <Box
                                        w="100%"
                                        h="50px"
                                        opacity={"0"}
                                        position={"relative"}
                                        zIndex={10}
                                        marginBottom={"-50px"}
                                        _hover={{
                                          cursor: "pointer",
                                          opacity: "0.1",
                                        }}
                                        display={{
                                          base: "block",
                                          md: "none",
                                          lg: "none",
                                        }}
                                        onClick={() => {
                                          if (!user) {
                                            openModal("signin");
                                          } else {
                                            onSelection(day, null);
                                          }
                                        }}
                                      ></Box>
                                      <VStack
                                        spacing={1}
                                        alignItems={"baseline"}
                                      >
                                        <Text
                                          fontSize={{
                                            base: "md",
                                            md: "2xl",
                                            lg: "2xl",
                                          }}
                                          fontWeight={"bold"}
                                          color={
                                            checkIfDateIsToday(day)
                                              ? _mainColor()
                                              : "gray.500"
                                          }
                                        >
                                          {format(day, "dd", {
                                            locale: languageCode(),
                                          }).toUpperCase()}
                                        </Text>
                                      </VStack>

                                      {activeDays[
                                        day.getDay() < 1 ? 6 : day.getDay() - 1
                                      ] &&
                                      checkIfDateIsGreaterOrEqual(day) &&
                                      checkIfDateIsLessThanMaxDate(day) ? (
                                        <>
                                          <Box
                                            h="90%"
                                            overflowY={"auto"}
                                            w="100%"
                                          >
                                            <Box
                                              w="100%"
                                              h="auto"
                                              display={{
                                                base: "flex",
                                                md: "none",
                                                lg: "none",
                                              }}
                                            >
                                              <Tag
                                                size={"sm"}
                                                variant="solid"
                                                bg={_mainColor()}
                                                colorScheme="blue"
                                                margin={"0 auto"}
                                              >
                                                {getNumberOfAvailableSlots(day)}{" "}
                                              </Tag>
                                            </Box>
                                            <VStack
                                              spacing={1}
                                              alignItems={"baseline"}
                                              height={"100%"}
                                              display={{
                                                base: "none",
                                                md: "flex",
                                                lg: "flex",
                                              }}
                                            >
                                              {getDayIntervals(
                                                day.getDay() < 1
                                                  ? 6
                                                  : day.getDay() - 1
                                              ).map(
                                                (
                                                  interval: any,
                                                  index: number
                                                ) =>
                                                  isAvailable(
                                                    day,
                                                    interval.hour,
                                                    interval.minute
                                                  ) && (
                                                    <Box
                                                      key={index}
                                                      w="100%"
                                                      h="auto"
                                                    >
                                                      <Button
                                                        //   key={index}
                                                        size="xs"
                                                        variant={"main"}
                                                        colorScheme="blue"
                                                        w="100%"
                                                        h="auto"
                                                        p="5px"
                                                        borderRadius={"3px"}
                                                        _hover={{
                                                          bg: "pink.500",
                                                        }}
                                                        onClick={() => {
                                                          if (!user) {
                                                            openModal("signin");
                                                          } else {
                                                            onSelection(
                                                              day,
                                                              interval
                                                            );
                                                          }
                                                        }}
                                                      >
                                                        {format(
                                                          set(day, {
                                                            hours:
                                                              interval.hour,
                                                            minutes:
                                                              interval.minute,
                                                          }),
                                                          "HH:mm"
                                                        )}
                                                      </Button>
                                                    </Box>
                                                  )
                                              )}
                                            </VStack>
                                          </Box>
                                        </>
                                      ) : (
                                        <Box
                                          color="gray.500"
                                          w="100%"
                                          fontSize={{
                                            base: "sm",
                                            md: "2xl",
                                            lg: "4xl",
                                          }}
                                        >
                                          <Center>
                                            {activeDays[
                                              day.getDay() < 1
                                                ? 6
                                                : day.getDay() - 1
                                            ] ? (
                                              <></>
                                            ) : (
                                              <Box title={t("Closed")}>
                                                <MdLockOutline />
                                              </Box>
                                            )}
                                          </Center>
                                        </Box>
                                      )}
                                    </Box>
                                  );
                                }
                              )}
                            </Grid>
                          </CardBody>
                        </Fade>
                      </Card>
                    </>
                  )}
                </Grid>
              </Fade>
            </>
          )}
        </>
      )}
    </>
  );
};
