import {
  getDocs,
  query,
  collection,
  where,
  onSnapshot,
} from "firebase/firestore";
import { useQuery } from "react-query";
import { auth, firestore } from "../firebase";
import {
  IAppointment,
  IReservedTimeslot,
  IShop,
} from "../interfaces/interfaces";
import { getShopSubscription } from "../stripe/Stripe";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect, useState } from "react";

export const useMyShops = (page: string) => {
  const [user] = useAuthState(auth);
  return useQuery({
    queryKey: ["shops", page],
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    queryFn: async () => {
      const snapshot = await getDocs(
        query(collection(firestore, "shops"), where("owner", "==", user?.uid))
      );
      const data: IShop[] = [];
      for (const doc of snapshot.docs) {
        let shop = doc.data() as IShop;
        shop.id = doc.id;
        shop.subscription = await getShopSubscription(shop);
        data.push(shop);
      }
      return data as IShop[];
    },
  });
};

export const useShop = (url: string) => {
  return useQuery({
    queryKey: ["shop", url],
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    queryFn: async () => {
      const doc = await getDocs(
        query(collection(firestore, "shops"), where("url", "==", url))
      );
      if (doc.empty) {
        return {} as IShop;
      }
      let shop = doc.docs[0].data() as IShop;
      shop.id = doc.docs[0].id;
      shop.subscription = await getShopSubscription(shop);
      return shop as IShop;
    },
  });
};

export const useMyShop = (url: string) => {
  const [user] = useAuthState(auth);
  return useQuery({
    queryKey: ["shop", url],
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    queryFn: async () => {
      const doc = await getDocs(
        query(
          collection(firestore, "shops"),
          where("url", "==", url),
          where("owner", "==", user?.uid)
        )
      );
      if (doc.empty) {
        return {} as IShop;
      }
      let shop = doc.docs[0].data() as IShop;
      shop.id = doc.docs[0].id;
      shop.subscription = await getShopSubscription(shop);
      return shop as IShop;
    },
  });
};

export const useShopWatchAppointments = (
  url: string
): {
  data: IShop | undefined;
  appointments: IAppointment[];
  reservedTimeslots: IReservedTimeslot[];
  pendingChanges: any;
  isLoading: boolean;
} => {
  const [pendingChanges, setPendingChanges] = useState<any>(null);
  const [pendingChangesTimeslots, setPendingChangesTimeslots] =
    useState<any>(null);
  const [appointments, setAppointments] = useState<IAppointment[]>([]);
  const [reservedTimeslots, setReservedTimeslots] = useState<
    IReservedTimeslot[]
  >([]);

  const { data, isLoading } = useShop(url);
  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!data || !data.id) {
      return;
    }
    const get = async () => {
      onSnapshot(
        collection(firestore, "shops", data.id, "appointments"),
        { includeMetadataChanges: true },
        (doc) => {
          setPendingChanges(doc.docChanges());
        }
      );
      onSnapshot(
        collection(firestore, "shops", data.id, "reserved-timeslots"),
        { includeMetadataChanges: true },
        (doc) => {
          setPendingChangesTimeslots(doc.docChanges());
        }
      );
    };
    get();
  }, [isLoading]);
  // SET PENDING APPOINTMENTS
  useEffect(() => {
    if (pendingChanges === null) {
      return;
    }
    for (const index in pendingChanges) {
      const change = pendingChanges[index];
      if (change.type === "added") {
        setAppointments((prev) => [
          ...prev,
          {
            ...change.doc.data(),
            id: change.doc.id,
          },
        ]);
      }
      if (change.type === "modified") {
        setAppointments((prev) =>
          prev.map((appointment) => {
            if (appointment.id === change.doc.id) {
              return {
                ...change.doc.data(),
                id: change.doc.id,
              };
            }
            return appointment;
          })
        );
      }
      if (change.type === "removed") {
        setAppointments((prev) =>
          prev.filter((appointment) => appointment.id !== change.doc.id)
        );
      }
    }
    setPendingChanges(null);
  }, [pendingChanges]);

  // SET PENDING TIMESLOTS
  useEffect(() => {
    if (pendingChangesTimeslots === null) {
      return;
    }
    for (const index in pendingChangesTimeslots) {
      const change = pendingChangesTimeslots[index];
      if (change.type === "added") {
        setReservedTimeslots((prev) => [
          ...prev,
          {
            ...change.doc.data(),
            id: change.doc.id,
          },
        ]);
      }
      if (change.type === "modified") {
        setReservedTimeslots((prev) =>
          prev.map((timeslot) => {
            if (timeslot.id === change.doc.id) {
              return {
                ...change.doc.data(),
                id: change.doc.id,
              };
            }
            return timeslot;
          })
        );
      }
      if (change.type === "removed") {
        setReservedTimeslots((prev) =>
          prev.filter((timeslot) => timeslot.id !== change.doc.id)
        );
      }
    }
    setPendingChangesTimeslots(null);
  }, [pendingChangesTimeslots]);

  return { data, appointments, reservedTimeslots, pendingChanges, isLoading };
};

// MY SHOP WATCH APPOINTMENTS
export const useMyShopWatchAppointments = (
  url: string
): {
  data: IShop | undefined;
  appointments: IAppointment[];
  reservedTimeslots: IReservedTimeslot[];
  pendingChanges: any;
  isLoading: boolean;
} => {
  const [pendingChanges, setPendingChanges] = useState<any>(null);
  const [pendingChangesTimeslots, setPendingChangesTimeslots] =
    useState<any>(null);
  const [appointments, setAppointments] = useState<IAppointment[]>([]);
  const [reservedTimeslots, setReservedTimeslots] = useState<
    IReservedTimeslot[]
  >([]);

  const { data, isLoading } = useMyShop(url);
  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!data || !data.id) {
      return;
    }
    const get = async () => {
      onSnapshot(
        collection(firestore, "shops", data.id, "appointments"),
        { includeMetadataChanges: true },
        (doc) => {
          setPendingChanges(doc.docChanges());
        }
      );
      onSnapshot(
        collection(firestore, "shops", data.id, "reserved-timeslots"),
        { includeMetadataChanges: true },
        (doc) => {
          setPendingChangesTimeslots(doc.docChanges());
        }
      );
    };
    get();
  }, [isLoading]);
  // SET PENDING APPOINTMENTS
  useEffect(() => {
    if (pendingChanges === null) {
      return;
    }
    for (const index in pendingChanges) {
      const change = pendingChanges[index];
      if (change.type === "added") {
        setAppointments((prev) => [
          ...prev,
          {
            ...change.doc.data(),
            id: change.doc.id,
          },
        ]);
      }
      if (change.type === "modified") {
        setAppointments((prev) =>
          prev.map((appointment) => {
            if (appointment.id === change.doc.id) {
              return {
                ...change.doc.data(),
                id: change.doc.id,
              };
            }
            return appointment;
          })
        );
      }
      if (change.type === "removed") {
        setAppointments((prev) =>
          prev.filter((appointment) => appointment.id !== change.doc.id)
        );
      }
    }
    setPendingChanges(null);
  }, [pendingChanges]);

  // SET PENDING TIMESLOTS
  useEffect(() => {
    if (pendingChangesTimeslots === null) {
      return;
    }
    for (const index in pendingChangesTimeslots) {
      const change = pendingChangesTimeslots[index];
      if (change.type === "added") {
        setReservedTimeslots((prev) => [
          ...prev,
          {
            ...change.doc.data(),
            id: change.doc.id,
          },
        ]);
      }
      if (change.type === "modified") {
        setReservedTimeslots((prev) =>
          prev.map((timeslot) => {
            if (timeslot.id === change.doc.id) {
              return {
                ...change.doc.data(),
                id: change.doc.id,
              };
            }
            return timeslot;
          })
        );
      }
      if (change.type === "removed") {
        setReservedTimeslots((prev) =>
          prev.filter((timeslot) => timeslot.id !== change.doc.id)
        );
      }
    }
    setPendingChangesTimeslots(null);
  }, [pendingChangesTimeslots]);

  return { data, appointments, reservedTimeslots, pendingChanges, isLoading };
};
