import { addDoc, collection } from "firebase/firestore";
import { firestore } from "../firebase";

const email = "support@apppoint.gr";

export const SendEmail = async ({ recipient, subject, message }: any) => {
  const docRef = await addDoc(collection(firestore, "emails"), {
    to: [
      {
        email: recipient,
      },
    ],
    subject: subject,
    html: message,
    createdAt: new Date(),
    from: {
      email: email,
      name: "Apppoint",
    },
  })
    .then(() => {})
    .catch((error) => {});
};
