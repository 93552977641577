import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { border, ChakraProvider, extendTheme, Table } from "@chakra-ui/react";
import { App } from "./components/App";
import { AuthModalProvider } from "./context/AuthModalContext";
import { AuthModal } from "./components/AuthModal";
import { BrowserRouter } from "react-router-dom";
import { _mainColor } from "./classes/Utils";
import "./i18n";
import { mainTheme } from "./theme/theme.js";
import "./style/effects.css";
import { Helmet } from "react-helmet";
import { QueryClient, QueryClientProvider } from "react-query";

const theme = extendTheme({
  colors: {
    brand: {
      900: "#3a56ff",
      800: "#3a56ff",
      700: "#3a56ff",
    },
  },
  components: {
    Input: {
      baseStyle: {
        baseStyle: {
          borderRadius: "0px",
        },
        field: {
          padding: "25px 20px", // Set the padding to 20px for the input field
          borderRadius: "3px",
        },
      },
    },
    Button: {
      baseStyle: {
        fontWeight: "bold",
      },
      sizes: {
        lg: {
          h: "56px",
          fontSize: "lg",
          px: "32px",
        },
      },
      variants: {
        "with-shadow": {
          bg: "red.400",
          boxShadow: "0 0 2px 2px #efdfde",
          _hover: {
            bg: "red.500",
          },
        },
        solid: (props: any) => ({
          bg: _mainColor(),
          _hover: {
            bg: _mainColor(),
          },
        }),
      },
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const queryClient = new QueryClient();
root.render(
  <ChakraProvider theme={mainTheme}>
    <BrowserRouter>
      <AuthModalProvider>
        <Helmet>
          {/* Title for the homepage */}
          <title>Apppoint.gr | Ραντεβού για την επιχείρηση σας, online, πανεύκολα</title>

          {/* Meta description to describe what your platform does */}
          <meta
            name="description"
            content="Το Apppoint.gr είναι η εύκολη πλατφόρμα για να δημιουργήσετε καταστήματα και να επιτρέψετε στους πελάτες να κλείνουν ραντεβού online. Εξυπηρετήστε την επιχείρησή σας εύκολα και γρήγορα."
          />

          {/* Keywords for better search visibility */}
          <meta
            name="keywords"
            content="online ραντεβού, Apppoint.gr, κρατήσεις καταστήματος, πλατφόρμα ραντεβού, διαχείριση καταστημάτων, κρατήσεις ραντεβού, make appointments, online appointments, shop appointments"
          />

          {/* Open Graph Meta Tags for social sharing */}
          <meta
            property="og:title"
            content="Apppoint.gr | Ραντεβού για την επιχείρηση σας, online, πανεύκολα"
          />
          <meta
            property="og:description"
            content="Δημιουργήστε το δικό σας κατάστημα και αφήστε τους πελάτες να κλείνουν online ραντεβού για τις υπηρεσίες σας με το Apppoint.gr."
          />
          <meta property="og:url" content="https://www.apppoint.gr" />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content="https://www.apppoint.gr/favicon.ico"
          />

          {/* Twitter Card Meta Tags */}
          <meta
            name="twitter:title"
            content="Apppoint.gr | Online κρατήσεις ραντεβού"
          />
          <meta
            name="twitter:description"
            content="Δημιουργήστε το δικό σας κατάστημα και αφήστε τους πελάτες να κλείνουν online ραντεβού για τις υπηρεσίες σας με το Apppoint.gr."
          />
          <meta
            name="twitter:image"
            content="https://www.apppoint.gr/favicon.ico"
          />
        </Helmet>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </AuthModalProvider>
    </BrowserRouter>
  </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
