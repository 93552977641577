import {
  AbsoluteCenter,
  Box,
  Center,
  Container,
  Heading,
  ScaleFade,
  Image,
  VStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export const SignInReminder = () => {
  const { t } = useTranslation();
  return (
    <ScaleFade in={true} initialScale={0.7}>
      <AbsoluteCenter>
        <VStack>
          <Box w={"100%"} my="20px">
            <Center>
              <Image src={"/illustrations/sign-in.svg"} alt="heart" w="50%" />
            </Center>
          </Box>
          <Heading>{t("PleaseSignInToContinue")}</Heading>
        </VStack>
      </AbsoluteCenter>
    </ScaleFade>
  );
};
