import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormControl,
  FormLabel,
  AbsoluteCenter,
  Box,
  Center,
  Checkbox,
  Divider,
  Flex,
  Heading,
  Stack,
  useColorModeValue,
  Text,
  HStack,
  InputGroup,
  InputRightElement,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
  User,
} from "firebase/auth";
import { auth, signInWithGooglePopup } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { FaGoogle } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { languageCode, languageCodeString } from "../classes/Utils";
import { Error } from "../classes/Error";

interface AuthModalProps {
  isOpen: boolean;
  onClose: () => void;
  type: "signin" | "signup";
}

export const AuthModal: React.FC<AuthModalProps> = ({
  isOpen,
  onClose,
  type,
}) => {
  const [user]: any = useAuthState(auth);
  const isSignIn = type === "signin";
  const [showPassword, setShowPassword] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [canDoAction, setCanDoAction] = useState(true);
  const toast = useToast();
  const { t } = useTranslation();
  const errors = Error();

  const handleSignIn = () => {
    setCanDoAction(false);
    setSubmitted(true);
    if (email === "" || password === "") {
      toast({
        title: t("Oops"),
        description: t("FormValidationError"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setCanDoAction(true);
      return;
    }
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential: any) => {
        onClose();
        toast({
          title: t("Success"),
          description: t("WelcomeBack", {
            name: userCredential?.user.displayName,
          }),
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setCanDoAction(true);
        // window.location.href = "/";
      })
      .catch((error) => {
        setCanDoAction(true);
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode);
        toast({
          title: t("Oops"),
          description: errors[errorCode] || errorMessage,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const handleGoogleSingIn = async () => {
    // setCanDoAction(false);
    // onClose();
    signInWithPopup(auth, new GoogleAuthProvider())
      .then((res) => {})
      .catch((error) => {
        console.error(error);
      });
    // setCanDoAction(true);
  };

  const handleSignUp = async () => {
    setCanDoAction(false);
    await createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        await sendEmailVerification(auth.currentUser as User).catch((error) => {
          console.error(error);
        });
        await updateProfile(auth.currentUser as User, {
          displayName: `${firstName} ${lastName}`,
        })
          .then((res) => {})
          .catch((error) => {
            console.error(error);
          });
        onClose();
        setCanDoAction(true);
      })
      .catch((error) => {
        setCanDoAction(true);
        const errorCode: any = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
        console.error(errorCode);
        toast({
          title: t("Oops"),
          description: errors[errorCode] || errorMessage,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      });
  };

  const termsAndConditions = () => {
    return (
      <Text>
        {t("BySigningUpYouAgreeToOur")}{" "}
        <Link
          to={`/terms-of-service/${languageCodeString()}`}
          onClick={onClose}
        >
          <Text as={"span"} color={"blue.400"}>
            {t("TermsOfService")}
          </Text>
        </Link>{" "}
        {t("And")}{" "}
        <Link
          to={`/privacy-policy/${languageCodeString()}`}
          onClick={onClose}
          color={"blue.400"}
        >
          <Text as={"span"} color={"blue.400"}>
            {t("PrivacyPolicy")}
          </Text>
        </Link>
        .
      </Text>
    );
  };

  const handlePasswordReset = () => {
    if (submitted) {
      return;
    }
    if (email === "") {
      toast({
        title: t("Oops"),
        description: t("PleaseFillYourEmail"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setSubmitted(false);
      return;
    }
    setSubmitted(true);
    sendPasswordResetEmail(auth, email)
      .then(() => {
        toast({
          title: t("Success"),
          description: t("PasswordResetEmailSent"),
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onClose();
        setSubmitted(false);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast({
          title: t("Oops"),
          description: errorMessage,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setSubmitted(false);
      });
  };

  return (
    <Modal
      onClose={() => {
        onClose();
        setCanDoAction(true);
        setEmail("");
        setPassword("");
      }}
      isOpen={isOpen && !user}
      isCentered
    >
      <ModalOverlay />
      <ModalContent borderRadius={"10px"} maxW={["90%", "md", "md"]}>
        <>
          <ModalHeader></ModalHeader>
          <ModalCloseButton disabled={!canDoAction} />
          <ModalBody>
            {isSignIn ? (
              <>
                <Flex align={"center"} justify={"center"}>
                  <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
                    <Stack align={"center"}>
                      <Heading fontSize={"4xl"}>
                        {t("SignInToYourAccount")}
                      </Heading>
                    </Stack>
                    <Box>
                      <Stack spacing={4}>
                        <FormControl id="email">
                          <FormLabel>{t("EmailAddress")}</FormLabel>
                          <Input
                            type="email"
                            isInvalid={submitted && !email}
                            errorBorderColor="crimson"
                            disabled={!canDoAction}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </FormControl>
                        <FormControl id="password">
                          <FormLabel>{t("Password")}</FormLabel>
                          <Input
                            isInvalid={submitted && !password}
                            errorBorderColor="crimson"
                            type="password"
                            disabled={!canDoAction}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </FormControl>
                        <Stack spacing={2}>
                          <Stack
                            direction={{ base: "column", sm: "row" }}
                            align={"start"}
                            justify={"space-between"}
                          >
                            {/* <Checkbox disabled={!canDoAction}>
                              {t("RememberMe")}
                            </Checkbox> */}
                            <Button
                              onClick={handlePasswordReset}
                              variant={"link"}
                              mb="4"
                              fontWeight={"normal"}
                              disabled={submitted}
                            >
                              <Text color={"blue.400"}>
                                {t("ForgotPassword")}
                              </Text>
                            </Button>
                          </Stack>
                          {termsAndConditions()}
                          <Button
                            mt="20px"
                            opacity={canDoAction ? 1 : 0.3}
                            colorScheme="blue"
                            disabled={!canDoAction}
                            onClick={handleSignIn}
                          >
                            {!canDoAction ? <Spinner /> : t("SignIn")}
                          </Button>

                          <Button
                            colorScheme="blue"
                            variant="outline"
                            leftIcon={<FaGoogle />}
                            onClick={handleGoogleSingIn}
                          >
                            {t("SignInWithGoogle")}
                          </Button>
                        </Stack>
                      </Stack>
                    </Box>
                  </Stack>
                </Flex>
              </>
            ) : (
              <Flex align={"center"} justify={"center"}>
                <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
                  <Stack align={"center"}>
                    <Heading fontSize={"4xl"} textAlign={"center"}>
                      {t("SignUp")}
                    </Heading>
                  </Stack>
                  <Box>
                    <Stack spacing={4}>
                      <HStack>
                        <Box>
                          <FormControl id="firstName" isRequired>
                            <FormLabel>{t("FirstName")}</FormLabel>
                            <Input
                              disabled={!canDoAction}
                              type="text"
                              onChange={(e) => setFirstName(e.target.value)}
                            />
                          </FormControl>
                        </Box>
                        <Box>
                          <FormControl id="lastName" isRequired>
                            <FormLabel>{t("LastName")}</FormLabel>
                            <Input
                              disabled={!canDoAction}
                              type="text"
                              onChange={(e) => setLastName(e.target.value)}
                            />
                          </FormControl>
                        </Box>
                      </HStack>
                      <FormControl id="email" isRequired>
                        <FormLabel>{t("EmailAddress")}</FormLabel>
                        <Input
                          disabled={!canDoAction}
                          type="email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </FormControl>
                      <FormControl id="password" isRequired>
                        <FormLabel>{t("Password")}</FormLabel>
                        <InputGroup>
                          <Input
                            disabled={!canDoAction}
                            type={showPassword ? "text" : "password"}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <InputRightElement h={"full"}>
                            <Button
                              disabled={!canDoAction}
                              variant={"ghost"}
                              onClick={() =>
                                setShowPassword((showPassword) => !showPassword)
                              }
                            >
                              {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                      </FormControl>
                      {termsAndConditions()}
                      <Stack spacing={2} pt={2}>
                        <Button
                          colorScheme="blue"
                          opacity={canDoAction ? 1 : 0.3}
                          onClick={handleSignUp}
                          disabled={!canDoAction}
                        >
                          {!canDoAction ? <Spinner /> : t("SignUp")}
                        </Button>
                        <Button
                          colorScheme="blue"
                          variant="outline"
                          leftIcon={<FaGoogle />}
                          onClick={handleGoogleSingIn}
                        >
                          {t("SignUpWithGoogle")}
                        </Button>
                      </Stack>
                    </Stack>
                  </Box>
                </Stack>
              </Flex>
            )}
          </ModalBody>
        </>
      </ModalContent>
    </Modal>
  );
};
