"use client";
import { FirebaseApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { updateDo } from "typescript";
import { auth, firestore } from "../firebase";
import { addDays, roundToNearestMinutes } from "date-fns";
import { loadStripe } from "@stripe/stripe-js";
import { formatFirebaseDate } from "../classes/Utils";
import i18n from "../i18n";
import { ISubscription } from "../interfaces/interfaces";

export const getCustomerId = async () => {
  const user = auth.currentUser;
  if (!user) throw new Error("User is not authenticated");

  const userRef = doc(firestore, "customers", user.uid);
  const userSnap = await getDoc(userRef);
  if (userSnap.exists()) {
    return userSnap.data().stripeId;
  } else {
    throw new Error("User is not authenticated");
  }
};

export const getCheckoutUrl = async (priceId: string, shop: any) => {
  const response = await fetch(
    `${process.env.REACT_APP_FUNCTIONS_URI}/checkout`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${await auth.currentUser?.getIdToken()}`,
      },
      body: JSON.stringify({
        //   priceId: "price_1PoMoeIafqRBnXqnAxaOLLYo",
        priceId: priceId,
        shopId: shop.id,
        auth:
          process.env.NODE_ENV === "development" ? auth.currentUser?.uid : null,
        //   customerId: await getCustomerId(),
        successUrl: window.location.href,
        shopOwner: shop.owner,
        locale: i18n.language,
      }),
    }
  );
  const data = await response.json();
  return data;
};

export const getPortalUrl = async (app: FirebaseApp): Promise<string> => {
  const auth = getAuth(app);
  const user = auth.currentUser;

  let dataWithUrl: any;
  try {
    const functions = getFunctions(app, "europe-central2");
    const functionRef = httpsCallable(
      functions,
      "ext-firestore-stripe-payments-createPortalLink"
    );
    const { data } = await functionRef({
      customerId: user?.uid,
      returnUrl: window.location.origin,
    });

    // Add a type to the data
    dataWithUrl = data as { url: string };
  } catch (error) {
    console.error(error);
  }

  return new Promise<string>((resolve, reject) => {
    if (dataWithUrl.url) {
      resolve(dataWithUrl.url);
    } else {
      reject(new Error("No url returned"));
    }
  });
};

export const getShopSubscription = async (
  shop: any
): Promise<ISubscription | null> => {
  const subscriptionsRef = collection(
    firestore,
    "customers",
    shop.owner,
    "subscriptions"
  );
  const subscriptions = await getDocs(subscriptionsRef);
  let count = 0;
  for (const i in subscriptions.docs) {
    const sub = subscriptions.docs[i].data() as ISubscription;
    sub.id = subscriptions.docs[i].id;
    if (sub.metadata.shopId === shop.id && sub.status === "active") {
      return { ...sub, trialUsed: true };
    }
    if (sub.metadata.shopId === shop.id && sub.status === "trialing") {
      return { ...sub, trialUsed: true };
    }
    if (sub.metadata.shopId === shop.id) {
      count++;
    }
  }
  if (count == 0) {
    return {
      id: "",
      status: "",
      current_period_end: 0,
      cancel_at: null,
      metadata: { shopId: shop.id },
      trialUsed: false,
    };
  }
  //   return null;

  return {
    id: "",
    status: "",
    current_period_end: 0,
    cancel_at: null,
    metadata: { shopId: shop.id },
    trialUsed: true,
  };
};

export const usedTrial = async (shop: any) => {
  const subscriptionsRef = collection(
    firestore,
    "customers",
    shop.owner,
    "subscriptions"
  );
  const subscriptions = await getDocs(subscriptionsRef);
  for (const i in subscriptions.docs) {
    const sub = subscriptions.docs[i].data();
    if (sub.metadata.shopId === shop.id) {
      return true;
    }
  }
  return false;
};
